import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { rolesAdapter } from '~/redux/modules/rolesModule';

export const rolesSelector = rolesAdapter.getSelectors<IStore>(state => state.roles);

export const selectRoles = createSelector(
  (state: IStore) => rolesSelector.selectAll(state),
  roles => roles,
);

export const selectRole = (roleId: string) =>
  createSelector(
    (state: IStore) => rolesSelector.selectAll(state),
    roles => roles.find(role => role.id === roleId),
  );
