import Api, { INewTaskTemplate } from 'sb_manufacturing_front_api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import errorHandler from '~/utils/errorHandler';
import { closePopup } from '~/redux/modules/popupModule';
import store from '~/redux/configureStore';

export const tasksTemplatesAdapter = createEntityAdapter<INewTaskTemplate>({
  selectId: taskTemplate => taskTemplate.id,
  sortComparer: (a, b) => b.id - a.id,
});

export interface ITasksTemplatesState {
  ids: EntityId[];
  entities: Dictionary<INewTaskTemplate>;
}

export const removeTask = createAsyncThunk('removeTask', async (taskId: number) => {
  Api.deleteTask(taskId)
    .then(result => {
      if (result.kind === 'ok') {
        return null;
      } else errorHandler(result);
    })
    .catch(result => {
      errorHandler(result);
    });
});

export const removeTaskTemplate = createAsyncThunk(
  'removeTaskTemplate',
  async (taskTemplateId: number) => {
    Api.deleteTaskTemplate(taskTemplateId)
      .then(result => {
        if (result.kind === 'ok') {
          store.dispatch(closePopup());
          toast.success('Шаблон удален');
          return null;
        } else errorHandler(result);
      })
      .catch(result => {
        errorHandler(result);
      });
  },
);

const tasksTemplatesSlice = createSlice({
  name: 'tasksTemplates',
  initialState: tasksTemplatesAdapter.getInitialState(),
  reducers: {
    create: tasksTemplatesAdapter.setMany,
    update: tasksTemplatesAdapter.upsertMany,
    remove: tasksTemplatesAdapter.removeMany,
    clear: tasksTemplatesAdapter.removeAll,
  },
});

export default tasksTemplatesSlice.reducer;

export const { create, update, clear, remove } = tasksTemplatesSlice.actions;
