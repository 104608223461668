import { Bar } from 'react-chartjs-2';
import { COLORS } from '~/helpers/constants';
import 'chart.js/auto';
import { TooltipItem } from 'chart.js';
import convertTimeHM from '~/helpers/convertTimeHM';

interface ChartBar {
  data: any;
}

const ChartBar = ({ data }: ChartBar) => {
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        backgroundColor: COLORS.WHITE,
        titleColor: COLORS.DARK,
        titleMarginBottom: 8,
        bodyColor: COLORS.DARK,
        padding: 8,
        cornerRadius: 4,
        formattedValue: '23,123',
        callbacks: {
          label: (tooltipItem: TooltipItem<any>) => {
            return (
              tooltipItem.dataset.label +
              ': ' +
              convertTimeHM(Math.abs(parseInt(tooltipItem.parsed.y)), false).text
            );
          },
        },
      },
    },
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value: any) => {
            return Math.round(value / 60 / 60);
          },
        },
      },
    },
  };

  return (
    <div className="chartBar">
      <Bar options={options} data={data} className="chartBar__bar" />
    </div>
  );
};

export default ChartBar;
