import * as React from 'react';
import classNames from 'classnames';

interface ISVGIcon {
  color?: string;
}

export const ArrowDown = ({ color }: ISVGIcon) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('stroke-current', color)}
    >
      <path d="M1.46447 1.03551L5 4.57104L8.53553 1.03551" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
