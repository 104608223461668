import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { tasksGeoTypesAdapter } from '~/redux/modules/tasksGeoTypesModule';

export const tasksGeoTypesSelectors = tasksGeoTypesAdapter.getSelectors<IStore>(
  state => state.taskGeoTypes,
);

export const selectTasksGeoTypes = createSelector(
  (state: IStore) => tasksGeoTypesSelectors.selectAll(state),
  tasksGeoTypes => tasksGeoTypes,
);

export const selectTaskType = (taskGeoTypeId: number) =>
  createSelector(
    (state: IStore) => tasksGeoTypesSelectors.selectAll(state),
    tasksGeoTypes => tasksGeoTypes.find(taskGeoType => taskGeoType.id === taskGeoTypeId),
  );
