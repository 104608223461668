import { IColumn } from '~/redux/modules/reportsColumnsModule';

export const Header = ({ columns }: IColumn) => {
  return (
    <header className="list-table__row list-table__row_header">
      {columns
        .filter(column => column.check)
        .map((column, key) => (
          <div
            style={{ minWidth: column.width, maxWidth: column.width }}
            className={`list-table__col list-table__col_${column.name} ${
              column.check && 'visible'
            }`}
            key={key}
            data-name={column.name}
          >
            <span>{column.title}</span>
          </div>
        ))}
    </header>
  );
};
