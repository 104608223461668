import { toast } from 'react-toastify';
import { GeneralApiProblem } from 'sb_manufacturing_front_api';

const errorHandler = (result: GeneralApiProblem) => {
  switch (result.kind) {
    case 'bad-data':
      if (result.data) {
        if (typeof result.data === 'object') {
          Object.keys(result.data).map((text: any) => {
            if (result.data && typeof result.data[text] === 'object') {
              return Object.keys(result.data[text]).map((text2: any) => {
                return result.data && toast.error(`${result.data[text][text2]}`);
              });
            } else {
              return result.data && toast.error(`${result.data[text]}`);
            }
          });
        }
      } else toast.error(result.msg);
      break;
    default:
      toast.error(result.msg);
  }
};

export default errorHandler;
