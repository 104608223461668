import moment from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';

export const convertFn = (date: number) => moment(moment.unix(date).format('YYYY/MM/DD'));

export const prepareDateServerTime = (date: string, timezone: number) => {
  if (date) {
    return moment(date).subtract(timezone, 'hours').format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
  } else {
    return date;
  }
};

export const getLocalMomentTime = (datetime: string | undefined, timezone: number) => {
  if (datetime) {
    return moment(datetime).add(timezone, 'hours');
  } else {
    return moment().add(timezone, 'hours');
  }
};

export const getLocalTime = (datetime: string | undefined, timezone: number) =>
  getLocalMomentTime(datetime, timezone).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);

export const getLocalTimeSchedule = (datetime: string | undefined, timezone: number) =>
  datetime ? getLocalMomentTime(datetime, timezone).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS) : '';

export const getLocalTimeFormat = (
  datetime: string | undefined | null,
  timezone: number,
  format: string,
) => (datetime ? getLocalMomentTime(datetime, timezone).format(format) : '');

export const getCompanyTime = (time_zone: number) =>
  moment().subtract(moment().utcOffset(), 'minutes').add(time_zone, 'hours');
