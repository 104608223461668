import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';
import { tasksTimelessAdapter } from '~/redux/modules/tasksTimelessModule';
import { ITaskTimeless } from 'sb_manufacturing_front_api';
import moment from 'moment';
import { ETaskTimelessStatus } from 'sb_manufacturing_front_api';

export const tasksTimelessSelector = tasksTimelessAdapter.getSelectors<IStore>(
  state => state.taskTimeless,
);

export const selectTasksTimeless = createSelector(
  (state: IStore) => tasksTimelessSelector.selectAll(state),
  task => task,
);

export const selectTasksTimelessDefault = (departmentIds: number[]) =>
  createSelector(
    (state: IStore) => tasksTimelessSelector.selectAll(state),
    task =>
      task.filter(
        item =>
          item.status === ETaskTimelessStatus.NEW && departmentIds.includes(item.department_id),
      ),
  );

export const selectTasksTimelessMe = (
  filter: [string, boolean],
  userId: number,
  departmentIds: number[],
) =>
  createSelector(
    (state: IStore) => tasksTimelessSelector.selectAll(state),
    task => {
      const tasks = task.filter(item => {
        const user = item.tasks.map(t => t.user_id);
        if (
          [ETaskTimelessStatus.NEW, ETaskTimelessStatus.IN_PROGRESS].includes(item.status) &&
          user.includes(userId) &&
          departmentIds.includes(item.department_id)
        ) {
          return item;
        }
      });
      switch (filter[0]) {
        case 'name':
          return sortName(tasks, filter[1]);
        case 'date':
          return sortDate(tasks, filter[1]);
        default:
          return tasks;
      }
    },
  );

export const selectTasksTimelessComplete = (filter: [string, boolean], departmentIds: number[]) =>
  createSelector(
    (state: IStore) => tasksTimelessSelector.selectAll(state),
    task => {
      const tasks = task.filter(
        item =>
          [
            ETaskTimelessStatus.COMPLETE,
            ETaskTimelessStatus.EXPIRED,
            ETaskTimelessStatus.REJECTED,
          ].includes(item.status) && departmentIds.includes(item.department_id),
      );
      switch (filter[0]) {
        case 'name':
          return sortName(tasks, filter[1]);
        case 'date':
          return sortDate(tasks, filter[1]);
        default:
          return tasks;
      }
    },
  );

export const selectTasksTimelessInProgress = (filter: [string, boolean], departmentIds: number[]) =>
  createSelector(
    (state: IStore) => tasksTimelessSelector.selectAll(state),
    task => {
      const tasks = task.filter(
        item =>
          item.status === ETaskTimelessStatus.IN_PROGRESS &&
          departmentIds.includes(item.department_id),
      );
      switch (filter[0]) {
        case 'name':
          return sortName(tasks, filter[1]);
        case 'date':
          return sortDate(tasks, filter[1]);
        default:
          return tasks;
      }
    },
  );

const sortName = (tasks: ITaskTimeless[], active: boolean) => {
  if (active) {
    return tasks.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
  } else {
    return tasks
      .sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
      .reverse();
  }
};

const sortDate = (tasks: ITaskTimeless[], active: boolean) => {
  if (active) {
    return tasks.sort((a, b) => {
      return moment(a.start_date).valueOf() - moment(b.start_date).valueOf();
    });
  } else {
    return tasks
      .sort((a, b) => {
        return moment(a.start_date).valueOf() - moment(b.start_date).valueOf();
      })
      .reverse();
  }
};
