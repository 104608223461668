import * as React from 'react';

interface IActiveFilter {
  column: IColumnFilter;
}
const ActiveFilter = ({ column }: IActiveFilter) => {
  const columns = React.useMemo(() => column.data.filter(check => check.check), [column.data]);

  const counter = React.useMemo(() => {
    if (columns.length > 2) {
      return columns.length - 2;
    }
  }, [columns]);

  columns.length = 2;

  return (
    <>
      {columns.map(active => (
        <div className="active-filters__filter" key={active.id}>
          <span className="active-filters__filter-name">{active.name}</span>
        </div>
      ))}
      {counter && <span className="active-filters__filter-counter">{`+${counter}`}</span>}
    </>
  );
};

export default ActiveFilter;
