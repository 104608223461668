import * as React from 'react';
import {
  selectDataDepartments,
  selectDepartmentsByIdsFilter,
} from '~/redux/selectors/departmentsSelectors';
import { selectDataProfessions } from '~/redux/selectors/professionsSelectors';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { useOnClickOutside } from '~/hooks';
import {
  allFilter,
  resetFilter,
  setUsersFilters,
  toggleFilter,
} from '~/redux/reducers/UsersFiltersSlice';
import { useAppDispatch, useAppSelector } from '~/hooks/redux';
import { selectCurrentUserId, selectUserRoleCanEdit } from '~/redux/selectors/authSelectors';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { TYPE_ROLE } from '~/helpers/constants';
import FilterCol from '~/components/filters/FilterCol';

export const UsersFilters = () => {
  const filterPopupRef = React.useRef<HTMLDivElement>(null);
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [currentFilterName, setCurrentFilterName] = React.useState('');

  const { usersFilters } = useAppSelector(state => state.usersFilters);
  const dataProfessions = useAppSelector(selectDataProfessions);
  const dataDepartments = useAppSelector(selectDataDepartments);
  const userId = useAppSelector(selectCurrentUserId);
  const currentUser = useAppSelector(selectUser(userId));
  const dataDepartmentsView = useAppSelector(
    selectDepartmentsByIdsFilter(currentUser?.departments_ids ?? []),
  );
  const userRoleCanEdit = useAppSelector(selectUserRoleCanEdit);

  const dispatch = useAppDispatch();

  const filterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (popupVisible) {
      setPopupVisible(false);
    } else if (filterPopupRef.current) {
      filterPopupRef.current.style.left =
        event.currentTarget.offsetLeft + event.currentTarget.children[0].clientWidth + 25 + 'px';
      usersFilters.map(obj => {
        if (event.currentTarget.dataset.name && obj.name === event.currentTarget.dataset.name) {
          setCurrentFilterName(event.currentTarget.dataset.name);
          filterPopupRef.current && filterPopupRef.current.classList.add('open');
          setPopupVisible(true);
        }
      });
    }
  };

  const filterCurrent = usersFilters.find(filter => filter.name === currentFilterName);

  const checkedFilters = React.useMemo(() => {
    const checkedFiltersArr = filterCurrent?.data.filter(el => el.check);
    return checkedFiltersArr && checkedFiltersArr.length > 0;
  }, [filterCurrent]);

  const onClose = () => {
    filterPopupRef.current && filterPopupRef.current.classList.remove('open');
    setPopupVisible(false);
  };

  useOnClickOutside(filterPopupRef, popupVisible ? onClose : () => {});

  const checkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.dataset.id);
    const name = event.target.dataset.filterName;
    if (!id || !name) return;
    dispatch(toggleFilter({ id, name }));
  };

  const chooseAll = (event: React.MouseEvent<HTMLDivElement>) => {
    const name = event.currentTarget.dataset.filter;
    if (!name) return;
    dispatch(allFilter({ name }));
  };

  const resetAll = (event: React.MouseEvent<HTMLDivElement>) => {
    const name = event.currentTarget.dataset.filter;
    if (!name) return;

    dispatch(resetFilter({ name }));
  };

  const usersDefaultFilters = React.useMemo(
    () => [
      {
        title: 'Цех',
        name: TYPE_ROLE.DEPARTMENT,
        data: userRoleCanEdit ? dataDepartments : dataDepartmentsView,
      },
      {
        title: 'Профессия',
        name: 'profession',
        data: dataProfessions,
      },
      {
        title: 'Разряд',
        name: 'category',
        data: [
          { id: 1, name: '1', check: false },
          { id: 2, name: '2', check: false },
          { id: 3, name: '3', check: false },
          { id: 4, name: '4', check: false },
          { id: 5, name: '5', check: false },
          { id: 6, name: '6', check: false },
        ],
      },
    ],
    [dataProfessions, dataDepartments, JSON.stringify(dataDepartmentsView)],
  );

  React.useEffect(() => {
    dispatch(setUsersFilters(usersDefaultFilters));
  }, [usersDefaultFilters]);

  return (
    <div className="list-table__row list-table__row_header_filters">
      {usersFilters.map((column, key) => {
        if (column.data.length <= 1) return null;
        return (
          <FilterCol key={key} column={column} filterClick={filterClick} filters={usersFilters} />
        );
      })}
      <div className="filter-popup" ref={filterPopupRef}>
        <button className="filter-popup__close" onClick={onClose}>
          <CloseSvg />
        </button>
        <div className="filter-popup__name">{filterCurrent?.title}</div>

        {!checkedFilters && (
          <div
            className="filter-popup__choose-all"
            onClick={chooseAll}
            data-filter={filterCurrent?.name}
          >
            Выбрать все
          </div>
        )}
        {checkedFilters && (
          <div className="filter-popup__reset" onClick={resetAll} data-filter={filterCurrent?.name}>
            Сбросить все
          </div>
        )}
        <div className="filter-popup__content">
          {filterCurrent?.data
            .filter(data => !data.deleted)
            .map((data, key) => (
              <div className="rf-content__line" key={key}>
                <input
                  type="checkbox"
                  onChange={checkChange}
                  data-id={data.id}
                  data-filter-name={filterCurrent.name}
                  data-name={data.name}
                  checked={data.check}
                />
                <span>{data.name}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
