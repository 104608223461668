import * as React from 'react';
import { useSelector } from 'react-redux';
import { INewShift } from 'sb_manufacturing_front_api';
import { selectWorkShiftId } from '~/redux/selectors/stateSelectors';
import Button from '~/components/form/buttons/Button';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';

type templatesScrollerProps = {
  taskContainer: React.RefObject<HTMLDivElement>;
  users: INewShift[];
};

const TaskScroller = ({ taskContainer, users }: templatesScrollerProps) => {
  const [container, setContainer] = React.useState(taskContainer.current);
  const workShiftId = useSelector(selectWorkShiftId);

  React.useEffect(() => {
    setTimeout(() => {
      taskContainer.current && setContainer(taskContainer.current);
    });
  }, [users, workShiftId]);

  const [offsetLeft, setOffsetLeft] = React.useState(0);
  const templateElem = container?.children[0]?.children[1]
    ? container.children[0].children[1].clientWidth
    : 0;

  container?.addEventListener('scroll', () => {
    container && setOffsetLeft(container.scrollLeft);
  });

  const handleScrollLeft = () => {
    container &&
      container.scrollTo({
        left: Math.ceil(container.scrollLeft / templateElem) * templateElem - templateElem,
        behavior: 'smooth',
      });
  };

  const handleScrollRight = () => {
    container &&
      container.scrollTo({
        left: Math.ceil(container.scrollLeft / templateElem) * templateElem + templateElem,
        behavior: 'smooth',
      });
  };

  return (
    <nav className="task-carousel__arrows">
      <Button
        type={BUTTON_TYPE.ICON}
        onClick={handleScrollLeft}
        className="calendar-prev"
        icon
        iconType={ICON_TYPE.ARROW_PREV}
        disabled={offsetLeft === 0}
      />
      <Button
        type={BUTTON_TYPE.ICON}
        onClick={handleScrollRight}
        className="calendar-next"
        icon
        iconType={ICON_TYPE.ARROW_NEXT}
        disabled={
          container
            ? users.length === 0 || container.scrollLeft === 0 - container.offsetWidth
            : true
        }
      />
    </nav>
  );
};

export default TaskScroller;
