import * as React from 'react';
import { Spoiler } from '~/components';
import { ReactComponent as ArrowRightSvg } from '~/assets/arrow-right.svg';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '~/redux/modules/authModule';
import Api, { INewUserWithTokenResult } from 'sb_manufacturing_front_api';
import { toast } from 'react-toastify';
import store from '~/redux/configureStore';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { closePopup } from '~/redux/modules/popupModule';
import Popup from '~/components/popup/Popup';
import { PASSWORD, COLORS, NAMES_FIELD, INPUT_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import InputsField from '~/components/form/input/Input';
import onInput from '~/utils/onInput';
import { ValidationSubmit } from '~/utils/validations';
import errorHandler from '~/utils/errorHandler';

interface ISetPasswordPopup {
  type: string;
}

const SetPasswordPopup = ({ type }: ISetPasswordPopup) => {
  const dispatch = useDispatch();
  const companyOptions = useSelector(selectCompanyOptions);

  const isSetType = type === PASSWORD.SET;
  const isChangeType = type === PASSWORD.CHANGE;
  const isForcedChangeType = type === PASSWORD.FORCED_CHANGE;

  const [loading, setLoading] = React.useState(false);

  const [state, setState] = React.useState({
    [NAMES_FIELD.OLD_PASSWORD]: '',
    [NAMES_FIELD.NEW_PASSWORD]: '',
    [NAMES_FIELD.REPEAT_NEW_PASSWORD]: '',
  });

  const [errors, setErrors] = React.useState({
    [NAMES_FIELD.OLD_PASSWORD]: '',
    [NAMES_FIELD.NEW_PASSWORD]: '',
    [NAMES_FIELD.REPEAT_NEW_PASSWORD]: '',
  });

  const [regExps, setRegExps] = React.useState([
    {
      regExp: '(?=.{8,})',
      match: false,
      text: 'Длина пароля должна быть не менее 8 символов',
    },
    {
      regExp: '(?=.*[0-9])',
      match: false,
      text: 'Пароль должен содержать цифры',
    },
    {
      regExp: '(?=.*[A-Z])(?=.*[a-z])',
      match: false,
      text: 'Пароль должен содержать заглавные и строчные буквы',
    },
    {
      regExp: `(?=.*[!#$%*&+ -./:;<=>?@^_{|}])`,
      match: false,
      text: 'Пароль должен содержать хотя бы один спецсимвол (@, #, $, &, *, % и т.п.)',
    },
  ]);

  const handleSubmit = async () => {
    ValidationSubmit({
      state,
      setErrors,
    });

    if (Object.keys(errors).length !== 0) return;

    setLoading(true);

    const result: INewUserWithTokenResult = await Api.changePassword({
      old_password: state[NAMES_FIELD.OLD_PASSWORD],
      new_password: state[NAMES_FIELD.NEW_PASSWORD],
    });

    setLoading(false);

    if (result.kind === 'ok') {
      const payload = [result.data];
      store.dispatch({ type: 'users/update', payload });
      toast.success('Данные обновлены');
      dispatch(closePopup());
      dispatch(signOut());
    } else errorHandler(result);
  };

  const handelOnInput = (value: string, name: string) => {
    onInput({
      value,
      name,
      state,
      setState,
      setErrors,
      setRegExps,
      regExps,
      use_strong_security: companyOptions?.use_strong_security,
    });
  };

  return (
    <Popup className="setPasswordPopup" closable={isChangeType}>
      <>
        <div className="passwordPopupHeader">
          {isSetType ? 'Установите пароль' : 'Смена пароля'}
        </div>
        {isForcedChangeType && (
          <div className="passwordPopupDesc">
            Истек срок действия пароля.
            <br />
            Доступ к сайту заблокирован до смены пароля
          </div>
        )}
        <div className="setPasswordPopup__block">
          <InputsField
            name={NAMES_FIELD.OLD_PASSWORD}
            label={isSetType ? 'Временный пароль' : 'Текущий пароль'}
            placeholder={isSetType ? 'Введите Ваш временный пароль' : 'Введите текущий пароль'}
            type={INPUT_TYPE.PASSWORD}
            errorBorder={!!errors[NAMES_FIELD.OLD_PASSWORD]}
            error={errors[NAMES_FIELD.OLD_PASSWORD]}
            onInput={(value, name) => handelOnInput(value, name)}
          />
        </div>
        <div className="setPasswordPopup__block">
          <InputsField
            name={NAMES_FIELD.NEW_PASSWORD}
            label="Новый пароль"
            placeholder="Придумайте новый пароль"
            type={INPUT_TYPE.PASSWORD}
            errorBorder={!!errors[NAMES_FIELD.NEW_PASSWORD]}
            error={errors[NAMES_FIELD.NEW_PASSWORD]}
            onInput={(value, name) => handelOnInput(value, name)}
          />
          {companyOptions?.use_strong_security && (
            <div className="passwordRequirements">
              <ul>
                {regExps.map((obj, key) => (
                  <li
                    key={key}
                    className={`${
                      state[NAMES_FIELD.NEW_PASSWORD].length > 0
                        ? obj.match
                          ? 'correct'
                          : 'uncorrect'
                        : ''
                    }`}
                  >
                    {obj.text}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <Spoiler title="Рекомендации">
            Пароль не должен включать в себя легко вычисляемые сочетания символов (имена, фамилии,
            известные названия, словарные и жаргонные слова и т.д.), последовательности символов и
            знаков (111, qwerty, abcd и т.д.), общепринятые сокращения (ЭВМ, ЛВС, USER и т.п.),
            аббревиатуры, клички домашних животных, номера автомобилей, телефонов, паспортные данные
            и другие значимые сочетаний букв и знаков, которые можно угадать, основываясь на
            информации о пользователе.
          </Spoiler>
        </div>
        <InputsField
          name={NAMES_FIELD.REPEAT_NEW_PASSWORD}
          label="Повторите пароль"
          placeholder="Повторите новый пароль"
          disabled={companyOptions?.use_strong_security && !!regExps.find(obj => !obj.match)}
          type={INPUT_TYPE.PASSWORD}
          errorBorder={!!errors[NAMES_FIELD.REPEAT_NEW_PASSWORD]}
          error={errors[NAMES_FIELD.REPEAT_NEW_PASSWORD]}
          onInput={(value, name) => handelOnInput(value, name)}
        />
        <div className="buttonLine">
          {!isChangeType && (
            <div
              className="aside-popup__header-button aside-popup__header-button_log-out"
              onClick={() => dispatch(signOut())}
            >
              Выход
              <ArrowRightSvg />
            </div>
          )}
          <Button
            text={loading ? 'Меняю..' : 'Сменить'}
            disabled={loading}
            background={COLORS.BLUE}
            onClick={handleSubmit}
            small
          />
        </div>
      </>
    </Popup>
  );
};

export default SetPasswordPopup;
