const containsArray = (first: number[], last: number[]) => {
  const isContains: boolean[] = [];

  for (let i = 0; i < last.length; i++) {
    isContains.push(first.includes(last[i]));
  }

  return isContains.includes(true);
};

export default containsArray;
