export const Calendar24 = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 11.1062H16.5" stroke="#B0B2B3" strokeWidth="2" />
      <rect
        x="7.5"
        y="7.84912"
        width="9"
        height="8.89291"
        rx="1"
        stroke="#B0B2B3"
        strokeWidth="2"
      />
      <rect x="8.5" y="5.74316" width="2" height="2.36297" fill="#B0B2B3" />
      <rect x="13.5" y="5.74316" width="2" height="2.36304" fill="#B0B2B3" />
    </svg>
  );
};
