import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, {
  IUserFilteredList,
  UsersFilteredList,
  INewUserResult,
  FireRecoverResult,
} from 'sb_manufacturing_front_api';
import errorHandler from '~/utils/errorHandler';
import { toast } from 'react-toastify';

export const fetchUsers = createAsyncThunk('user/fetchAll', async (input: IUserFilteredList) => {
  const response: UsersFilteredList = await Api.getUsers(input);
  if (response.kind === 'ok') {
    return response.data;
  } else errorHandler(response);
});

export const updateProfile = createAsyncThunk(
  'updateProfile',

  async (input: any) => {
    const response: INewUserResult = await Api.updateProfile(input);
    if (response.kind === 'ok') {
      return response as INewUserResult;
    } else errorHandler(response);
  },
);

export const updateUser = createAsyncThunk(
  'updateUser',

  async (input: any) => {
    const response: INewUserResult = await Api.updateUser(input);
    if (response.kind === 'ok') {
      return response as INewUserResult;
    } else errorHandler(response);
  },
);

export const createUser = createAsyncThunk(
  'createUser',

  async (input: any) => {
    const response: INewUserResult = await Api.createUser(input);
    if (response.kind === 'ok') {
      return response as INewUserResult;
    } else errorHandler(response);
  },
);

export const fireUser = createAsyncThunk(
  'fireUser',

  (id: number) => {
    return Api.fireUser(id)
      .then(result => {
        if (result.kind === 'ok') {
          toast.success('Сотрудник уволен!');
          return result as FireRecoverResult;
        } else {
          errorHandler(result);
        }
      })
      .catch(result => {
        errorHandler(result);
      });
  },
);

export const recoverUser = createAsyncThunk(
  'recoverUser',

  (id: number) => {
    return Api.recoverUser(id)
      .then(result => {
        if (result.kind === 'ok') {
          toast.success('Сотрудник восстановлен!');
          return result as FireRecoverResult;
        } else {
          errorHandler(result);
        }
      })
      .catch(result => {
        errorHandler(result);
      });
  },
);
