import moment from 'moment';
import { INewShift } from 'sb_manufacturing_front_api';
import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { FORMAT_MOMENT } from '~/helpers/constants';
import useShiftTime from '~/hooks/useShiftTime';

interface ITaskTrackingOnTableProps {
  users: INewShift[];
  dinner: {
    userId: number;
    dinnerStartTime?: string;
    dinnerEndTime?: string;
    hasDinner?: boolean;
  };
}

const HOUR_WIDTH = 299.9;
const PX_PER_SECOND = 96 / 60 / 60;

const TaskDinner = ({ users, dinner }: ITaskTrackingOnTableProps) => {
  const order: number = users.findIndex(u => u.user_id === dinner.userId);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const userId = useSelector(selectCurrentUserId);
  const { beginTime } = useShiftTime();
  const isMeShift = users.map(item => item.user_id).includes(userId);

  if (!dinner.hasDinner || order === -1) {
    return null;
  }

  const timeStart = moment(dinner.dinnerStartTime, FORMAT_MOMENT.HHMMSS);
  const timeStartReal = moment(dinner.dinnerStartTime, FORMAT_MOMENT.HHMMSS)
    .add(timeZone, 'hours')
    .format(FORMAT_MOMENT.HHMMSS);
  const timeEnd = moment(dinner.dinnerEndTime, FORMAT_MOMENT.HHMMSS);
  const timeLocal = moment(`${moment(beginTime).format(FORMAT_MOMENT.YYYYMMDD)} ${timeStartReal}`);

  const startDinner = timeStart.add(timeZone, 'hours').format(FORMAT_MOMENT.HHMM);
  const endDinner = timeEnd.add(timeZone, 'hours').format(FORMAT_MOMENT.HHMM);

  const xPos = order * HOUR_WIDTH;

  const yPos = (timeLocal.unix() - moment(beginTime).unix()) * PX_PER_SECOND;

  const height = (timeEnd.unix() - timeStart.unix()) * PX_PER_SECOND;

  return (
    <div
      style={{
        position: 'absolute',
        left: !isMeShift ? xPos : (userId === dinner.userId ? xPos : xPos + 48) || 0,
        top: yPos,
        width: HOUR_WIDTH,
        height: height,
      }}
    >
      <div className="taskDinner">
        <span className="taskDinner__time">
          {startDinner} - {endDinner}
        </span>
        <span className="taskDinner__title">Обед</span>
      </div>
    </div>
  );
};

export default TaskDinner;
