import { IGenericDirectory } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const tasksGeoTypesAdapter = createEntityAdapter<IGenericDirectory>({
  selectId: taskGeoType => taskGeoType.id,
});

export interface ITasksGeoTypesState {
  ids: EntityId[];
  entities: Dictionary<IGenericDirectory>;
}

const tasksGeoTypesSlice = createSlice({
  name: 'taskGeoTypes',
  initialState: tasksGeoTypesAdapter.getInitialState(),
  reducers: {
    create: tasksGeoTypesAdapter.setMany,
    update: tasksGeoTypesAdapter.upsertMany,
    remove: tasksGeoTypesAdapter.removeMany,
    clear: tasksGeoTypesAdapter.removeAll,
  },
});

export default tasksGeoTypesSlice.reducer;

export const { create, update, remove, clear } = tasksGeoTypesSlice.actions;
