import Legends from '~/components/statistic/Legends';
import convertTimeHM from '~/helpers/convertTimeHM';

interface IColumns {
  color: string;
  value: number;
}

interface IChartTasks {
  columns: IColumns[];
  legends?: any;
}

interface ILineItem {
  height: number;
  text: string;
  color: string;
}

const ChartDiagram = ({ columns, legends }: IChartTasks) => {
  const maxElem = Math.max(...columns.flatMap(({ value }) => value));
  const lineItems: ILineItem[] = [];
  columns.forEach(column => {
    lineItems.push({
      height: (column.value * 100) / maxElem,
      text: convertTimeHM(column.value, false).text,
      color: column.color,
    });
  });

  return (
    <div className="chartTasks chartDiagrams">
      {legends && <Legends items={legends} className="chartTasks__legend" />}

      <div className="chartTasks__lines">
        {lineItems.map(item => (
          <div
            className={`chartTasks__linesItem ${item.color}`}
            style={{
              height: item.height,
              backgroundColor: item.color,
            }}
          >
            <span
              style={{
                backgroundColor: '#1111113A',
              }}
            >
              {item.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChartDiagram;
