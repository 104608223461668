import { IGenericDirectory } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const tasksTypesAdapter = createEntityAdapter<IGenericDirectory>({
  selectId: taskType => taskType.id,
});

export interface ITasksTypesState {
  ids: EntityId[];
  entities: Dictionary<IGenericDirectory>;
}

const tasksTypesSlice = createSlice({
  name: 'taskTypes',
  initialState: tasksTypesAdapter.getInitialState(),
  reducers: {
    create: tasksTypesAdapter.setMany,
    update: tasksTypesAdapter.upsertMany,
    remove: tasksTypesAdapter.removeMany,
    clear: tasksTypesAdapter.removeAll,
  },
});

export default tasksTypesSlice.reducer;

export const { create, update, remove, clear } = tasksTypesSlice.actions;
