import * as React from 'react';
import { INewUser } from 'sb_manufacturing_front_api';
import { useSelector } from 'react-redux';
import { selectDepartment } from '~/redux/selectors/departmentsSelectors';
import { selectProfession } from '~/redux/selectors/professionsSelectors';
import Button from '~/components/form/buttons/Button';
import { COLORS, BUTTON_TYPE } from '~/helpers/constants';

export interface IUserComponent extends INewUser {
  onClick: (id: number) => void;
}

export const User = ({
  id,
  onClick,
  firstname,
  lastname,
  middlename,
  departments_ids,
  profession_id,
}: IUserComponent) => {
  const departmentName = useSelector(selectDepartment(departments_ids?.[0]))?.name;
  const professionName = useSelector(selectProfession(profession_id))?.name;
  const fio = React.useMemo(() => {
    return `${lastname.firstToUpper()} ${firstname.firstToUpper()} ${middlename.firstToUpper()}`;
  }, [firstname, lastname, middlename]);

  const handleClick = React.useCallback(() => {
    onClick(id);
  }, [onClick, id]);
  return (
    <div className="alphabet-person" onClick={handleClick}>
      <div className="alphabet-person__row">
        <div className="alphabet-person__name">{fio}</div>
      </div>
      <div className="alphabet-person__row">
        {departmentName && (
          <p className="alphabet-person__specialisation">
            {departmentName}
            {' · '}
          </p>
        )}
        {professionName && <p className="alphabet-person__specialisation">{professionName}</p>}
      </div>
      <Button
        className="alphabet-person__add-btn"
        text="добавить"
        type={BUTTON_TYPE.ICON}
        onClick={() => {}}
        background={COLORS.TRANSPARENT}
      />
    </div>
  );
};
