import { FC, useMemo } from 'react';
import moment from 'moment';
import { CalendarDay } from './day';

interface ICalendarWeekProps {
  current: number;
  selected: number;
  month: number;
  onPressDay: (value: number) => void;
}

const defaultWeekdays: number[] = Array.from({ length: 7 }, (_, d) => d);
const defaultKeyExtractor = (date: moment.Moment): string => date.format('DD-MMMM-YYYY');

/**
 * 
  useEffect(() => {
    console.log(
      moment().startOf('day').unix() === selectedMonth
        ? 'todayIsSelected'
        : 'todaysIsNotSelected'
    )
  }, [selectedMonth])
 */

export const CalendarWeek: FC<ICalendarWeekProps> = ({ current, month, selected, onPressDay }) => {
  const days = useMemo(() => {
    const start: moment.Moment = moment.unix(current).startOf('week');
    const currentMonth: number = moment.unix(month).startOf('month').month();

    return defaultWeekdays.map(weekday => {
      const date: moment.Moment = start.clone().set('isoWeekday', weekday + 1);
      return {
        date: date.month() === currentMonth ? date : moment.unix(0),
        key: defaultKeyExtractor(date),
      };
    });
  }, [current, month]);

  return (
    <>
      {days.map(({ key, date }) => (
        <CalendarDay key={key} value={date.unix()} selected={selected} onClick={onPressDay} />
      ))}
    </>
  );
};
