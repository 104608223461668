import React from 'react';
import { TaskNoTimeInside } from '~/containers/tasks/task/info/TaskNoTimeInside';
import { ITaskTimeless } from 'sb_manufacturing_front_api';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaskType } from '~/redux/selectors/tasksTypesSelectors';
import { DECLINATION_TYPE } from '~/helpers/constants';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import moment from 'moment';
import textDeclension from '~/helpers/textDeclension';
import CheckboxesField from '~/components/form/checkbox/Checkbox';
import Priority from '~/components/priority/Priority';

interface ITaskNoTimeInWorkInfo {
  task: ITaskTimeless;
  isMe?: boolean;
  startDate?: string;
  endDate?: string;
}

export const TaskNoTimeInWorkInfo: React.FC<ITaskNoTimeInWorkInfo> = ({
  task,
  isMe,
  startDate,
  endDate,
}) => {
  const dispatch = useDispatch();
  const taskType = useSelector(selectTaskType(Number(task?.type_id)));
  const userId: number = useSelector(selectCurrentUserId);
  const meTask = task.tasks.filter(item => item.user_id === userId);

  const hours: number = task?.duration ? Math.floor(task?.duration / 60 / 60) : 0;
  const minutes: number = task?.duration ? Math.floor(task?.duration / 60) - hours * 60 : 0;

  const handelHistory = () => {
    const taskLogsAll = [...task?.logs];
    const taskLogs = task.tasks.map(item => item?.logs);
    const logs = taskLogsAll.concat(...taskLogs);
    const sortLogs = logs.sort((a, b) => {
      return moment(a.date).valueOf() - moment(b.date).valueOf();
    });
    dispatch(
      openPopup({
        name: POPUPS_NAME.HISTORY_TASK_POPUP,
        data: { logs: sortLogs },
      }),
    );
  };

  return (
    <div className="task-no-time-in-work-info">
      <div className="task-no-time-in-work-info__task">
        <div className="task-no-time-in-work-info__block">
          <div className="task-no-time-in-work-info__title">Данные о задаче</div>
          {task?.description ? (
            <>
              <div className="task-no-time-in-work-info__block-title">Описание</div>
              <div className="task-no-time-in-work-info__block-subtitle">{task?.description}</div>
            </>
          ) : null}
          {hours || minutes ? (
            <>
              <div className="task-no-time-in-work-info__block-title">Рекомендованное время</div>
              <div className="task-no-time-in-work-info__block-subtitle">
                {hours ? `${textDeclension(hours, DECLINATION_TYPE.HOURS)}` : null}
                {minutes ? ` ${textDeclension(minutes, DECLINATION_TYPE.MINUTES)}` : null}
              </div>
            </>
          ) : null}
          <div className="task-no-time-in-work-info__block-title">Тип задачи</div>
          <div className="task-no-time-in-work-info__block-subtitle">{taskType?.name}</div>
          <div className="task-no-time-in-work-info__block-title">Приоритет</div>
          <Priority id={task.priority} />
        </div>
        <div className="task-no-time-in-work-info__title">Дополнительно</div>
        {task.need_supervisor_test ? (
          <CheckboxesField label="Требует проверки начальника смены" defaultValue={true} disabled />
        ) : null}
        {task.need_department_test ? (
          <CheckboxesField
            label="Требует проверки начальника участка"
            defaultValue={true}
            disabled
          />
        ) : null}
        {task.need_comment ? (
          <CheckboxesField label="Требует комментария работника" defaultValue={true} disabled />
        ) : null}
        {task.need_photo_report ? (
          <CheckboxesField label="Требует фотоотчет перед началом" defaultValue={true} disabled />
        ) : null}
        <div className="task-no-time-in-work-info__history" onClick={handelHistory}>
          История всей задачи
        </div>
      </div>
      <div className="task-no-time-in-work-info__workers">
        {isMe ? (
          <>
            {meTask.map((item, key) => (
              <TaskNoTimeInside
                key={key}
                taskId={item.id}
                duration={task.duration}
                startDate={startDate}
                endDate={endDate}
              />
            ))}
          </>
        ) : (
          <>
            {task.tasks.map((item, key) => (
              <TaskNoTimeInside
                key={key}
                taskId={item.id}
                duration={task.duration}
                startDate={startDate}
                endDate={endDate}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
