import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { taskTimelessTemplatesAdapter } from '~/redux/modules/tasksTimelessTemplatesModule';

export const taskTimelessTemplatesSelector = taskTimelessTemplatesAdapter.getSelectors<IStore>(
  state => state.taskTimelessTemplates,
);

export const selectTimelessTemplates = createSelector(
  (state: IStore) => taskTimelessTemplatesSelector.selectAll(state),
  taskTimelessTemplates => taskTimelessTemplates,
);
