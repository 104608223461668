import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { tasksTemplatesAdapter } from '~/redux/modules/tasksTemplatesModule';

export const tasksTemplatesSelector = tasksTemplatesAdapter.getSelectors<IStore>(
  state => state.tasksTemplates,
);

export const selectTemplates = createSelector(
  (state: IStore) => tasksTemplatesSelector.selectAll(state),
  tasksTemplates => tasksTemplates,
);
