import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INewUser } from 'sb_manufacturing_front_api';
import Popup from '~/components/popup/Popup';
import { UserSearch } from './add-user/search';
import { UserBadge } from './add-user/user-badge';
import { Alphabet } from './add-user/alphabet';
import { selectTodayUsers, selectWorkingUsers } from '~/redux/selectors/usersSelectors';
import {
  selectDay,
  selectDepartmentId,
  selectDepartmentIds,
  selectWorkShiftId,
  selectWorkShiftIds,
} from '~/redux/selectors/stateSelectors';
import { selectShifts } from '~/redux/selectors/shiftsSelectors';
import { addUserShift, removeShift } from '~/redux/modules/shiftsModule';
import { selectWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import moment from 'moment';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { prepareDateServerTime } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT } from '~/helpers/constants';
import containsArray from '~/helpers/containsArray';
import useAuthRole from '~/hooks/useAuthRole';
import { selectVacationsAtDate } from '~/redux/selectors/vacationsSelectors';

const AddUserPopup = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = React.useState<INewUser[]>([]);
  const shifts = useSelector(selectShifts);
  const departmentIds = useSelector(selectDepartmentIds);
  const departmentId = useSelector(selectDepartmentId);
  const usersList = useSelector(selectWorkingUsers);
  const usersToday = useSelector(selectTodayUsers);
  const workShiftId = useSelector(selectWorkShiftId);
  const workShiftIds = useSelector(selectWorkShiftIds);
  const currentDay = useSelector(selectDay);
  const workingShifts = useSelector(selectWorkingShifts);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const workingShift = workingShifts.find(
    item => item.id === (workShiftIds.length === 1 ? workShiftIds[0] : workShiftId),
  );
  const vacations = useSelector(selectVacationsAtDate(currentDay));
  const usersTodayIds = usersToday
    .map(item => item.id)
    .filter(userId => !vacations.some(vacation => vacation.user_id === userId));
  const { isSupervisor } = useAuthRole();

  React.useEffect(() => {
    setUsers(usersList);
  }, [usersList]);

  const handleRemoveUser = (userId: number, name?: string) => {
    const shift = shifts.find(
      shift => shift.working_shift_id === workingShift?.id && shift.user_id === userId,
    );

    if (shift) {
      dispatch(removeShift({ shiftId: shift.id, name }));
    }
  };

  const handleAddUser = (userId: number) => {
    const workShiftDay = moment(currentDay, FORMAT_MOMENT.DASH_YYYYMMDD);
    const beginTime = moment(workingShift?.begin_time, FORMAT_MOMENT.HHMMSS).add(timeZone, 'hours');

    workShiftDay.hours(moment(beginTime).hours());
    workShiftDay.minutes(moment(beginTime).minutes());
    workShiftDay.seconds(moment(beginTime).seconds());

    const start_time = prepareDateServerTime(
      workShiftDay.format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
      timeZone,
    );
    dispatch(
      addUserShift({
        workShiftId: workShiftIds.length === 1 ? workShiftIds[0] : workShiftId,
        start_time,
        userId,
      }),
    );
  };

  const handleSearchChange = React.useCallback((values: INewUser[]) => {
    setUsers(values);
  }, []);

  const filteredUsers = users.filter((user: any) => {
    if (isSupervisor) {
      return user?.departments_ids?.includes(departmentId) && !usersTodayIds.includes(user.id);
    }
    return containsArray(departmentIds, user.departments_ids) && !usersTodayIds.includes(user.id);
  });

  return (
    <Popup aside>
      <AsidePopupHeader>
        <h2 className="aside-popup__title aside-popup__title_notifications">
          Добавить сотрудника в смену
        </h2>
      </AsidePopupHeader>
      <AsidePopupBody>
        <UserSearch onSearchChange={handleSearchChange} />
        <div className="add-person-popup__added">
          {usersToday.map(user => (
            <UserBadge key={user.id} onRemove={handleRemoveUser} user={user} />
          ))}
        </div>
        <Alphabet value={filteredUsers} onUserAdd={handleAddUser} />
      </AsidePopupBody>
    </Popup>
  );
};

export default AddUserPopup;
