import cx from 'classnames';
import { CSSProperties, useMemo, useRef } from 'react';

interface IPopover {
  show: boolean;
  anchorEl?: HTMLElement | null;
  anchorPosition?: 'left' | 'right' | 'center';
  verticalAnchorPosition?: 'top' | 'bottom' | 'center';
  additionalLeft?: number;
  additionalTop?: number;
  fullWidth?: boolean;
  style?: CSSProperties;
}

export const Popover: React.FC<IPopover> = ({
  show,
  children,
  anchorEl,
  anchorPosition = 'left',
  verticalAnchorPosition = 'top',
  additionalLeft = 0,
  additionalTop = 0,
  fullWidth = false,
  style,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  const horizontalOffset = useMemo(() => {
    if (anchorPosition === 'left') {
      return additionalLeft;
    } else if (anchorPosition === 'right') {
      return (anchorEl?.clientWidth || 0) + additionalLeft;
    } else {
      return Math.round((anchorEl?.clientWidth || 0) / 2) + additionalLeft;
    }
  }, [anchorEl, anchorPosition, additionalLeft]);

  const verticalOffset = useMemo(() => {
    if (verticalAnchorPosition === 'top') {
      return additionalTop;
    } else if (verticalAnchorPosition === 'bottom') {
      return (anchorEl?.clientHeight || 0) + additionalTop;
    } else {
      return (
        Math.round((anchorEl?.clientHeight || 0) / 2) -
        Math.round((popoverRef.current?.clientHeight || 0) / 2) +
        additionalTop
      );
    }
  }, [anchorEl, verticalAnchorPosition, additionalTop]);

  return (
    <div
      ref={popoverRef}
      className={cx('popover', { popover__active: show })}
      style={{
        left: horizontalOffset,
        top: verticalOffset,
        width: fullWidth ? '100%' : 'auto',
        ...style,
      }}
    >
      <div className="popover__body">{children}</div>
    </div>
  );
};
