import { Dispatch } from 'react';
import { SetStateAction } from 'react';
import { ValidationItem } from '~/utils/validations';
import { NAMES_FIELD } from '~/helpers/constants';

interface IOnInput {
  value: string | boolean | number;
  name: string;
  state: IStateForm;
  setState: Dispatch<SetStateAction<IStateForm>>;
  setErrors: Dispatch<SetStateAction<{ [name: string]: string }>>;
  setRegExps?: Dispatch<SetStateAction<{ regExp: string; match: boolean; text: string }[]>>;
  regExps?: { regExp: string; match: boolean; text: string }[];
  use_strong_security?: boolean;
}

const onInput = ({
  value,
  name,
  state,
  setErrors,
  setState,
  use_strong_security,
  setRegExps,
  regExps,
}: IOnInput) => {
  setErrors(prevState => {
    if (name === NAMES_FIELD.NEW_PASSWORD) {
      delete prevState[NAMES_FIELD.REPEAT_NEW_PASSWORD];
    }
    delete prevState[name];
    return {
      ...prevState,
    };
  });

  setErrors(prevState => {
    return {
      ...prevState,
      ...ValidationItem({
        name,
        value,
        state,
        use_strong_security,
        setRegExps,
        regExps,
      }),
    };
  });

  setState(prevState => {
    return {
      ...prevState,
      [name]: value,
    };
  });
};

export default onInput;
