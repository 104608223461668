import { INewProfession } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const professionsAdapter = createEntityAdapter<INewProfession>({
  selectId: profession => profession.id,
});

export interface IProfessionsState {
  ids: EntityId[];
  entities: Dictionary<INewProfession>;
}

const professionsSlice = createSlice({
  name: 'professions',
  initialState: professionsAdapter.getInitialState(),
  reducers: {
    create: professionsAdapter.setMany,
    update: professionsAdapter.upsertMany,
    remove: professionsAdapter.removeMany,
    clear: professionsAdapter.removeAll,
  },
});

export default professionsSlice.reducer;

export const { create, update, remove, clear } = professionsSlice.actions;
