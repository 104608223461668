import * as React from 'react';
import { useDispatch } from 'react-redux';
import Api from 'sb_manufacturing_front_api';
import classNames from 'classnames';
import { generatePassword } from '~/utils';
import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { closePopup } from '~/redux/modules/popupModule';
import Popup from '~/components/popup/Popup';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import errorHandler from '~/utils/errorHandler';

interface IGeneratePasswordPopup {
  userId: number;
}

const GeneratePasswordPopup: React.FC<IGeneratePasswordPopup> = ({ userId }) => {
  const dispatch = useDispatch();
  const [screen, setScreen] = React.useState(1);
  const [generatedPassword, setGeneratedPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const companyOptions = useSelector(selectCompanyOptions);

  const handleClosePopup = () => {
    dispatch(closePopup());
  };

  const handleGenerate = async () => {
    setLoading(true);

    const password = generatePassword(companyOptions.use_strong_security);

    const result = await Api.resetPassword({
      user_id: userId,
      password,
    });

    setLoading(false);

    if (result.kind === 'ok') {
      setGeneratedPassword(password);
      setScreen(2);
    } else errorHandler(result);
  };

  const handleCopy = () =>
    navigator.clipboard?.writeText(generatedPassword).then(() => alert('Пароль скопирован'));

  return (
    <Popup className="generatePasswordPopup">
      <>
        <div className="passwordPopupHeader">Сгенерировать новый пароль</div>
        <div className="passwordPopupDesc">
          {screen === 1 && 'Вы уверены, что хотите сгенерировать новый пароль?'}
          {screen === 2 &&
            'После закрытия диалогового окна, вы больше не сможете увидеть сгенерированный пароль'}
        </div>
        {screen === 2 && (
          <Button
            type={BUTTON_TYPE.ICON}
            text={generatedPassword}
            onClick={handleCopy}
            icon
            reverse
            className="generatedPassword"
            iconType={ICON_TYPE.COPY}
          />
        )}
        <div className="buttonsLine">
          {screen === 1 && (
            <>
              <div
                className={classNames('text_blue', 'text_fs20', 'text_pointer', {
                  disabled: loading,
                })}
                onClick={handleClosePopup}
              >
                Отмена
              </div>
              <div
                className={classNames('text_blue', 'text_fs20', 'text_pointer', {
                  disabled: loading,
                })}
                onClick={handleGenerate}
              >
                {loading ? 'Генерирую..' : 'Сгенерировать'}
              </div>
            </>
          )}
          {screen === 2 && (
            <div className="text_blue text_fs20 text_pointer" onClick={handleClosePopup}>
              Я скопировал
            </div>
          )}
        </div>
      </>
    </Popup>
  );
};

export default GeneratePasswordPopup;
