import { NAMES_FIELD } from '~/helpers/constants';
import { Dispatch } from 'react';
import { SetStateAction } from 'react';
import { checkPasswordRegExp } from '~/utils';

interface IValidationItem {
  name: string;
  value: string | number | boolean;
  state: IStateForm;
  use_strong_security?: boolean;
  setRegExps?: Dispatch<SetStateAction<{ regExp: string; match: boolean; text: string }[]>>;
  regExps?: { regExp: string; match: boolean; text: string }[];
}

export const ValidationItem = ({
  name,
  value,
  state,
  use_strong_security,
  regExps,
  setRegExps,
}: IValidationItem) => {
  switch (name) {
    case NAMES_FIELD.LOGIN:
    case NAMES_FIELD.PASSWORD:
    case NAMES_FIELD.FIRST_NAME:
    case NAMES_FIELD.LAST_NAME:
    case NAMES_FIELD.PROFESSION_ID:
    case NAMES_FIELD.CATEGORY:
    case NAMES_FIELD.DEPARTMENT_ID:
    case NAMES_FIELD.ROLE:
    case NAMES_FIELD.SCHEDULE_TEMPLATE_ID:
    case NAMES_FIELD.SCHEDULE_TEMPLATE_START_DATE:
      if (!value) {
        return { [name]: 'Поле обязательно для заполнения!' };
      }
      break;
    case NAMES_FIELD.REPEAT_NEW_PASSWORD:
      if (value !== state[NAMES_FIELD.NEW_PASSWORD]) {
        return {
          [NAMES_FIELD.REPEAT_NEW_PASSWORD]:
            'Пароли не совпадают. Попробуйте еще раз или обратитесь к администратору',
        };
      }
      break;
    case NAMES_FIELD.NEW_PASSWORD:
      if (use_strong_security) {
        regExps &&
          regExps.forEach((obj, index) => {
            obj.match = !!value.toString().match(`^${obj.regExp}`);
            regExps[index] = obj;
            setRegExps && setRegExps(regExps);
          });
      }

      if (value === state[NAMES_FIELD.OLD_PASSWORD]) {
        return {
          [NAMES_FIELD.NEW_PASSWORD]:
            'Новый пароль совпадает с предыдущим. Попробуйте еще раз или обратитесь к администратору ',
        };
      } else {
        if (value !== state[NAMES_FIELD.REPEAT_NEW_PASSWORD]) {
          return {
            [NAMES_FIELD.REPEAT_NEW_PASSWORD]:
              'Пароли не совпадают. Попробуйте еще раз или обратитесь к администратору',
          };
        }
      }
      break;
    case NAMES_FIELD.PASSWORD_WORKER:
      if (use_strong_security) {
        if (!checkPasswordRegExp(String(value))) {
          return {
            [name]:
              'Длина пароля должна быть не менее 8 символов. Пароль должен содержать цифры. Пароль должен содержать заглавные и строчные буквы. Пароль должен содержать хотя бы один спецсимвол (@, #, $, &, *, % и т.п.)',
          };
        }
      } else {
        if (!value) {
          return { [name]: 'Поле обязательно для заполнения!' };
        }
      }
      break;
  }
};

interface IValidationSubmit {
  state: IStateForm;
  setErrors: Dispatch<SetStateAction<{ [name: string]: string }>>;
  use_strong_security?: boolean;
  setRegExps?: Dispatch<SetStateAction<{ regExp: string; match: boolean; text: string }[]>>;
  regExps?: { regExp: string; match: boolean; text: string }[];
}

export const ValidationSubmit = ({
  state,
  setErrors,
  use_strong_security,
  setRegExps,
  regExps,
}: IValidationSubmit) => {
  Object.keys(state).forEach(item => {
    if (state.hasOwnProperty(item)) {
      setErrors(prevState => {
        return {
          ...prevState,
          ...ValidationItem({
            name: item,
            value: state[item],
            state,
            use_strong_security,
            regExps,
            setRegExps,
          }),
        };
      });
    }
  });
};
