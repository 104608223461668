import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export interface IPeriods {
  id: number;
  name: string;
  value: number[];
  check: boolean;
}

export const timesheetsPeriodsAdapter = createEntityAdapter<IPeriods>();

const yesterday = [moment().add(-1, 'days').unix(), moment().unix()];
export const today = [moment().startOf('day').unix(), moment().startOf('day').unix()];
// const week = [moment().startOf('week').unix(), moment().endOf('week').unix()];
// export const month = [moment().startOf('month').unix(), moment().endOf('month').unix()];

const timesheetsPeriodsSlice = createSlice({
  name: 'timessheetsPeriods',
  initialState: timesheetsPeriodsAdapter.getInitialState({
    entities: [
      { id: 0, name: 'Вчера', value: yesterday, check: false },
      { id: 1, name: 'Сегодня', value: today, check: true },
      // { id: 2, name: 'Неделя', value: week, check: false },
      // { id: 3, name: 'Месяц', value: month, check: true },
    ],
  }),
  reducers: {
    updateTimeSheetsPeriods: timesheetsPeriodsAdapter.upsertMany,
    updateTimeSheetsPeriod: timesheetsPeriodsAdapter.upsertOne,
  },
});

export default timesheetsPeriodsSlice.reducer;

export const { updateTimeSheetsPeriods, updateTimeSheetsPeriod } = timesheetsPeriodsSlice.actions;
