import * as React from 'react';
import cx from 'classnames';
import { EShiftType } from 'sb_manufacturing_front_api';
import { useOnClickOutside } from '~/hooks';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { selectUser } from '~/redux/selectors/usersSelectors';
import { useSelector } from 'react-redux';
import { WorkShiftInfoActions } from '~/components/schedule/popup/WorkShiftInfoActions';
import { WorkShiftInfoTime } from '~/components/schedule/popup/WorkShiftInfoTime';
import classNames from 'classnames';
import { INewShiftModify } from '~/redux/modules/shiftsModule';
import WorkShiftDinner from '~/components/schedule/popup/WorkShiftDinner';
import {
  workingShiftInfo,
  SHIFT_STATUS,
  SHIFT_STATUS_DESCRIPTION,
  BUTTON_TYPE,
  ICON_TYPE,
} from '~/helpers/constants';
import { getWorkShiftTextStatus } from '~/utils';
import { selectUserRoleCanEdit } from '~/redux/selectors/authSelectors';
import Button from '~/components/form/buttons/Button';

interface IWorkShiftInfoPopupProps {
  isShown: boolean;
  onClose: () => void;
  shift: INewShiftModify | undefined;
  userId: number;
  workShiftDate: string;
  rowIndex: number;
  rowLength: number;
  isSoon: boolean;
}

export const ShiftPopup = ({
  isShown,
  onClose,
  shift,
  userId,
  workShiftDate,
  rowIndex,
  rowLength,
  isSoon,
}: IWorkShiftInfoPopupProps) => {
  const worker = useSelector(selectUser(userId));
  const userRoleCanEdit = useSelector(selectUserRoleCanEdit);

  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);

  const [isStartedCreatingShift, setIsStartedCreatingShift] = React.useState<boolean>(false);
  const [positionLeft, setPositionLeft] = React.useState(false);

  const fio = React.useMemo(() => {
    return `${worker?.lastname.firstToUpper()} ${worker?.firstname.firstToUpper()} ${worker?.middlename.firstToUpper()}`;
  }, [worker]);

  const [workShiftId, setWorkShiftId] = React.useState<number | undefined>(shift?.working_shift_id);

  const workShiftInfoClassName = cx(
    workingShiftInfo,
    shift && `${workingShiftInfo}_${SHIFT_STATUS[shift.type]}`,
    shift && isSoon && `${workingShiftInfo}_warning`,
    {
      [`${workingShiftInfo}_shown`]: isShown,
    },
  );

  React.useEffect(() => {
    setWorkShiftId(shift?.working_shift_id);
    setIsStartedCreatingShift(false);
  }, [shift]);

  React.useEffect(() => {
    if (ref?.current) {
      const rect = ref.current.getBoundingClientRect();
      rect.left + rect.width + 50 > window.innerWidth && setPositionLeft(true);
      setTimeout(() => {
        ref?.current && (ref.current.style.opacity = '1');
      }, 100);
    }
  }, [ref]);

  return (
    <div
      className={classNames(workShiftInfoClassName, {
        [`${workingShiftInfo}_left`]: positionLeft,
        [`${workingShiftInfo}_top`]: rowIndex < 4,
        [`${workingShiftInfo}_bottom`]: rowIndex > 4 && rowIndex > rowLength - 4,
      })}
      ref={ref}
    >
      <button className={`${workingShiftInfo}__action-close`} onClick={onClose}>
        <CloseSvg />
      </button>
      <p className={`${workingShiftInfo}__status`}>
        {getWorkShiftTextStatus(shift?.start_time ?? workShiftDate, shift?.type)}
      </p>

      <h3 className={`${workingShiftInfo}__title`}>{fio}</h3>

      {(shift || isStartedCreatingShift) && (
        <WorkShiftInfoTime
          workShiftId={workShiftId}
          canChangeShiftId={
            shift
              ? (shift?.type === EShiftType.ACCEPTED || shift?.type === EShiftType.DEFAULT) &&
                userRoleCanEdit
              : true
          }
          onChangeShiftId={setWorkShiftId}
        />
      )}

      {(shift || isStartedCreatingShift) && workShiftId && (
        <WorkShiftDinner workShiftId={workShiftId} />
      )}

      {shift && SHIFT_STATUS_DESCRIPTION[shift.type] && (
        <div className={`${workingShiftInfo}__status-description`}>
          {SHIFT_STATUS_DESCRIPTION[shift.type]}
        </div>
      )}

      {userRoleCanEdit && (
        <Button
          type={BUTTON_TYPE.ICON}
          text="Создать смену"
          onClick={() => setIsStartedCreatingShift(true)}
          icon
          displayNone={!!shift || isStartedCreatingShift}
          iconType={ICON_TYPE.CREATE}
        />
      )}

      {userRoleCanEdit && (
        <WorkShiftInfoActions
          workerId={worker?.id}
          shift={shift}
          workShiftDate={workShiftDate}
          isStartedCreatingShift={isStartedCreatingShift}
          workShiftId={workShiftId}
          handleClosePopup={onClose}
        />
      )}
    </div>
  );
};
