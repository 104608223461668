import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';
import { shiftsAdapter } from '~/redux/modules/shiftsModule';
import { selectDay, selectWorkShiftId, selectWorkShiftIds } from './stateSelectors';
import { selectWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { FORMAT_MOMENT } from '~/helpers/constants';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { getLocalMomentTime } from '~/helpers/convertToUnix';

export const shiftsSelector = shiftsAdapter.getSelectors<IStore>(state => state.shifts);

export const selectShifts = createSelector(
  (state: IStore) => shiftsSelector.selectAll(state),
  shifts => shifts,
);

export const selectUserShifts = (userId: number) =>
  createSelector(
    (state: IStore) => shiftsSelector.selectAll(state),
    shifts => shifts.filter(shift => shift.user_id === userId),
  );

export const selectCurrentShifts = createSelector(
  [selectShifts, selectDay, selectWorkShiftIds, selectCompanyOptions],
  (shifts, day, workShiftIds, options) => {
    return shifts.filter(shift => {
      const shiftDate = getLocalMomentTime(shift.start_time, options.time_zone).format(
        FORMAT_MOMENT.DASH_YYYYMMDD,
      );

      return shiftDate === day && workShiftIds.includes(shift.working_shift_id);
    });
  },
);

export const selectDayTracking = createSelector(
  [selectShifts, selectDay, selectWorkShiftId, selectCompanyOptions],
  (shifts, day, workShiftId, options) => {
    return shifts
      .filter(shift => {
        const shiftDate = getLocalMomentTime(shift.start_time, options.time_zone).format(
          FORMAT_MOMENT.DASH_YYYYMMDD,
        );

        return shiftDate === day && shift.working_shift_id === workShiftId;
      })
      .map(shift => {
        return {
          user_id: shift?.user_id,
          begin_time: shift.check_in_time,
          end_time: shift.check_out_time,
        };
      });
  },
);

export const selectDayDinners = createSelector(
  [selectShifts, selectDay, selectWorkShiftIds, selectWorkingShifts, selectCompanyOptions],
  (shifts, day, workShiftIds, workingShift, options) => {
    return shifts
      .filter(shift => {
        const shiftDate = getLocalMomentTime(shift.start_time, options.time_zone).format(
          FORMAT_MOMENT.DASH_YYYYMMDD,
        );

        return shiftDate === day && workShiftIds.includes(shift.working_shift_id);
      })
      .map(shift => {
        const workShift = workingShift.find(item => item.id === shift.working_shift_id);
        return {
          userId: shift?.user_id,
          dinnerStartTime: workShift?.dinner_begin_time,
          dinnerEndTime: workShift?.dinner_end_time,
          hasDinner: workShift?.has_dinner,
        };
      });
  },
);
