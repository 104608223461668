import { IStore } from '~/redux/IStore';
import { notificationsAdapter } from '~/redux/modules/notificationsModule';
import { createSelector } from 'reselect';

export const notificationsSelectors = notificationsAdapter.getSelectors<IStore>(
  state => state.notifications,
);

export const selectNotifications = createSelector(
  (state: IStore) => notificationsSelectors.selectAll(state),
  notifications => notifications,
);
