import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOnClickOutside } from '~/hooks';
import { Autocomplete, Popover } from '~/components';
import { INewTaskTemplate, ITaskTimelessTemplate } from 'sb_manufacturing_front_api';
import { selectTemplates } from '~/redux/selectors/tasksTemplatesSelectors';
import { useAppSelector } from '~/hooks/redux';
import { selectTimelessTemplates } from '~/redux/selectors/tasksTimelessTemplatesSelectors';
import { ALERT, ALERT_TYPE } from '~/helpers/constants';
import { showAlert } from '~/redux/modules/stateModule';

interface ITitleAutocomplete
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  blurOnSelect?: boolean;
  onClickSelect?: (
    item: (INewTaskTemplate & { duration?: number }) | ITaskTimelessTemplate,
  ) => void;
}

interface ITemplateItem {
  item: any;
  onSelect: (item: any) => void;
}

const TemplateItem = ({ item, onSelect }: ITemplateItem) => {
  const dispatch = useDispatch();
  const isDuration = item.duration !== undefined;

  const handleSelect = React.useCallback(() => {
    if (isDuration) {
      onSelect({
        ...item,
        geo: item?.geo ? item.geo.map((geo: any) => (geo?.id ? geo.id : geo)) : [],
        is_comment: Number(item?.need_comment),
      });
    } else {
      onSelect({
        ...item,
        geo: item?.geo ? item.geo.map((geo: any) => (geo?.id ? geo.id : geo)) : [],
        is_comment: item.is_comment,
      });
    }
  }, [item, onSelect]);

  const handleRemove = React.useCallback(() => {
    const data = {
      title: 'Удаление шаблона',
      description: `Вы уверены, что хотите удалить шаблон "${item.title}"?`,
      name: ALERT.REMOVE_TEMPLATE,
      type: ALERT_TYPE.REMOVE,
      data: {
        taskId: item.id,
        isDuration: isDuration,
      },
    };

    dispatch(showAlert(data));
  }, [item.id, item.title]);

  return (
    <div className="template_hint">
      <p>{item.title}</p>
      <div className="actions">
        <button className="color__danger" onClick={handleRemove}>
          Удалить
        </button>
        <button className="color__new" onClick={handleSelect}>
          Использовать
        </button>
      </div>
    </div>
  );
};

export const TitleAutocomplete = ({ blurOnSelect, onClickSelect, ...rest }: ITitleAutocomplete) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const templates = useSelector(selectTemplates);
  const timelessTemplates = useAppSelector(selectTimelessTemplates);
  const [results, setResults] = React.useState<INewTaskTemplate[]>([]);
  const [focused, setFocused] = React.useState(false);

  const templatesAll: (INewTaskTemplate | ITaskTimelessTemplate)[] = [
    ...timelessTemplates,
    ...templates,
  ];

  const findFn = React.useCallback(
    (str = '') =>
      new Promise<{ kind: 'ok'; data: (INewTaskTemplate | ITaskTimelessTemplate)[] }>(resolve => {
        const q = str.toLowerCase();
        const data = templatesAll
          .filter(
            template =>
              template.id === parseInt(q) ||
              (template.title || '').toLowerCase().includes(q) ||
              (template.description || '').toLowerCase().includes(q),
          )
          .slice(0, 5);
        resolve({ kind: 'ok', data });
      }),
    [templates],
  );

  const handleFocus = React.useCallback(
    e => {
      setFocused(true);
      rest.onFocus && rest.onFocus(e);
    },
    [rest.onFocus],
  );
  const handleBlur = React.useCallback(() => {
    setFocused(false);
  }, [rest.onBlur]);

  useOnClickOutside(ref, handleBlur);

  const handleSelect = React.useCallback(
    (value: INewTaskTemplate | ITaskTimelessTemplate) => {
      onClickSelect && onClickSelect(value);
      blurOnSelect && inputRef.current?.blur();
      blurOnSelect && handleBlur();
    },
    [rest.onChange, blurOnSelect, onClickSelect],
  );

  const handleResultsChange = React.useCallback((_, items) => {
    setResults(items);
  }, []);

  const show = React.useMemo(() => {
    return focused && results.length > 0;
  }, [focused, results]);

  return (
    <div ref={ref} style={{ position: 'relative', width: '100%', marginRight: 20 }}>
      <Autocomplete
        {...rest}
        ref={inputRef}
        onResultsChange={handleResultsChange}
        findFn={findFn}
        onFocus={handleFocus}
      />
      <Popover show={show} anchorEl={ref.current} verticalAnchorPosition="bottom" fullWidth>
        {results.length > 0 &&
          results.map(item => <TemplateItem key={item.id} item={item} onSelect={handleSelect} />)}
      </Popover>
    </div>
  );
};
