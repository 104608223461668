import moment from 'moment';
import { INewShift } from 'sb_manufacturing_front_api';
import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import useShiftTime from '~/hooks/useShiftTime';

interface ITaskTrackingOnTableProps {
  shift: INewShift;
  shifts: INewShift[];
  index: number;
}

const HOUR_WIDTH = 299.85;
const PX_PER_SECOND = 96 / 60 / 60;

const TaskShift = ({ shift, shifts, index }: ITaskTrackingOnTableProps) => {
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const userId = useSelector(selectCurrentUserId);
  const { beginTime } = useShiftTime();
  const isMeShift = shifts.map(item => item.user_id).includes(userId);

  const timeStart = moment(shift.start_time);
  const timeStartReal = moment(shift.start_time).add(timeZone, 'hours');
  const timeEnd = moment(shift.end_time);

  const xPos = index * HOUR_WIDTH;

  const yPos = (timeStartReal.unix() - moment(beginTime).unix()) * PX_PER_SECOND;

  const height = (timeEnd.unix() - timeStart.unix()) * PX_PER_SECOND;

  return (
    <div
      style={{
        position: 'absolute',
        left: !isMeShift ? xPos : (userId === shift.user_id ? xPos : xPos + 48) || 0,
        top: yPos,
        width: HOUR_WIDTH,
        height: height,
        background: 'white',
        zIndex: -1,
      }}
      data-index={index}
    ></div>
  );
};

export default TaskShift;
