import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICurrentWorkerState {
  id: number | null;
}

const initialState: ICurrentWorkerState = {
  id: null,
};

export const currentWorkerSlice = createSlice({
  name: 'currentWorkerSlice',
  initialState,
  reducers: {
    setCurrentWorker(state, action: PayloadAction<ICurrentWorkerState>) {
      state.id = action.payload.id;
    },
  },
});

export default currentWorkerSlice.reducer;
export const { setCurrentWorker } = currentWorkerSlice.actions;
