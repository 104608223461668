import * as React from 'react';

export const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  cb: () => void,
  isNoClose?: boolean,
) => {
  if (!cb) {
    cb = () => {};
  }

  const classNoSkip = ['users-add__back'];

  const handleBodyClick = React.useCallback(
    (e: Event) => {
      // @ts-ignore
      if (!classNoSkip.includes(e.target.classList[0]) && e.target.classList[0]) {
        // @ts-ignore
        if (ref && ref.current && !ref.current.contains(e.target)) {
          cb();
        }
      }
    },
    [ref, cb],
  );
  const handleWindowKeydown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        cb();
      }
    },
    [cb],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleWindowKeydown);
    return () => {
      window.removeEventListener('keydown', handleWindowKeydown);
    };
  }, [cb, handleWindowKeydown]);

  React.useEffect(() => {
    if (!isNoClose) {
      document.body.addEventListener('click', handleBodyClick);
      return () => {
        document.body.removeEventListener('click', handleBodyClick);
      };
    }
  }, [ref, cb]);
};
