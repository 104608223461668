import Legends from '~/components/statistic/Legends';
import { COLORS } from '~/helpers/constants';

interface IChartLine {
  hoursMax: number;
  hoursPlan: number;
  textPlan: string;
}

const ChartLine = ({ hoursMax, hoursPlan, textPlan }: IChartLine) => {
  const percent = (hoursPlan / hoursMax) * 100 || 0;

  const style = {
    width: `${percent > 100 ? 100 : percent}%`,
  };

  const legends = [
    {
      title: 'Незапланированное время',
      titleColor: COLORS.GREY,
      circleColor: COLORS.GREY_LIGHT,
    },
    {
      title: 'Запланированное время',
      titleColor: COLORS.GREY,
      circleColor: COLORS.BLUE,
    },
  ];

  return (
    <div className="chartLine">
      <Legends items={legends} className="chartLine__legend" />
      <div className="chartLine__lineAll">
        <div className="chartLine__lineFilling" style={style}>
          {percent > 10 && (
            <>
              <div className="chartLine__lineFillingTotal">{textPlan}</div>
              <div className="chartLine__lineFillingPercent">{percent.toFixed(0)}%</div>
            </>
          )}
        </div>
        <div className="chartLine__lineTotal">{hoursMax} ч.</div>
      </div>
    </div>
  );
};

export default ChartLine;
