import { FC, useMemo } from 'react';
import moment from 'moment';
import { CalendarWeek } from './week';

interface ICalendarTableProps {
  month: number;
  selected: number;
  onClickDay: (day: number) => void;
}

const defaultWeekdays: string[] = Array.from({ length: 7 }, (_, d) =>
  moment(d, 'e')
    .startOf('week')
    .isoWeekday(d + 1)
    .format('ddd'),
);

export const CalendarTable: FC<ICalendarTableProps> = ({ month, selected, onClickDay }) => {
  const data = useMemo((): moment.Moment[] => {
    const minDate: moment.Moment = moment.unix(month).startOf('month');
    const maxDate: moment.Moment = moment.unix(month).endOf('month');
    const result: moment.Moment[] = [];
    const from: moment.Moment = minDate.startOf('week');
    const to: moment.Moment = maxDate.startOf('week');

    let week: moment.Moment = from;
    while (week.isBefore(to)) {
      result.push(week);
      week = week.clone().add(1, 'week');
    }

    result.push(week);
    return result;
  }, [month]);

  return (
    <div className="calendar__table">
      {defaultWeekdays.map(weekday => (
        <div key={weekday} className="calendar__day-name">
          {weekday}
        </div>
      ))}
      {data.map(week => (
        <CalendarWeek
          key={week.unix()}
          month={month}
          current={week.unix()}
          onPressDay={onClickDay}
          selected={selected}
        />
      ))}
    </div>
  );
};
