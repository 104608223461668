import { INewUser } from 'sb_manufacturing_front_api';
import { User } from './user';

interface IUserGroup {
  letter: string;
  users: INewUser[];
  onUserAdd: (id: number) => void;
}

export const UserGroup = ({ letter, users, onUserAdd }: IUserGroup) => {
  return (
    <>
      <h3 className="alphabet__title">{letter.toUpperCase()}</h3>
      {users.map(user => (
        <User key={user.id} onClick={onUserAdd} {...user} />
      ))}
    </>
  );
};
