import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { scheduleTemplatesAdapter } from '~/redux/modules/scheduleTemplatesModule';

export const scheduleTemplatesSelector = scheduleTemplatesAdapter.getSelectors<IStore>(
  state => state.scheduleTemplates,
);

export const selectScheduleTemplates = createSelector(
  (state: IStore) => scheduleTemplatesSelector.selectAll(state),
  scheduleTemplates => scheduleTemplates,
);

export const selectScheduleTemplate = (scheduleTemplateId: number) =>
  createSelector(
    (state: IStore) => scheduleTemplatesSelector.selectAll(state),
    scheduleTemplates =>
      scheduleTemplates.find(scheduleTemplate => scheduleTemplate.id === scheduleTemplateId),
  );
