import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { selectUserRoleCanEdit } from '~/redux/selectors/authSelectors';
import { UsersFilters } from './Filters';
import { useAppDispatch, useAppSelector } from '~/hooks/redux';
import { fetchUsers } from '~/redux/reducers/GetUsersThunk';
import { useUserFilter } from '~/hooks/useUserFilters';
import { selectUsersInView, selectUsersTotalCount } from '~/redux/selectors/usersSelectors';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { UsersTable } from '~/containers/workers/list-table/table';
import Pagination from '~/components/pagination/Pagination';
import SearchInput from '~/components/SearchInput';
import Button from '~/components/form/buttons/Button';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import { titlesMap } from '~/utils/titles';

const WorkersScreen = () => {
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [deleted_only, setDeleted_only] = useState(false);

  const users = useAppSelector(selectUsersInView);
  const totalCount = useAppSelector(selectUsersTotalCount);
  const userRoleCanEdit = useAppSelector(selectUserRoleCanEdit);
  const { field, direction } = useAppSelector(state => state.usersFiltersSort);
  const dispatch = useAppDispatch();
  const { categories_ids, departments_ids, professions_ids } = useUserFilter();

  const handleClickUserCreate = useCallback(async () => {
    dispatch(openPopup({ name: POPUPS_NAME.WORKER_POPUP, data: { userId: null } }));
  }, []);

  useEffect(() => {
    const debounceOnChange = debounce(
      fetchUsers({
        deleted_only,
        fio: search,
        limit: Number(limit),
        order_by: { field, direction },
        page: Number(page) || 1,
        professions_ids,
        departments_ids,
        categories_ids,
        shift_template_id: [],
      }),
      600,
    );
    dispatch(debounceOnChange);
  }, [
    dispatch,
    search,
    limit,
    page,
    field,
    direction,
    deleted_only,
    JSON.stringify(professions_ids),
    JSON.stringify(departments_ids),
    JSON.stringify(categories_ids),
  ]);

  console.log(users);

  return (
    <div className="layout-screen">
      <h1 className="layout-screen__title">{titlesMap.workers}</h1>

      <div className="layout-screen__content">
        <div className="tabs layout-screen__tabs">
          <div
            className={`tabs__tab ${!deleted_only ? 'active' : ''}`}
            onClick={() => setDeleted_only(false)}
          >
            Работающие
          </div>
          {userRoleCanEdit && (
            <div
              className={`tabs__tab ${deleted_only ? 'active' : ''}`}
              onClick={() => setDeleted_only(true)}
            >
              Уволенные
            </div>
          )}
          {userRoleCanEdit && (
            <Button
              type={BUTTON_TYPE.ICON}
              text="Добавить сотрудника"
              onClick={handleClickUserCreate}
              icon
              iconType={ICON_TYPE.CREATE}
            />
          )}
        </div>
        <SearchInput onChange={setSearch} title="Введите имя" />
        <UsersFilters />
        <Pagination
          setLimit={setLimit}
          limit={limit}
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          className="layout-screen__paginationTop"
        />
        <UsersTable users={users} className="layout-screen__table" />
      </div>
    </div>
  );
};

export default WorkersScreen;
