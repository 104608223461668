import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api, { HelmetReportData, IHelmetReportFilter } from 'sb_manufacturing_front_api';
import { HelmetReport } from 'sb_manufacturing_front_api';
import errorHandler from '~/utils/errorHandler';

export const fetchHelmetReport = createAsyncThunk(
  'helmetReport/fetchAll',
  async (input: IHelmetReportFilter) => {
    const response: HelmetReport = await Api.getHelmetReport(input);
    if (response.kind === 'ok') {
      return response.data;
    } else errorHandler(response);
  },
);

const initialState: HelmetReportData = {
  data: [],
  totalCount: 0,
};

export const helmetReportSlice = createSlice({
  name: 'helmetReport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchHelmetReport.fulfilled, (state, action) => {
      const payload = action.payload as HelmetReportData;
      if (!payload) return;
      state.data = payload.data;
      state.totalCount = payload.totalCount;
    });
  },
});

export default helmetReportSlice.reducer;
