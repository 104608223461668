import * as React from 'react';
import classNames from 'classnames';

interface ITextarea {
  name?: string;
  label?: string;
  error?: string;
  errorBorder?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onInput?: (value: string, name: string) => void;
  defaultValue?: string;
}

const TextareasField = ({
  label,
  errorBorder,
  error,
  placeholder,
  disabled,
  name,
  onInput,
  defaultValue,
}: ITextarea) => {
  return (
    <div className="new-textarea">
      {!!label && <label className="new-textarea__label">{label}</label>}
      <div
        className={classNames('new-textarea__container', {
          ['errorBorder']: errorBorder,
        })}
      >
        <textarea
          className="new-textarea__textarea"
          name={name}
          value={defaultValue}
          onInput={event => onInput && onInput(event.currentTarget.value, event.currentTarget.name)}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
      {error && <div className="new-textarea__error">{error}</div>}
    </div>
  );
};

export default TextareasField;
