import { IStore } from '~/redux/IStore';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { canEdit } from '~/helpers/canEdit';

const selectSelf = (state: IStore) => state;

export const selectCurrentUserId = createDraftSafeSelector(selectSelf, state => state.auth.user.id);

export const selectUserRole = createDraftSafeSelector(selectSelf, state => state.auth.user.role);

export const selectUserRoleCanEdit = createDraftSafeSelector(selectSelf, state =>
  canEdit.includes(state.auth.user.role),
);

export const selectIsUserLogged = createDraftSafeSelector(
  selectSelf,
  state => state.auth.user.token !== '',
);

export const selectToken = createDraftSafeSelector(selectSelf, state => state.auth.user.token);

export const selectErrors = createDraftSafeSelector(selectSelf, state => state.auth?.error);

export const selectIsLoading = createDraftSafeSelector(
  selectSelf,
  state => state.auth.loading === 'pending',
);
