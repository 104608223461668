import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { tasksTypesAdapter } from '~/redux/modules/tasksTypesModule';

export const tasksTypesSelectors = tasksTypesAdapter.getSelectors<IStore>(
  state => state.tasksTypes,
);

export const selectTasksTypes = createSelector(
  (state: IStore) => tasksTypesSelectors.selectAll(state),
  tasksTypes => tasksTypes,
);

export const selectTaskType = (taskTypeId: number) =>
  createSelector(
    (state: IStore) => tasksTypesSelectors.selectAll(state),
    tasksTypes => tasksTypes.find(taskType => taskType.id === taskTypeId),
  );

export const selectDataTaskTypes = createSelector(
  (state: IStore) => tasksTypesSelectors.selectAll(state),
  zones =>
    zones.map(zone => ({
      id: zone.id,
      name: zone.name,
      deleted: false,
      check: false,
    })),
);
