import { INewWorkingShift } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const workingShiftsAdapter = createEntityAdapter<INewWorkingShift>({
  selectId: zone => zone.id,
});

export interface IWorkingShiftsState {
  ids: EntityId[];
  entities: Dictionary<INewWorkingShift>;
}

const workingShiftsSlice = createSlice({
  name: 'workingShifts',
  initialState: workingShiftsAdapter.getInitialState(),
  reducers: {
    create: workingShiftsAdapter.setMany,
    update: workingShiftsAdapter.upsertMany,
    remove: workingShiftsAdapter.removeMany,
    clear: workingShiftsAdapter.removeAll,
  },
});

export default workingShiftsSlice.reducer;

export const { create, update, remove, clear } = workingShiftsSlice.actions;
