import { IBluetoothGadget } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const bluetoothGadgetsAdapter = createEntityAdapter<IBluetoothGadget>({
  selectId: gadget => gadget.id,
});

export interface IBluetoothGadgetsState {
  ids: EntityId[];
  entities: Dictionary<IBluetoothGadget>;
}

const bluetoothGadgetsSlice = createSlice({
  name: 'bluetoothGadgets',
  initialState: bluetoothGadgetsAdapter.getInitialState(),
  reducers: {
    create: bluetoothGadgetsAdapter.setMany,
    update: bluetoothGadgetsAdapter.upsertMany,
    remove: bluetoothGadgetsAdapter.removeMany,
    clear: bluetoothGadgetsAdapter.removeAll,
  },
});

export default bluetoothGadgetsSlice.reducer;

export const { create, update, remove, clear } = bluetoothGadgetsSlice.actions;
