import React from 'react';
import { ETaskStatus, ETaskGeoStatus } from 'sb_manufacturing_front_api';

interface ITaskGeoStatus {
  status: ETaskStatus;
  geo_status?: number;
}

export const GeoStatus: React.FC<ITaskGeoStatus> = ({ status, geo_status }) => {
  let isStatusOk = true;
  const notCheckStatuses = [
    ETaskStatus.E_REJECTED,
    ETaskStatus.E_IN_PROGRESS,
    ETaskStatus.E_PAUSE,
    ETaskStatus.E_DEFAULT,
  ];

  const checkGeoStatus =
    geo_status && [ETaskGeoStatus.FAIL, ETaskGeoStatus.SEMI_FAIL].includes(geo_status);

  if (checkGeoStatus && !notCheckStatuses.includes(status)) {
    isStatusOk = false;
  }

  return isStatusOk ? <></> : <div className={`task-info__label status__error`}>Ошибка гео</div>;
};
