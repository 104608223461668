import { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { FORMAT_MOMENT } from '~/helpers/constants';

interface IDayProps {
  value: number;
  selected: number;
  onClick?: (value: number) => void;
}

export const CalendarDay: FC<IDayProps> = ({ value, selected, onClick = () => null }) => {
  const isActive: boolean = useMemo(() => value === selected, [selected]);
  const isToday: boolean = useMemo(() => {
    return (
      value !== 0 && moment().startOf('day').unix() === moment.unix(value).startOf('day').unix()
    );
  }, [selected]);
  const dayStr: string = useMemo(() => moment.unix(value).format(FORMAT_MOMENT.D), [value]);
  const handleClick = useCallback(() => onClick(value), [onClick, value]);

  const calendarDayClassName = cx('calendar__day', {
    calendar__day_active: isActive,
    calendar__day_today: isToday,
    calendar__day_empty: value === 0,
  });

  return (
    <div className={calendarDayClassName} onClick={handleClick}>
      {value === 0 ? null : dayStr}
    </div>
  );
};
