import textDeclension from '~/helpers/textDeclension';
import { DECLINATION_TYPE } from '~/helpers/constants';

const getTimeOptions = (): {
  time: ISelectOption;
  hours: ISelectOption;
  minutes: ISelectOption;
} => {
  const timeMap: ISelectOption = {};
  const hoursMap: ISelectOption = {};
  const minutesMap: ISelectOption = {};

  let h = 0;
  let m = 0;

  for (let i = 0; i <= 95; i++) {
    const hours: string = h < 10 ? `0${h}` : h.toString();
    const minutes: string = m < 10 ? `0${m}` : m.toString();

    if (m === 45) {
      h++;
      m = 0;
    } else {
      m += 15;
    }

    const res = `${hours}:${minutes}`;

    if (i <= 23) {
      hoursMap[i] = textDeclension(i, DECLINATION_TYPE.HOURS);
    }

    if (i <= 4) {
      minutesMap[m] = textDeclension(m, DECLINATION_TYPE.MINUTES);
    }

    timeMap[res] = res;
  }

  return { time: timeMap, hours: hoursMap, minutes: minutesMap };
};

export default getTimeOptions;
