import { useState } from 'react';
import { IWorkRequest, IWorkRequestCustomField } from 'sb_manufacturing_front_api';
import Button from '~/components/form/buttons/Button';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';

import Popup from '~/components/popup/Popup';
import Status from '~/components/status/Status';
import { TaskStatus } from '~/components/task-status';
import { BUTTON_TYPE, COLORS, ICON_TYPE } from '~/helpers/constants';
import AddNewTask from './AddNewTask';
import { useDispatch } from 'react-redux';
import { openDialog } from '~/redux/modules/dialogPopupModule';
import { useAppSelector } from '~/hooks/redux';
import { workRequestCustomFields } from '~/redux/selectors/workRequestCustomFieldsSelector';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

interface IEditWorkRequestPopup {
  workRequest: IWorkRequest;
}

const EditWorkRequestPopup = ({ workRequest }: IEditWorkRequestPopup) => {
  const dispatch = useDispatch();
  const workRequestCustomFieldsData = useAppSelector(workRequestCustomFields);
  const companyOptions = useAppSelector(selectCompanyOptions);
  const {
    id,
    status,
    title,
    external_id,
    description,
    start_date,
    end_date,
    custom_fields,
    tasks,
  } = workRequest;
  const [taskOpen, setTaskOpen] = useState<boolean>(false);
  const [task, setTask] = useState<any | undefined>(undefined);

  const handleClickTaskButton = () => {
    setTaskOpen(true);
  };
  const handleClickBack = () => {
    dispatch(
      openDialog({
        data: {
          closable: true,
          dialogData: {
            title: 'Задача не сохранена',
            message: 'Вы уверены, что хотите вернуться назад без сохранения задачи?',
            confirm: {
              button: {
                text: 'Назад к заявке',
                transparent: true,
                background: COLORS.BLUE,
              },
              onConfirm: () => {
                setTaskOpen(false);
                setTask(undefined);
              },
            },
          },
        },
      }),
    );
  };
  const handleClickTask = (task: any) => {
    setTask(task);
    setTaskOpen(true);
  };
  return (
    <Popup aside className="taskPopup work-request-popup">
      <>
        {taskOpen && (
          <div className="users-add">
            <AddNewTask handleClickBack={handleClickBack} workRequestId={id} task={task} />
          </div>
        )}
        <AsidePopupHeader>
          <div className="work-request-popup__headerContainer">
            <Status title={status} />
            <h2 className="aside-popup__title aside-popup__title_notifications">{title}</h2>
          </div>
        </AsidePopupHeader>
        <AsidePopupBody>
          <div className="work-request-popup__bodyContainer">
            <div className="work-request-popup__tasksContainer">
              <span className="work-request-popup__tasksContainer__title">Задачи</span>
              {tasks.length > 0 && (
                <div className="work-request-popup__tasksContainer__tasks">
                  {tasks.map(task => (
                    <div className="work-request-popup__tasksContainer__task">
                      <div className="work-request-popup__tasksContainer__task__avatar">ФИ</div>
                      <div className="work-request-popup__tasksContainer__task__taskBody">
                        <div className="work-request-popup__tasksContainer__task__extraData">
                          <TaskStatus status={task?.status} />
                          <span className="work-request-popup__tasksContainer__task__date">
                            {`${getLocalTimeFormat(
                              task.begin_time,
                              companyOptions.time_zone,
                              'DD.MM.YYYY',
                            )}, ${task.working_shift_name}`}
                          </span>
                          {/* FIXME: Изменить по готовности бека */}
                        </div>
                        <div className="work-request-popup__tasksContainer__task__title">
                          <span>{task.title}</span>
                        </div>
                      </div>
                      <Button
                        transparent
                        type={BUTTON_TYPE.ICON}
                        icon
                        iconType={ICON_TYPE.ARROW_NEXT}
                        onClick={() => handleClickTask(task)}
                      />
                    </div>
                  ))}
                </div>
              )}

              <Button
                className="work-request-popup__tasksContainer__button"
                text="Создать задачу"
                type={BUTTON_TYPE.ICON}
                iconType={ICON_TYPE.CREATE}
                icon
                transparent
                onClick={handleClickTaskButton}
              />
            </div>

            <div className="work-request-popup__propertyItem">
              <span className="work-request-popup__propertyItem__title">Идентификатор заявки</span>
              <span className="work-request-popup__propertyItem__value">{external_id}</span>
            </div>

            <div className="work-request-popup__propertyItemsGroup">
              <div className="work-request-popup__propertyItem">
                <span className="work-request-popup__propertyItem__title">
                  Начало выполнения работ
                </span>
                <span className="work-request-popup__propertyItem__value">{start_date}</span>
              </div>
              <div className="work-request-popup__propertyItem">
                <span className="work-request-popup__propertyItem__title">
                  Окончание выполнения работ
                </span>
                <span className="work-request-popup__propertyItem__value">{end_date}</span>
              </div>
            </div>

            <div className="work-request-popup__propertyItem">
              <span className="work-request-popup__propertyItem__title">Описание</span>
              <span className="work-request-popup__propertyItem__value">{description}</span>
            </div>

            {Object.keys(custom_fields).map(key => (
              <div key={key} className="work-request-popup__propertyItem">
                <span className="work-request-popup__propertyItem__title">
                  {workRequestCustomFieldsData.find(
                    item => (item as IWorkRequestCustomField).key === key,
                  )?.name || ''}
                </span>
                <span className="work-request-popup__propertyItem__value">
                  {custom_fields[key]}
                </span>
              </div>
            ))}
          </div>
        </AsidePopupBody>
      </>
    </Popup>
  );
};

export default EditWorkRequestPopup;
