import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api, {
  IIncidentsListFilter,
  IncidentList,
  IncidentsListData,
} from 'sb_manufacturing_front_api';
import errorHandler from '~/utils/errorHandler';
export const fetchIncidentsList = createAsyncThunk(
  'shiftsReport/fetchAll',
  async (input: IIncidentsListFilter) => {
    const response: IncidentList = await Api.getIncidentsList(input);
    if (response.kind === 'ok') {
      return response.data;
    } else {
      errorHandler(response);
    }
  },
);

const initialState: { data: any[]; totalCount: number } = {
  data: [],
  totalCount: 0,
};

export const incidentsReportSlice = createSlice({
  name: 'incidentList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchIncidentsList.fulfilled, (state, action) => {
      const payload = action.payload as IncidentsListData;
      if (!payload) return;
      state.data = payload.data;
      state.totalCount = payload.totalCount;
    });
  },
});

export default incidentsReportSlice.reducer;
