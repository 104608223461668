import * as React from 'react';
import { Polygon } from '@pbe/react-yandex-maps';
import { useAppSelector } from '~/hooks/redux';
import { selectDataZones } from '~/redux/selectors/zonesSelectors';
import { MutableRefObject } from 'react';

const defaultOptions = {
  fill: false,
  strokeWidth: 2,
};

const options = {
  strokeColor: '#FFF',
  strokeStyle: 'shortdash',
  zIndex: 2,
};

const parentOptions = {
  strokeColor: '#FE8282',
  zIndex: 1,
};

interface IFC {
  setZoneName: (value: string | null) => void;
}

const MapZones = ({ setZoneName }: IFC) => {
  const zones = useAppSelector(selectDataZones);

  const JSXZones: JSX.Element[] = [];

  zones.forEach((zone, key) => {
    const zoneCoords = JSON.parse(zone.coords);
    zoneCoords.forEach((polygon: { [value: string]: number }[]) => {
      const coords = polygon.map((coord: { [value: string]: number }) => Object.values(coord));
      JSXZones.push(
        <Polygon
          geometry={[coords]}
          options={
            zone.parent_id
              ? { ...defaultOptions, ...options }
              : { ...defaultOptions, ...parentOptions }
          }
          onMouseEnter={() => setZoneName(zone.name)}
          onMouseLeave={() => setZoneName(null)}
          key={key}
        />,
      );
    });
  });

  return <>{JSXZones}</>;
};

export default MapZones;
