import * as React from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowDownSvg } from '~/assets/arrow-down.svg';
import ActiveFilter from '~/components/filters/ActiveFilters';

interface IFilterCol {
  column: IColumnFilter;
  filterClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  filters: IColumnFilter[];
}
const FilterCol = ({ column, filterClick, filters }: IFilterCol) => {
  const activeColumn = React.useMemo(() => {
    return !!column.data.find(obj => obj.check);
  }, [column]);

  return (
    <div>
      <div
        className="list-table__col list-users-table_col"
        onClick={filterClick}
        data-name={column.name}
      >
        <div className="active-filters">
          <span
            className={classNames(
              'active-filters_span',
              activeColumn && 'active-filters_span-active',
            )}
          >
            {column.title}:
          </span>
          <ActiveFilter column={column} />
        </div>

        {filters
          .filter(obj => obj.name === column.name)
          .map((_, key) => (
            <ArrowDownSvg key={key} />
          ))}
      </div>
    </div>
  );
};

export default FilterCol;
