import * as React from 'react';
import { GeneralApiProblem } from 'sb_manufacturing_front_api';
import { useDebounce } from '~/hooks';

export interface IAutocomplete
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  findFn: (term: string) => Promise<{ kind: 'ok'; data: any[] } | GeneralApiProblem>;
  onResultsChange: (term: string, items: any[]) => void;
  searchOnEmpty?: boolean;
}

export const AutocompleteWithoutRef = ({
  findFn,
  onResultsChange,
  searchOnEmpty,
  ...rest
}: IAutocomplete) => {
  const [results, setResults] = React.useState<any[]>([]);

  const debouncedSearchTerm = useDebounce(rest.value as string, 500);
  const oldSearchTerm = React.useRef('');

  React.useEffect(() => {
    oldSearchTerm.current = debouncedSearchTerm;
    if (debouncedSearchTerm || searchOnEmpty) {
      findFn(debouncedSearchTerm).then(results => {
        if (results.kind === 'ok') {
          setResults(results.data);
        }
      });
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm, findFn]);

  React.useEffect(() => {
    onResultsChange(oldSearchTerm.current, results);
  }, [results, onResultsChange]);

  return <input {...rest} />;
};

export const Autocomplete = React.forwardRef<HTMLInputElement, IAutocomplete>((props, ref) => (
  <AutocompleteWithoutRef {...props} ref={ref} />
));
