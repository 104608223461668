import React from 'react';
import { Tooltip } from 'antd';
import { INewUser } from 'sb_manufacturing_front_api';

interface PerformersProps {
  titleName?: string;
  titleShow: boolean;
  titleColor?: string;
  oneLine?: boolean;
  users?: INewUser[];
}

export const Performers: React.FC<PerformersProps> = ({
  titleShow,
  titleName,
  titleColor,
  oneLine,
  users,
}) => {
  const Text = users?.map((item, key) =>
    key > 3 ? (
      <p>
        {item?.lastname} {item?.firstname}
      </p>
    ) : null,
  );

  return (
    <div className="performers">
      {titleShow ? (
        <div className={`performers__title ${titleColor ?? 'black'}`}>{titleName}</div>
      ) : null}
      <div className={`performers__items ${oneLine ? 'line' : ''}`}>
        {users && users?.length > 1 ? (
          <>
            {users?.map((item, key) => {
              if (key <= 3) {
                return (
                  <Tooltip
                    placement="bottom"
                    title={`${item?.lastname} ${item?.firstname}`}
                    key={key}
                  >
                    <div className="performers__item">
                      {item?.lastname?.substring(0, 1)}
                      {item?.firstname?.substring(0, 1)}
                    </div>
                  </Tooltip>
                );
              }
            })}
            {users && users?.length > 4 ? (
              <Tooltip placement="bottom" title={Text}>
                <div className="performers__item">+{users?.length - 4}</div>
              </Tooltip>
            ) : null}
          </>
        ) : (
          <p className="performers__name">
            {users && users.length
              ? `${users[0]?.lastname} ${users[0]?.firstname?.substring(
                  0,
                  1,
                )}.${users[0]?.middlename?.substring(0, 1)}.`
              : 'Пользователь не найден'}
          </p>
        )}
      </div>
    </div>
  );
};
