import * as React from 'react';
import { getPeriodShifts } from '~/redux/modules/shiftsModule';
import { prepareDateServerTime } from '~/helpers/convertToUnix';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import moment from 'moment/moment';
import Table from '~/components/schedule/Table';
import { UsersFilters } from '~/containers/shedules/Filters';
import { useUserFilter } from '~/hooks/useUserFilters';
import { FORMAT_MOMENT } from '~/helpers/constants';
import SearchInput from '~/components/SearchInput';
import { titlesMap } from '~/utils/titles';

const ScheduleScreen = () => {
  const dispatch = useDispatch();
  const companyOptions = useSelector(selectCompanyOptions);
  const timeZone = companyOptions.time_zone;
  const { categories_ids, departments_ids, professions_ids } = useUserFilter();
  const [dates, setDates] = React.useState<string[]>([]);
  const [useFilter, setUseFilter] = React.useState<boolean>(false);
  const [fio, setFio] = React.useState<string>('');

  React.useEffect(() => {
    const startDate = moment().startOf('month').subtract(30, 'day');
    const array = [];

    for (let i = 0; i < moment().daysInMonth() + 60; i++) {
      array.push(moment(startDate).add(i, 'day').format(FORMAT_MOMENT.DASH_YYYYMMDD));
    }

    setDates(array);
  }, []);

  React.useEffect(() => {
    if (dates.length < 2 || companyOptions.id === 0) return;
    setUseFilter(true);

    dispatch(
      getPeriodShifts({
        data: {
          start_time: prepareDateServerTime(dates[0], timeZone),
          end_time: prepareDateServerTime(dates[dates.length - 1], timeZone),
          categories_ids,
          departments_ids,
          fio,
          professions_ids,
        },
        timeZone,
      }),
    );
  }, [
    JSON.stringify(categories_ids),
    JSON.stringify(departments_ids),
    fio,
    timeZone,
    JSON.stringify(professions_ids),
  ]);

  return (
    <div className="layout-screen">
      <h1 className="layout-screen__title">{titlesMap.schedules}</h1>

      <div className="layout-screen__content">
        <SearchInput onChange={setFio} title="Введите имя" />
        <UsersFilters />
        <Table
          dates={dates}
          useFilter={useFilter}
          setUseFilter={setUseFilter}
          setDates={value => setDates(value)}
          fio={fio}
        />
      </div>
    </div>
  );
};

export default ScheduleScreen;
