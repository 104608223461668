import * as React from 'react';
import { ReactComponent as IllustrationSvg } from '~/assets/login-illustration.svg';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '~/redux/modules/authModule';
import {
  selectCompanyOptions,
  selectIsCompanyOptionsInit,
} from '~/redux/selectors/companyOptionsSelectors';
import { selectErrors } from '~/redux/selectors/authSelectors';
import { INPUT_TYPE, COLORS, NAMES_FIELD } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import InputsField from '~/components/form/input/Input';
import onInput from '~/utils/onInput';
import { ValidationSubmit } from '~/utils/validations';

const LoginScreen = () => {
  const dispatch = useDispatch();

  const authErrors = useSelector(selectErrors);
  const companyOptions = useSelector(selectCompanyOptions);
  const isCompanyOptionsInit = useSelector(selectIsCompanyOptionsInit);

  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [prefix, setPrefix] = React.useState<string>('АЗУП-');
  const [name, setName] = React.useState<string>('');

  const [state, setState] = React.useState<IStateForm>({
    [NAMES_FIELD.LOGIN]: '',
    [NAMES_FIELD.PASSWORD]: '',
  });

  const [errors, setErrors] = React.useState<{ [name: string]: string }>({
    [NAMES_FIELD.LOGIN]: '',
    [NAMES_FIELD.PASSWORD]: '',
  });

  React.useEffect(() => {
    if (companyOptions) {
      setPrefix(companyOptions?.prefix);
      setName(companyOptions?.name);
    }
  }, [isCompanyOptionsInit]);

  const handleSubmit = async () => {
    ValidationSubmit({
      state,
      setErrors,
    });

    if (Object.keys(errors).length !== 0) return;

    setIsSubmitted(true);

    await dispatch(
      signIn({
        login: state[NAMES_FIELD.LOGIN],
        password: state[NAMES_FIELD.PASSWORD],
      }),
    );

    setIsSubmitted(false);
  };

  const handelOnInput = (value: string, name: string) => {
    onInput({
      value,
      name,
      state,
      setState,
      setErrors,
    });
  };

  if (isCompanyOptionsInit) {
    if (companyOptions === undefined) {
      return (
        <section className="login">
          <div className="no-prefix">404</div>
          <div className="login__illustration">
            <IllustrationSvg />
          </div>
        </section>
      );
    } else {
      document.title = `Smena WFM: ${name}`;

      return (
        <section className="login">
          <div className="login__form">
            <h1 className="h1 h1_tasks">Вход</h1>
            <InputsField
              name={NAMES_FIELD.LOGIN}
              label="Табельный номер"
              prefix={prefix}
              placeholder="00000"
              errorBorder={!!errors[NAMES_FIELD.LOGIN]}
              onInput={(value, name) => handelOnInput(value, name)}
            />
            <InputsField
              name={NAMES_FIELD.PASSWORD}
              label="Пароль"
              placeholder="Введите пароль"
              type={INPUT_TYPE.PASSWORD}
              errorBorder={!!errors[NAMES_FIELD.PASSWORD]}
              onInput={(value, name) => handelOnInput(value, name)}
            />
            <Button
              text="Войти"
              className="login"
              onClick={handleSubmit}
              disabled={isSubmitted}
              background={COLORS.BLUE}
            />
            {authErrors && (
              <span className="login__error">
                {authErrors?.password && authErrors?.password.length
                  ? authErrors?.password.map((item: string, key: number) => {
                      return <p key={key}>{item}</p>;
                    })
                  : authErrors}
              </span>
            )}
          </div>
          <div className="login__copyright">&copy; SMENA (ООО «БП Диджитал»)</div>
          <div className="login__illustration">
            <IllustrationSvg />
          </div>
        </section>
      );
    }
  } else {
    return <></>;
  }
};

export default LoginScreen;
