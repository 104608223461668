import * as React from 'react';
import { ETaskStatus } from 'sb_manufacturing_front_api';

interface ITaskStatus {
  status: ETaskStatus;
  isExpired?: boolean;
}

const MAPPED_VALUES: { [key in ETaskStatus]?: [string, string] } = {
  [ETaskStatus.E_COMPLETE]: ['Выполнено', 'success'],
  [ETaskStatus.E_REJECTED]: ['Отклонено', 'error'],
  [ETaskStatus.E_EXPIRED]: ['Просрочено', 'error'],
  [ETaskStatus.E_IN_PROGRESS]: ['В работе', 'warning'],
  [ETaskStatus.E_VALIDATION]: ['Ожидает проверки НУ', 'default'],
  [ETaskStatus.E_VALIDATION_SV]: ['Ожидает проверки НС', 'default'],
  [ETaskStatus.E_PAUSE]: ['Пауза', 'pause'],
  [ETaskStatus.E_EMERGENCY_PAUSED]: ['Пауза', 'pause'],
  [ETaskStatus.E_DEFAULT]: ['Назначена', 'default'],
  [ETaskStatus.E_REJECTED_SV]: ['Отклонена НС', 'error'],
  [ETaskStatus.E_REJECTED_D]: ['Отклонена НУ', 'error'],
};

export const TaskStatus = ({ status, isExpired }: ITaskStatus) => {
  const [text, preset] = React.useMemo(() => MAPPED_VALUES[status] || [], [status]);
  return (
    <div className="labels">
      {isExpired && <div className={`labels-status status__error`}>Просрочено</div>}
      {ETaskStatus.E_EXPIRED !== status && (
        <div className={`labels-status status__${preset}`}>{text}</div>
      )}
    </div>
  );
};
