import { createSelector } from 'reselect';
import { IStore } from '~/redux/IStore';
import { professionsAdapter } from '~/redux/modules/professionsModule';

export const professionsSelectors = professionsAdapter.getSelectors<IStore>(
  state => state.professions,
);

export const selectProfessions = createSelector(
  (state: IStore) => professionsSelectors.selectAll(state),
  professions => professions,
);

export const selectDataProfessions = createSelector(
  (state: IStore) => professionsSelectors.selectAll(state),
  professions =>
    professions.map(profession => ({
      id: profession.id,
      name: profession.name,
      check: false,
    })),
);

export const selectProfession = (professionId: number) =>
  createSelector(
    (state: IStore) => professionsSelectors.selectAll(state),
    professions => professions.find(profession => profession.id === professionId),
  );
