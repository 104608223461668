import Legends from '~/components/statistic/Legends';
import { INewTask } from 'sb_manufacturing_front_api';
import { COLORS } from '~/helpers/constants';
import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

interface ITimeValue {
  text: string;
  number: number;
}

interface IChartTasks {
  time: number;
  tasksPlaned: INewTask[];
  tasksWorked: INewTask[];
  tasksGeo: INewTask[];
  tasksExpired: INewTask[];
  timePlaned: ITimeValue;
  timeWorked: ITimeValue;
  timeGeo: ITimeValue;
  timeExpired: ITimeValue;
}

const ChartTasks = ({
  tasksPlaned,
  tasksWorked,
  tasksGeo,
  tasksExpired,
  time,
  timePlaned,
  timeWorked,
  timeGeo,
  timeExpired,
}: IChartTasks) => {
  const useGeo = useSelector(selectCompanyOptions).use_geo;

  const timePlanedPercent = (timePlaned.number / time) * 100 || 0;
  const timeWorkedPercent = (timeWorked.number / time) * 100 || 0;
  const timeGeoPercent = (timeGeo.number / time) * 100 || 0;
  const timeExpiredPercent = (timeExpired.number / time) * 100 || 0;

  const legendsTasks = [
    {
      title: 'Назначено',
      titleColor: COLORS.GREY,
      circleColor: COLORS.GREY100,
      total: timePlaned.text,
      percent: `${tasksPlaned.length}`,
    },
    {
      title: 'С откл. по времени  ',
      titleColor: COLORS.GREY,
      circleColor: COLORS.PINK,
      total: timeExpired.text,
      percent: `${tasksExpired.length}`,
    },
    {
      title: 'Отработано',
      titleColor: COLORS.GREY,
      circleColor: COLORS.GREEN,
      total: timeWorked.text,
      percent: `${tasksWorked.length}`,
    },
  ];

  const lineItems = [
    {
      height: timePlanedPercent > 100 ? '100%' : `${timePlanedPercent.toFixed(1)}%`,
      text: timePlanedPercent > 20 && timePlaned.text,
      color: 'grey',
    },
    {
      height: timeWorkedPercent > 100 ? '100%' : `${timeWorkedPercent.toFixed(1)}%`,
      text: timeWorkedPercent > 20 && timeWorked.text,
      color: 'green',
    },
    {
      height: timeExpiredPercent > 100 ? '100%' : `${timeExpiredPercent.toFixed(1)}%`,
      text: timeExpiredPercent > 20 && timeExpired.text,
      color: 'pink',
    },
  ];

  if (useGeo) {
    legendsTasks.push({
      title: 'С откл. по гео  ',
      titleColor: COLORS.GREY,
      circleColor: COLORS.RED,
      total: timeGeo.text,
      percent: `${tasksGeo.length}`,
    });

    lineItems.push({
      height: timeGeoPercent > 100 ? '100%' : `${timeGeoPercent.toFixed(1)}%`,
      text: timeGeoPercent > 20 && timeGeo.text,
      color: 'red',
    });
  }

  return (
    <div className="chartTasks">
      <Legends items={legendsTasks} className="chartTasks__legend" />
      <div className="chartTasks__lines">
        {lineItems.map(item => (
          <div
            className={`chartTasks__linesItem ${item.color}`}
            style={{
              height: item.height,
            }}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChartTasks;
