import * as React from 'react';
import { useAppSelector } from '~/hooks/redux';
import { Header } from './header';
import { Row } from './row';

interface IUsersTable {
  columns: {
    title: string;
    name: string;
    check: boolean;
  }[];
  tab: string;
}

export const ReportsTable = ({ columns, tab }: IUsersTable) => {
  const { data: reportsShifts } = useAppSelector(state => state.reportsShifts);
  const { data: reportsTasks } = useAppSelector(state => state.reportsTask);
  const { data: reportsHelmet } = useAppSelector(state => state.reportsHelmet);
  const { data: incidentList } = useAppSelector(state => state.incidentsList);
  const { data: reportsZoneEntrance } = useAppSelector(state => state.reportsZoneEntrance);

  let rows = [];
  switch (tab) {
    case 'tasks':
      rows = reportsTasks;
      break;
    case 'shifts':
      rows = reportsShifts;
      break;
    case 'helmets':
      rows = reportsHelmet;
      break;
    case 'incidents':
      rows = incidentList;
      break;
    case 'ppe':
      rows = reportsZoneEntrance;
      break;
  }

  return (
    <div className="list-table">
      <Header columns={columns} />
      {rows.length < 1 && <div className="no-results">Ничего не найдено</div>}
      {rows.map((row: any, key: number) => (
        <Row columns={columns} key={key} row={row} tab={tab} />
      ))}
    </div>
  );
};
