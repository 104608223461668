import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IReportsFiltersState {
  reportsFilters: IColumnFilter[];
}

export interface IResetFilter {
  name: string;
}

export interface IToggleFilter {
  name: string;
  radio: boolean;
  id: number | null;
}

const initialState: IReportsFiltersState = {
  reportsFilters: [],
};

export const reportsFiltersSlice = createSlice({
  name: 'reportsFilters',
  initialState,
  reducers: {
    setReportsFilters(state, action: PayloadAction<IColumnFilter[]>) {
      state.reportsFilters = action.payload;
    },
    setReportsFilterItem(state, action: PayloadAction<IColumnFilter>) {
      const { name, data } = action.payload;
      const filter = state.reportsFilters.find(filter => filter.name === name);
      if (!filter) return;
      filter.data = data;
    },
    resetFilter(state, action: PayloadAction<IResetFilter>) {
      const { name } = action.payload;
      const filter = state.reportsFilters.find(filter => filter.name === name);
      if (!filter) return;
      const resetChecks = filter.data.map(el => ({ ...el, check: false }));
      if (!resetChecks || !filter) return;
      filter.data = resetChecks;
    },
    allFilter(state, action: PayloadAction<IResetFilter>) {
      const { name } = action.payload;
      const filter = state.reportsFilters.find(filter => filter.name === name);
      if (!filter) return;
      const allChecks = filter.data.map(el => ({ ...el, check: true }));
      if (!allChecks || !filter) return;
      filter.data = allChecks;
    },
    toggleFilter(state, action: PayloadAction<IToggleFilter>) {
      const { id, name, radio } = action.payload;
      const filter = state.reportsFilters.find(filter => filter.name === name);
      if (!filter) return;
      if (radio) {
        filter.data.map(item => (item.check = item.id === id));
      } else {
        const currentCheck = filter.data.find(el => el.id === id);
        if (!currentCheck) return;
        currentCheck.check = !currentCheck.check;
      }
    },
    switchOffFilter(state, action: PayloadAction<IToggleFilter>) {
      const { id, name } = action.payload;
      const filter = state.reportsFilters.find(filter => filter.name === name);
      if (!filter) return;
      const currentCheck = filter.data.find(el => el.id === id);
      if (!currentCheck) return;
      currentCheck.check = false;
    },
  },
});

export default reportsFiltersSlice.reducer;
export const {
  setReportsFilters,
  setReportsFilterItem,
  toggleFilter,
  switchOffFilter,
  resetFilter,
  allFilter,
} = reportsFiltersSlice.actions;
