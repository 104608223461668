import * as React from 'react';
import { ETaskStatus, INewUser } from 'sb_manufacturing_front_api';
import { useAppSelector } from '~/hooks/redux';
import { selectUserRoleCanEdit, selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { selectProfession } from '~/redux/selectors/professionsSelectors';
import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { selectUserTasksTimeless, selectTasksShift } from '~/redux/selectors/tasksSelectors';
import { BUTTON_TYPE, ICON_TYPE, ALERT, ALERT_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import { showAlert } from '~/redux/modules/stateModule';
import { selectDepartment } from '~/redux/selectors/departmentsSelectors';

interface ICarouselUser extends Partial<INewUser> {
  total?: number;
  complete?: number;
  online?: boolean;
  shiftId?: number;
  departments_ids?: number[];
}

const TaskCompleteness = ({
  complete = 0,
  total = complete,
}: {
  total?: number;
  complete?: number;
}) => {
  const modifier = React.useMemo(() => {
    if (total === 0) {
      return 'none';
    } else if (total > 0) {
      const percent = complete / total;
      if (percent <= 0.5) {
        return 'danger';
      } else if (percent > 0.5 && percent < 1) {
        return 'warning';
      }
      return 'success';
    }
  }, [total, complete]);

  return (
    <div className={`task-carousel__counter color__${modifier}`}>
      <span className="task-carousel__counter-from">{complete}</span>/
      <span className="task-carousel__counter-to">{total}</span>
    </div>
  );
};

export const CarouselUser = ({
  id,
  firstname,
  lastname,
  profession_id,
  online,
  shiftId,
  departments_ids,
}: ICarouselUser) => {
  const dispatch = useDispatch();
  const userTasks = useAppSelector(selectTasksShift).filter(task => task.user_id === id);
  const userTasksTimeless = useAppSelector(selectUserTasksTimeless(id ?? 0));
  const userRoleCanEdit = useAppSelector(selectUserRoleCanEdit);
  const userProfession = useAppSelector(selectProfession(profession_id ?? 0));
  const userId = useAppSelector(selectCurrentUserId);
  const departmentName = useAppSelector(selectDepartment(departments_ids?.[0]))?.name;

  const completedTasks = userTasks.filter(task => task.status === ETaskStatus.E_COMPLETE);

  const handleRemove = React.useCallback(() => {
    if (shiftId && userRoleCanEdit) {
      const data = {
        title: 'Удалить сотрудника со смены',
        description: 'Вы уверены, что хотите удалить сотрудника из смены',
        name: ALERT.REMOVE_USER_SHIFTS,
        type: ALERT_TYPE.REMOVE,
        data: {
          shiftId: shiftId,
          userName: `${lastname} ${firstname}`,
        },
      };

      dispatch(showAlert(data));
    }
  }, [shiftId]);

  const initials = React.useMemo(
    () => `${(lastname || '').charAt(0)}${(firstname || '').charAt(0)}`.toUpperCase(),
    [firstname, lastname],
  );

  const total = React.useMemo(() => userTasks.length, [userTasks]);
  const complete = React.useMemo(() => completedTasks.length, [completedTasks]);

  return (
    <div
      className={`task-carousel__col task-carousel__col_person ${
        userId === id ? 'task-carousel__col_person-first' : ''
      }`}
    >
      {userRoleCanEdit && (
        <Button
          type={BUTTON_TYPE.ICON}
          onClick={handleRemove}
          className="task-carousel__remove-person"
          icon
          iconType={ICON_TYPE.REMOVE}
        />
      )}
      {userId === id ? (
        <button className={`user-avatar`}>{initials}</button>
      ) : (
        <Tooltip placement="top" title={online ? 'Работник в сети' : 'Работник не в сети'}>
          <button
            className={`user-avatar ${
              online ? 'user-avatar--after' : 'user-avatar--after user-avatar--after-offline'
            }`}
          >
            {initials}
          </button>
        </Tooltip>
      )}
      <TaskCompleteness total={total} complete={complete} />
      <h3 className="task-carousel__name">
        {userId === id ? 'Мои задачи' : `${lastname} ${firstname}`}
      </h3>
      <p className="task-carousel__specialisation">{departmentName}</p>
      <p className="task-carousel__specialisation">{userProfession?.name}</p>
      {!!userTasksTimeless.length && (
        <div className="task-carousel__no-time-count">
          + {userTasksTimeless.length} задач без времени
        </div>
      )}
    </div>
  );
};
