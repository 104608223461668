import * as React from 'react';
import { INewUser } from 'sb_manufacturing_front_api';
import { useSelector } from 'react-redux';
import { Autocomplete } from '~/components';
import { selectUsersExcludingToday } from '~/redux/selectors/usersSelectors';

interface IUserSearch {
  onSearchChange: (values: INewUser[]) => void;
  users?: INewUser[];
}

export const UserSearch = ({ users, onSearchChange }: IUserSearch) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const selectorUsersNotToday = useSelector(selectUsersExcludingToday);
  const [usersNotToday, setUsersNotToday] = React.useState<INewUser[]>([]);
  const [term, setTerm] = React.useState('');

  React.useEffect(() => {
    if (users) {
      setUsersNotToday(users);
    } else {
      setUsersNotToday((selectorUsersNotToday as unknown) as INewUser[]);
    }
  }, [users, selectorUsersNotToday]);

  const findFn = React.useCallback(
    (str = '') =>
      new Promise<{ kind: 'ok'; data: INewUser[] }>(resolve => {
        if (!str) {
          return resolve({ kind: 'ok', data: usersNotToday });
        }
        const q = str.toLowerCase();
        const data = usersNotToday.filter(
          user =>
            user.id === parseInt(q) ||
            (user.firstname || '').toLowerCase().includes(q) ||
            (user.lastname || '').toLowerCase().includes(q) ||
            (user.middlename || '').toLowerCase().includes(q),
          // (user.profession?.name || '').toLowerCase().includes(q)
        );
        resolve({ kind: 'ok', data });
      }),
    [usersNotToday],
  );

  const handleResultsChange = React.useCallback((_, items) => {
    onSearchChange(items);
  }, []);

  const handleTermChange = React.useCallback(e => {
    setTerm(e.nativeEvent.target.value);
  }, []);

  return (
    <div ref={ref}>
      <Autocomplete
        className="input input_gray add-person-popup__input-search"
        placeholder="Введите имя или должность сотрудника"
        ref={inputRef}
        onResultsChange={handleResultsChange}
        findFn={findFn}
        value={term}
        onChange={handleTermChange}
        searchOnEmpty
      />
    </div>
  );
};
