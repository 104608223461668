import { selectUserRoleCanEdit } from '~/redux/selectors/authSelectors';
import { useDispatch } from 'react-redux';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { useSelector } from 'react-redux';
import { selectWorkShiftIds } from '~/redux/selectors/stateSelectors';

export const AddUser = () => {
  const dispatch = useDispatch();
  const userRoleCanEdit = useSelector(selectUserRoleCanEdit);
  const workingShiftIds = useSelector(selectWorkShiftIds);

  if (!userRoleCanEdit) return null;

  return (
    <>
      {userRoleCanEdit && workingShiftIds.length === 1 && (
        <div className="task-carousel__col task-carousel__col_person">
          <button
            className="task-carousel__add-person"
            data-toggle_aside_popup="add-person"
            onClick={() => {
              dispatch(openPopup({ name: POPUPS_NAME.ADD_USER_SHIFT_POPUP }));
            }}
          >
            <div className="task-carousel__plus-circle">+</div>
            <div className="task-carousel__counter">
              <span className="task-carousel__counter-from">0</span>/
              <span className="task-carousel__counter-to">0</span>
            </div>
            <h3 className="task-carousel__name">Добавить сотрудника</h3>
            <p className="task-carousel__specialisation">не назначено</p>
          </button>
        </div>
      )}
    </>
  );
};
