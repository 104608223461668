import * as React from 'react';
import { ReactComponent as SearchIcon } from '~/assets/search.svg';
import { debounce } from 'lodash';

interface ISearchInput {
  onChange: (text: string) => void;
  onEnter?: (text: string) => void;
  title?: string;
}

const SearchInput: React.FC<ISearchInput> = ({ onChange, onEnter, title }) => {
  const handleInputChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const handleOnEnter = React.useCallback(
    e => {
      if (e.charCode === 13) {
        onEnter && onEnter(e.target.value);
      }
    },
    [onEnter],
  );

  const debounceOnChange = debounce(handleInputChange, 600);

  return (
    <label className="search-input">
      <span className="search-input__icon-wrapper">
        <SearchIcon />
      </span>
      <input
        type="text"
        className="search-input__input input_gray"
        placeholder={title}
        onChange={debounceOnChange}
        onKeyPress={handleOnEnter}
      />
    </label>
  );
};

export default SearchInput;
