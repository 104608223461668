import * as React from 'react';
import { ReactComponent as DotsSvg } from '~/assets/dots.svg';
import { TaskMessage } from './task-message';

interface ITaskList {
  id: number;
}

export const TaskList = ({ id }: ITaskList) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [openMessage, setOpenMessage] = React.useState<boolean>(false);
  const [type, setType] = React.useState<string>('');

  const handelClick = React.useCallback((value: boolean) => {
    setOpenMessage(value);
  }, []);

  return (
    <>
      <div className="task-list" style={{ position: 'absolute', right: 0 }}>
        <div className="task-list__btn" onClick={() => setOpen(prevState => !prevState)}>
          <DotsSvg />
        </div>
        <div className={`task-list__list ${open ? 'open' : ''}`}>
          <div
            className="task-list__list-item"
            onClick={() => {
              setOpenMessage(true);
              setType('message');
              setOpen(false);
            }}
          >
            Сообщить о проблеме
          </div>
          <div
            className="task-list__list-item"
            onClick={() => {
              setOpenMessage(true);
              setType('error');
              setOpen(false);
            }}
          >
            Не могу выполнить задачу
          </div>
        </div>
      </div>
      {openMessage && (
        <TaskMessage view={openMessage} handelClick={handelClick} id={id} type={type} />
      )}
    </>
  );
};
