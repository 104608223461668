import * as React from 'react';
import classNames from 'classnames';
import { INPUT_TYPE } from '~/helpers/constants';
import { ReactComponent as EyeClosed } from '~/assets/eye-closed.svg';
import { ReactComponent as EyeOpened } from '~/assets/eye-opened.svg';

interface IInput {
  name?: string;
  label?: string;
  error?: string;
  errorBorder?: boolean;
  isTouched?: boolean;
  prefix?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  onInput?: (value: string, name: string) => void;
  defaultValue?: string;
}

const InputsField = ({
  label,
  prefix,
  type,
  errorBorder,
  error,
  placeholder,
  disabled,
  name,
  onInput,
  defaultValue,
}: IInput) => {
  const [inputFocused, setInputFocused] = React.useState(false);
  const [inputType, setInputType] = React.useState(type ?? INPUT_TYPE.TEXT);
  const defaultTypePassword = type === INPUT_TYPE.PASSWORD;
  const currentTypePassword = inputType === INPUT_TYPE.PASSWORD;

  return (
    <div className="new-input">
      {!!label && <label className="new-input__label">{label}</label>}
      <div
        className={classNames('new-input__container', {
          ['withPrefix']: prefix,
          ['prefixFocus']: prefix && inputFocused,
          ['errorBorder']: errorBorder,
          ['inputTypePassword']: defaultTypePassword,
        })}
      >
        {prefix && <div className="new-input__prefix">{prefix}</div>}
        <input
          className="new-input__input"
          name={name}
          type={inputType}
          value={defaultValue}
          onInput={event => onInput && onInput(event.currentTarget.value, event.currentTarget.name)}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          disabled={disabled}
          placeholder={placeholder}
        />
        {defaultTypePassword && (
          <div
            className="new-input__visiblePassword"
            onClick={() =>
              setInputType(currentTypePassword ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD)
            }
          >
            {currentTypePassword ? <EyeClosed /> : <EyeOpened />}
          </div>
        )}
      </div>
      {error && <div className="new-input__error">{error}</div>}
    </div>
  );
};

export default InputsField;
