import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import '~/scss/index.scss';
import '~/polyfills';
import moment from 'moment';
import 'moment/locale/ru';
import { ToastContainer } from 'react-toastify';
import App from '~/App';
import store from '~/redux/configureStore';

const ignored = [/.*Warning: %s is deprecated in StrictMode.*/];
const oldConsoleError = console.error;
console.error = (...args: any[]) => {
  if (args[0] === String(args[0])) {
    if (ignored.some(reg => reg.test(args[0]))) {
      return;
    }
  }
  oldConsoleError(...args);
};

moment.locale('ru');

const renderApp = (App: React.ComponentType<any>) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store} key="provider">
        <PersistGate persistor={store.persistor}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.StrictMode>,
    document.getElementById('app-wrapper'),
  );
};

renderApp(App);

if ((module as any).hot) {
  (module as any).hot.accept('~/App', () => {
    const { App: NewApp } = require('~/App');
    renderApp(NewApp);
  });
}
