import { useAppSelector } from '~/hooks/redux';
import { selectUserRole } from '~/redux/selectors/authSelectors';
import { TYPE_ROLE } from '~/helpers/constants';

const useAuthRole = () => {
  const role = useAppSelector(selectUserRole);

  const isSupervisor = TYPE_ROLE.SUPERVISOR === role;
  const isDepartment = TYPE_ROLE.DEPARTMENT === role;
  const isView = TYPE_ROLE.VIEW === role;
  const isWorker = TYPE_ROLE.WORKER === role;
  const isManager = TYPE_ROLE.MANAGER === role;
  const isAdmin = TYPE_ROLE.ADMIN === role;

  return {
    isSupervisor,
    isDepartment,
    isView,
    isWorker,
    isManager,
    isAdmin,
  };
};

export default useAuthRole;
