import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';
import { departmentsAdapter } from '~/redux/modules/departmentsModule';

export const departmentsSelectors = departmentsAdapter.getSelectors<IStore>(
  state => state.departments,
);

export const selectDepartments = createSelector(
  (state: IStore) => departmentsSelectors.selectAll(state),
  departments => departments.filter(d => !d.deleted),
);

export const selectDataDepartments = createSelector(
  (state: IStore) => departmentsSelectors.selectAll(state),
  departments =>
    departments
      .map(department => ({
        id: department.id,
        name: department.name,
        deleted: department.deleted,
        check: false,
      }))
      .sort((x, y) => Number(x.deleted) - Number(y.deleted)),
);

export const selectDepartment = (departmentId?: number) =>
  createSelector(
    (state: IStore) => departmentsSelectors.selectAll(state),
    departments => departments.find(department => department.id === departmentId),
  );

export const selectDepartmentsByIds = (departmentIds: number[]) =>
  createSelector(
    (state: IStore) => departmentsSelectors.selectAll(state),
    departments => departments.filter(department => departmentIds.includes(department.id)),
  );

export const selectDepartmentsByIdsFilter = (departmentIds: number[]) =>
  createSelector(
    (state: IStore) => departmentsSelectors.selectAll(state),
    departments => {
      const items = departments.filter(department => departmentIds.includes(department.id));

      return items.map(item => ({
        id: item.id,
        name: item.name,
        check: false,
      }));
    },
  );
