import { IStore } from '~/redux/IStore';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT } from '~/helpers/constants';

const selectSelf = (state: IStore) => state;

const timeZone = createDraftSafeSelector(
  selectSelf,
  state => state.companyOptions.options.time_zone,
);

export const selectMapList = createDraftSafeSelector(selectSelf, state => {
  const innerTimeZone = timeZone(state);
  return state.map.geoPositions.map(item => {
    return {
      ...item,
      time: getLocalTimeFormat(item.time, innerTimeZone, FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    };
  });
});

export const selectMapLoading = createDraftSafeSelector(selectSelf, state => state.map.loading);

export const selectMapNotCheckinUsersIds = createDraftSafeSelector(
  selectSelf,
  state => state.map.notCheckinUsersIds,
);

export const selectMapShift = (shiftId: number) =>
  createDraftSafeSelector(selectSelf, state => {
    const innerTimeZone = timeZone(state);
    const shift = state.map.geoPositions.find(item => item.shift_id == shiftId);
    return {
      ...shift,
      geoPositions: shift?.geoPositions?.map(item => {
        return {
          ...item,
          time: getLocalTimeFormat(item.time, innerTimeZone, FORMAT_MOMENT.YYYYMMDD_HHMMSS),
        };
      }),
      logs: shift?.logs?.map(item => {
        return {
          ...item,
          time: getLocalTimeFormat(item.time, innerTimeZone, FORMAT_MOMENT.YYYYMMDD_HHMMSS),
        };
      }),
      shift: {
        ...shift,
        check_in_time: getLocalTimeFormat(
          shift?.shift?.check_in_time || '',
          innerTimeZone,
          FORMAT_MOMENT.YYYYMMDD_HHMMSS,
        ),
        check_out_time: getLocalTimeFormat(
          shift?.shift?.check_out_time || '',
          innerTimeZone,
          FORMAT_MOMENT.YYYYMMDD_HHMMSS,
        ),
        end_time: getLocalTimeFormat(
          shift?.shift?.end_time || '',
          innerTimeZone,
          FORMAT_MOMENT.YYYYMMDD_HHMMSS,
        ),
        start_time: getLocalTimeFormat(
          shift?.shift?.start_time || '',
          innerTimeZone,
          FORMAT_MOMENT.YYYYMMDD_HHMMSS,
        ),
      },
    };
  });
