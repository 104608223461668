import * as React from 'react';
import { Calendar as CalendarComponent } from '~/components';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import { nextDay } from '~/redux/modules/stateModule';
import { prevDay } from '~/redux/modules/stateModule';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '~/hooks/redux';
import { selectDay } from '~/redux/selectors/stateSelectors';

const Calendar = () => {
  const dispatch = useDispatch();
  const handleNext = () => dispatch(nextDay());
  const handlePrev = () => dispatch(prevDay());

  const selectedDay = useAppSelector(selectDay);

  return (
    <div className="calendar">
      <CalendarComponent current={selectedDay} />
      <nav className="calendar__nav">
        <Button
          type={BUTTON_TYPE.ICON}
          onClick={handlePrev}
          className="calendar-prev"
          icon
          iconType={ICON_TYPE.ARROW_PREV}
        />
        <Button
          type={BUTTON_TYPE.ICON}
          onClick={handleNext}
          className="calendar-next"
          icon
          iconType={ICON_TYPE.ARROW_NEXT}
        />
      </nav>
    </div>
  );
};

export default Calendar;
