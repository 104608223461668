import * as React from 'react';
import isNull from 'lodash/isNull';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { selectWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { workingShiftInfo } from '~/helpers/constants';

interface IWorkShiftInfoTimeProps {
  workShiftId?: number;
  canChangeShiftId: boolean;
  onChangeShiftId: (id: number) => void;
}

export const WorkShiftInfoTime = ({
  workShiftId,
  canChangeShiftId = false,
  onChangeShiftId,
}: IWorkShiftInfoTimeProps) => {
  const workingShifts = useSelector(selectWorkingShifts);

  const handleSelectShiftId = React.useCallback((id: number) => {
    onChangeShiftId(id);
  }, []);

  return (
    <div className={`${workingShiftInfo}__time-block`}>
      {workingShifts.map(item => {
        const timeButtonClassName = cx(`${workingShiftInfo}__time`, {
          [`${workingShiftInfo}__time_selected`]: !isNull(workShiftId) && workShiftId === item?.id,
        });
        return (
          <button
            className={timeButtonClassName}
            onClick={() => handleSelectShiftId(item?.id)}
            disabled={!canChangeShiftId}
            key={item?.id}
          >
            {item?.name}
          </button>
        );
      })}
    </div>
  );
};
