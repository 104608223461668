import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';
import { additionalShiftsAdapter } from '~/redux/modules/additionalShiftsModule';
import moment from 'moment';

export const additionalShiftsSelector = additionalShiftsAdapter.getSelectors<IStore>(
  state => state.additionalShifts,
);

export const selectAdditionalShifts = createSelector(
  (state: IStore) => additionalShiftsSelector.selectAll(state),
  additionalShifts =>
    additionalShifts.sort(
      (a, b) => moment(a.start_time).valueOf() - moment(b.start_time).valueOf(),
    ),
);
