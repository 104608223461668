import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from '~/redux/modules/authModule';
import notificationReducer from '~/redux/modules/notificationsModule';
import usersReducer from '~/redux/modules/usersModule';
import zonesReducer from '~/redux/modules/zonesModule';
import scheduleTemplatesReducer from '~/redux/modules/scheduleTemplatesModule';
import rolesReducer from '~/redux/modules/rolesModule';
import professionsReducer from '~/redux/modules/professionsModule';
import departmentsReducer from '~/redux/modules/departmentsModule';
import bluetoothGadgetsReducer from '~/redux/modules/bluetoothGadgetsModule';
import workingShiftsReducer from '~/redux/modules/workingShiftsModule';
import tasksReducer from '~/redux/modules/tasksModule';
import shiftsReducer from '~/redux/modules/shiftsModule';
import vacationsReducer from '~/redux/modules/vacationsModule';
import additionalShiftsReducer from '~/redux/modules/additionalShiftsModule';
import companyOptionsReducer from '~/redux/modules/companyOptionsModule';
import stateReducer from '~/redux/modules/stateModule';
import tasksTemplatesReducer from '~/redux/modules/tasksTemplatesModule';
import tasksTypesReducer from '~/redux/modules/tasksTypesModule';
import tasksStatusesReducer from '~/redux/modules/tasksStatusesModule';
import tasksGeoTypesReducer from '~/redux/modules/tasksGeoTypesModule';
import usersFiltersReducer from '~/redux/reducers/UsersFiltersSlice';
import reportsFiltersReducer from '~/redux/reducers/ReportsFiltersSlice';
import usersFilterSortReducer from '~/redux/reducers/UsersFiltersSortSlice';
import timesheetsPeriodsReducer from '~/redux/modules/periodsModule';
import ShiftsReportReducer from '~/redux/modules/shiftsReportsModule';
import IncidentsListReducer from '~/redux/modules/incidentsListModule';
import ZoneEntranceReportReducer from '~/redux/modules/zoneEntranceReportsModule';
import tasksReportReducer from '~/redux/modules/tasksReportsModule';
import helmetReportReducer from '~/redux/modules/helmetReportsModule';
import currentWorkerReducer from '~/redux/modules/currentWorkerModule';
import tasksTimelessReducer from '~/redux/modules/tasksTimelessModule';
import popupReducer from '~/redux/modules/popupModule';
import dialogPopupReducer from '~/redux/modules/dialogPopupModule';
import reportsColumnsReducer from '~/redux/modules/reportsColumnsModule';
import workRequestCustomFieldsReducer from '~/redux/modules/workRequestCustomFieldsModule';
import taskTimelessTemplatesReducer from '~/redux/modules/tasksTimelessTemplatesModule';
import statisticsReducer from '~/redux/modules/statisticsModule';
import mapReducer from '~/redux/modules/mapModule';
import worksRequestsReducer from '~/redux/modules/workRequestsModule';

export const rootReducer = combineReducers({
  auth: authReducer,
  task: tasksReducer,
  taskTimeless: tasksTimelessReducer,
  notifications: notificationReducer,
  users: usersReducer,
  zones: zonesReducer,
  workingShifts: workingShiftsReducer,
  scheduleTemplates: scheduleTemplatesReducer,
  roles: rolesReducer,
  professions: professionsReducer,
  departments: departmentsReducer,
  bluetoothGadgets: bluetoothGadgetsReducer,
  shifts: shiftsReducer,
  vacations: vacationsReducer,
  additionalShifts: additionalShiftsReducer,
  companyOptions: companyOptionsReducer,
  state: stateReducer,
  tasksTemplates: tasksTemplatesReducer,
  taskTimelessTemplates: taskTimelessTemplatesReducer,
  tasksStatuses: tasksStatusesReducer,
  tasksTypes: tasksTypesReducer,
  taskGeoTypes: tasksGeoTypesReducer,
  usersFilters: usersFiltersReducer,
  reportsFilters: reportsFiltersReducer,
  usersFiltersSort: usersFilterSortReducer,
  timesheetsPeriods: timesheetsPeriodsReducer,
  reportsTask: tasksReportReducer,
  reportsShifts: ShiftsReportReducer,
  incidentsList: IncidentsListReducer,
  reportsZoneEntrance: ZoneEntranceReportReducer,
  reportsHelmet: helmetReportReducer,
  currentWorker: currentWorkerReducer,
  popup: popupReducer,
  dialogPopup: dialogPopupReducer,
  reportsColumns: reportsColumnsReducer,
  statistics: statisticsReducer,
  worksRequests: worksRequestsReducer,
  workRequestCustomFields: workRequestCustomFieldsReducer,
  map: mapReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
