import * as React from 'react';

interface IAsidePopupHeader {
  children?: React.ReactChild | React.ReactChild[];
}

const AsidePopupHeader: React.FC<IAsidePopupHeader> = ({ children }) => {
  return <div className="aside-popup__header">{children}</div>;
};

export default AsidePopupHeader;
