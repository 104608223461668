import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api, {
  IZoneEntranceDataFilter,
  ZoneEntranceData,
  ZoneEntranceDataReport,
} from 'sb_manufacturing_front_api';
import errorHandler from '~/utils/errorHandler';
export const fetchZoneEntranceReports = createAsyncThunk(
  'zoneEntraceReport/fetchAll',
  async (input: IZoneEntranceDataFilter) => {
    const response: ZoneEntranceDataReport = await Api.getZoneEntranceDataReport(input);
    if (response.kind === 'ok') {
      return response.data;
    } else {
      errorHandler(response);
    }
  },
);

const initialState: { data: any[]; totalCount: number } = {
  data: [],
  totalCount: 0,
};

export const zonEntranceReportSlice = createSlice({
  name: 'incidentList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchZoneEntranceReports.fulfilled, (state, action) => {
      const payload = action.payload as ZoneEntranceData;
      if (!payload) return;
      state.data = payload.data;
      state.totalCount = payload.totalCount;
    });
  },
});

export default zonEntranceReportSlice.reducer;
