import { IStore } from '~/redux/IStore';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

const selectSelf = (state: IStore) => state;

export const selectCompanyOptions = createDraftSafeSelector(
  selectSelf,
  state => state.companyOptions.options,
);

export const selectIsCompanyOptionsInit = createDraftSafeSelector(
  selectSelf,
  state => state.companyOptions.loading === 'fulfilled',
);
