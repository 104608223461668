import { NavLink } from 'react-router-dom';
import { ReactComponent as TasksSvg } from '~/assets/tasks.svg';
import { ReactComponent as WorkersSvg } from '~/assets/workers.svg';
import { ReactComponent as ReportSvg } from '~/assets/report.svg';
import { ReactComponent as HelpSvg } from '~/assets/help.svg';
import { ReactComponent as DocsSvg } from '~/assets/docs.svg';
import { ReactComponent as StatsSvg } from '~/assets/stats.svg';
import { ReactComponent as SchedulesSvg } from '~/assets/schedules.svg';
import { ReactComponent as MapSvg } from '~/assets/map.svg';
import { urlsMap } from '~/utils/urls';
import { titlesMap } from '~/utils/titles';
import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

export const SideMenu = () => {
  const useGeo = useSelector(selectCompanyOptions).use_geo;

  const arrayLinks: ({ url: string; title: string } | null)[] = [
    { url: urlsMap.tasks, title: titlesMap.tasks },
    { url: urlsMap.schedules, title: titlesMap.schedules },
    { url: urlsMap.workers, title: titlesMap.workers },
    useGeo ? { url: urlsMap.map, title: titlesMap.map } : null,
    { url: urlsMap.statistics, title: titlesMap.statistics },
    { url: urlsMap.reports, title: titlesMap.reports },
  ];

  const renderIcon = (name: string) => {
    switch (name) {
      case titlesMap.tasks:
        return <TasksSvg className="asideNav__ico" />;
      case titlesMap.schedules:
        return <SchedulesSvg className="asideNav__ico" />;
      case titlesMap.workers:
        return <WorkersSvg className="asideNav__ico" />;
      case titlesMap.statistics:
        return <StatsSvg className="asideNav__ico" />;
      case titlesMap.reports:
        return <ReportSvg className="asideNav__ico" />;
      case titlesMap.map:
        return <MapSvg className="asideNav__ico" />;
    }
  };

  return (
    <aside className="aside">
      <nav className="asideNav">
        {arrayLinks.map((item, key) => {
          if (item) {
            return (
              <NavLink
                to={item.url}
                className="asideNav__link"
                activeClassName="asideNav__link_active"
                key={key}
              >
                {renderIcon(item.title)}
                <span className="asideNav__link-text">{item.title}</span>
              </NavLink>
            );
          }
        })}
        <div className="asideNav__link asideNav__link_last">
          <a href={urlsMap.docs} target="_blank">
            <DocsSvg className="asideNav__ico" />
            <span className="asideNav__link-text">Документация</span>
          </a>
          <a href={urlsMap.email} target="_blank">
            <HelpSvg className="asideNav__ico" />
            <span className="asideNav__link-text">Написать нам</span>
          </a>
        </div>
      </nav>
    </aside>
  );
};
