import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectWorkingShift } from '~/redux/selectors/workingShiftsSelectors';

interface IWorkingShiftIcon {
  id: number;
}

const WorkingShiftIcon = ({ id }: IWorkingShiftIcon) => {
  const workingShiftType = useSelector(selectWorkingShift(id));

  if (!workingShiftType && id !== 0) return null;

  return (
    <div
      className={classNames(
        'workingShiftsTypes',
        `workingShiftsTypes--${id === 0 ? 'shift_icon' : workingShiftType?.icon}`,
      )}
    />
  );
};

export default WorkingShiftIcon;
