import React, { useState } from 'react';
import { TaskNoTimeInWorkInfo } from '~/containers/tasks/task/info/TaskNoTimeInWorkInfo';
import { Performers } from '~/containers/tasks/task/Performers';
import { Tooltip } from 'antd';
import { ITaskTimeless } from 'sb_manufacturing_front_api';
import { useSelector } from 'react-redux';
import { selectUsers } from '~/redux/selectors/usersSelectors';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import moment from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';

interface ITaskNoTimeInWork {
  task: ITaskTimeless;
  isMe?: boolean;
}

export const TaskNoTimeInWork: React.FC<ITaskNoTimeInWork> = ({ task, isMe }) => {
  const usersAll = useSelector(selectUsers);
  const companyOptions = useSelector(selectCompanyOptions);

  const [openInfo, setOpenInfo] = useState(false);
  const classStatus = ['default', 'in-work', 'complete', 'error', '', '', 'error'];
  const textStatus = ['Назначена', 'В работе', 'Выполнено', 'Отклонено', '', '', 'Просрочено'];
  const usersTask: number[] = task.tasks.map(item => item.user_id);
  const performers = usersAll.filter(user => usersTask.includes(user.id));

  const startDate = getLocalTimeFormat(
    task?.start_date,
    companyOptions?.time_zone,
    FORMAT_MOMENT.DDMMYYYY,
  );
  const endDate = getLocalTimeFormat(
    task?.end_date,
    companyOptions?.time_zone,
    FORMAT_MOMENT.DDMMYYYY,
  );
  const isSame = moment(task?.start_date).isSame(task?.end_date);

  const handelClick = () => {
    setOpenInfo(!openInfo);
  };

  return (
    <div className="task-no-time-in-work">
      <div className="task-no-time-in-work__header" onClick={handelClick}>
        <Tooltip placement="bottom" title={textStatus[task.status]}>
          <div className={`task-no-time-in-work__status ${classStatus[task.status]}`} />
        </Tooltip>
        <Tooltip placement="bottom" title={task.title}>
          <div className="task-no-time-in-work__title">{task.title}</div>
        </Tooltip>
        <div className="task-no-time-in-work__period">
          {isSame ? startDate : `${startDate} – ${endDate}`}
        </div>
        <div className="task-no-time-in-work__performer">
          <Performers users={performers} titleShow={false} oneLine={true} />
        </div>
      </div>
      {openInfo ? (
        <div className="task-no-time-in-work__list">
          <TaskNoTimeInWorkInfo
            isMe={isMe}
            task={task}
            startDate={task?.start_date}
            endDate={task?.end_date}
          />
        </div>
      ) : null}
    </div>
  );
};
