import * as React from 'react';
import { Modal } from 'antd';
import { ReactComponent as EyeFileSvg } from '~/assets/eye-file.svg';

interface IPdf {
  value: string;
}

export const PdfPreview = ({ value }: IPdf) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <iframe
        className="task-carousel__plus-iframe"
        src={value}
        frameBorder="0"
        allowFullScreen={true}
      />
      <div className="ant-image-mask" onClick={showModal}>
        <EyeFileSvg />
      </div>
      <Modal visible={isModalVisible} onCancel={handleCancel}>
        <iframe src={value} />
      </Modal>
    </>
  );
};
