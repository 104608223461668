import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { EShiftType, INewShift } from 'sb_manufacturing_front_api';
import { addUserShift } from '~/redux/modules/shiftsModule';
import { selectWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { prepareDateServerTime } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { FORMAT_MOMENT, workingShiftInfo, COLORS, ALERT, ALERT_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import { showAlert } from '~/redux/modules/stateModule';

interface IWorkShiftInfoActionsProps {
  workerId?: number;
  shift?: INewShift;
  workShiftDate: string;
  isStartedCreatingShift: boolean;
  workShiftId?: number;
  handleClosePopup: () => void;
}

export const WorkShiftInfoActions = ({
  workerId,
  shift,
  workShiftDate,
  isStartedCreatingShift,
  workShiftId,
}: IWorkShiftInfoActionsProps) => {
  const dispatch = useDispatch();
  const workingShifts = useSelector(selectWorkingShifts);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const scheduleInProgress = false;
  const workShiftDay = moment(workShiftDate, FORMAT_MOMENT.DASH_YYYYMMDD);

  const shiftStartTime = (workShiftId: number) => {
    const workingShift = workingShifts.find(item => item.id === workShiftId);
    const beginTime = moment(workingShift?.begin_time, FORMAT_MOMENT.HHMMSS).add(timeZone, 'hours');

    workShiftDay.hours(moment(beginTime).hours());
    workShiftDay.minutes(moment(beginTime).minutes());
    workShiftDay.seconds(moment(beginTime).seconds());

    return prepareDateServerTime(workShiftDay.format(FORMAT_MOMENT.YYYYMMDD_HHMMSS), timeZone);
  };

  const onCreate = React.useCallback(() => {
    if (workShiftId && workerId) {
      dispatch(
        addUserShift({
          workShiftId,
          start_time: shiftStartTime(workShiftId),
          userId: workerId,
        }),
      );
    }
  }, [workShiftId]);

  const onUpdate = React.useCallback(() => {
    if (shift && workShiftId && workerId) {
      const data = {
        title: 'Изменение смены',
        description: 'Вы действительно хотите изменить смену?',
        name: ALERT.CHANGE_SHIFT,
        type: ALERT_TYPE.CHANGE,
        data: {
          shiftId: shift.id,
          userId: workerId,
          startTime: shiftStartTime(workShiftId),
          workShiftId: workShiftId,
        },
      };

      dispatch(showAlert(data));
    }
  }, [workShiftId]);

  const onDelete = React.useCallback(() => {
    if (shift) {
      const data = {
        title: 'Удаление смены',
        description: 'Вы действительно хотите удалить смену?',
        name: ALERT.REMOVE_SHIFT,
        type: ALERT_TYPE.REMOVE,
        data: {
          shiftId: shift.id,
        },
      };

      dispatch(showAlert(data));
    }
  }, [shift]);

  return (
    <div className={`${workingShiftInfo}__actions`}>
      {shift && !isStartedCreatingShift && (
        <>
          {[EShiftType.CANCELED, EShiftType.ADDITIONAL].includes(shift.type) && (
            <Button text="Удалить" onClick={onDelete} background={COLORS.PINK} border small />
          )}
          {[EShiftType.ACCEPTED, EShiftType.DEFAULT].includes(shift.type) && (
            <>
              <Button text="Удалить" onClick={onDelete} background={COLORS.PINK} border small />
              <Button
                text="Сохранить"
                disabled={workShiftId === shift.working_shift_id || scheduleInProgress}
                background={COLORS.BLUE}
                onClick={onUpdate}
                small
              />
            </>
          )}
        </>
      )}
      {isStartedCreatingShift && (
        <Button
          text="Создать"
          disabled={!workShiftId || scheduleInProgress}
          background={COLORS.BLUE}
          onClick={onCreate}
          small
        />
      )}
    </div>
  );
};
