declare global {
  interface String {
    firstToUpper(): string;
  }
}

String.prototype.firstToUpper = function () {
  if (typeof this === 'string' && !!this.length) {
    return this.charAt(0).toUpperCase() + this.slice(1);
  } else return String(this);
};

export {};
