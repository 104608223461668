import * as React from 'react';
import { INewUser } from 'sb_manufacturing_front_api';
import { ScheduleTemplateInfo } from '~/components/schedule/popup/schedule-template-info';
import { useDispatch, useSelector } from 'react-redux';
import { selectScheduleTemplates } from '~/redux/selectors/scheduleTemplatesSelectors';
import { getLocalTimeSchedule, prepareDateServerTime } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { updateUser } from '~/redux/reducers/GetUsersThunk';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { useOnClickOutside } from 'usehooks-ts';
import { createSchedulePopup, COLORS } from '~/helpers/constants';
import SelectsField from '~/components/form/select/Select';
import Button from '~/components/form/buttons/Button';
import moment from 'moment';

interface ICreateSchedulePopup {
  onClose: () => void;
  user: INewUser;
  rowIndex: number;
  rowLength: number;
}

export const CreateSchedulePopup = ({
  onClose,
  user,
  rowIndex,
  rowLength,
}: ICreateSchedulePopup) => {
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLDivElement>(null);
  const { firstname, lastname, middlename } = user;
  const preparedOptions: ISelectOption = {};

  const userScheduleTemplates = useSelector(selectScheduleTemplates);
  const companyOptions = useSelector(selectCompanyOptions);

  userScheduleTemplates.forEach(template => {
    preparedOptions[template.id] = template.name;
  });

  const [closePopup, setClosePopup] = React.useState(() => onClose);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);
  const [dateStart, setDateStart] = React.useState(
    getLocalTimeSchedule(user?.schedule_template_start_date, companyOptions.time_zone),
  );
  const [dateEnd, setDateEnd] = React.useState(
    getLocalTimeSchedule(user?.schedule_template_end_date, companyOptions.time_zone),
  );
  const [scheduleTemplateId, setScheduleTemplateId] = React.useState<number | undefined>(
    user.schedule_template_id,
  );

  useOnClickOutside(ref, closePopup);

  React.useEffect(() => {
    setDateEnd(moment().isBefore(dateEnd) ? dateEnd : '');
  }, [dateEnd]);

  const handleSubmit = async () => {
    setSubmitDisabled(true);

    const result: any = await dispatch(
      updateUser({
        schedule_template_id: scheduleTemplateId,
        schedule_template_start_date: prepareDateServerTime(dateStart, companyOptions.time_zone),
        schedule_template_end_date: prepareDateServerTime(dateEnd, companyOptions.time_zone),
        id: user.id,
        department_id: user.departments_ids[0],
        role: user.role_id,
      }),
    );

    setSubmitDisabled(false);

    if (result?.kind === 'ok' || result?.payload?.kind === 'ok') {
      toast.success('Данные обновлены');
      onClose();
    }
  };

  const datePickerVisible = (value: boolean) => {
    if (!value) {
      setClosePopup(() => onClose);
    } else {
      setClosePopup(() => {});
    }
  };

  return (
    <div
      className={classNames(createSchedulePopup, {
        ['bottomPosition']: rowIndex >= 6 && rowIndex > rowLength - 7,
      })}
      ref={ref}
    >
      <div className={`${createSchedulePopup}__close`} onClick={onClose}>
        <CloseSvg />
      </div>
      <div className={`${createSchedulePopup}__block`}>
        <div className={`${createSchedulePopup}_header`}>Создание графика</div>
        <div className={`${createSchedulePopup}_name`}>
          {lastname} {firstname} {middlename}
        </div>
      </div>
      <div className={`${createSchedulePopup}__block`}>
        <SelectsField
          options={preparedOptions}
          onChange={(value: ISelectValue) => setScheduleTemplateId(Number(value!.value))}
          defaultValue={scheduleTemplateId}
        />
      </div>
      <div className={`${createSchedulePopup}__block`}>
        <ScheduleTemplateInfo
          dateStart={dateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
          setDateStart={setDateStart}
          changeVisible={datePickerVisible}
        />
      </div>
      <div className={`${createSchedulePopup}__block`}>
        <Button
          text={submitDisabled ? 'Создаю..' : 'Создать'}
          disabled={submitDisabled}
          background={COLORS.BLUE}
          onClick={handleSubmit}
          small
        />
      </div>
    </div>
  );
};
