import moment from 'moment';
import textDeclension from '~/helpers/textDeclension';
import { DECLINATION_TYPE } from '~/helpers/constants';

const convertTimeHM = (milliseconds: number, convertSecond: boolean, fullText?: boolean) => {
  const momentTime = moment.duration(convertSecond ? milliseconds / 1000 : milliseconds, 'seconds');
  let text: string;

  const hours = Math.floor(momentTime.asHours());
  const mins = Math.floor(momentTime.asMinutes()) - hours * 60;

  if (fullText) {
    const textHours = textDeclension(hours, DECLINATION_TYPE.HOURS);
    const textMins = textDeclension(mins, DECLINATION_TYPE.MINUTES);

    text = hours ? textHours + ' ' + textMins : textMins;
  } else {
    const textM = !!mins ? `${mins} м` : '';

    text = hours >= 0 ? `${hours} ч ${textM}` : '0 ч';
  }

  return {
    number: Number(hours + '.' + mins),
    text: text,
  };
};

export default convertTimeHM;
