import * as React from 'react';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import classNames from 'classnames';

interface IUsersPagination {
  limit: number;
  setLimit: (limit: number) => void;
  page: number;
  setPage: (page: number) => void;
  totalCount: number;
  className?: string;
}

const Pagination = ({
  limit,
  setLimit,
  page,
  setPage,
  totalCount,
  className,
}: IUsersPagination) => {
  const pageCount = React.useMemo(() => Math.ceil(totalCount / limit), [totalCount, limit]);

  const handleLimitInputChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    event => {
      const value = event.target.value;
      if (/^\d+$/.test(value) || value === '') {
        setLimit(Number(value));
        setPage(1);
      }
    },
    [setLimit],
  );

  const handlePageInputChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    event => {
      const value = event.target.value;
      const parsedValue = Number(value);

      if (parsedValue < 1) {
        return setPage(1);
      }
      if (parsedValue > pageCount) {
        return setPage(pageCount);
      }

      if (/^\d+$/.test(value)) {
        setPage(parsedValue);
      }
    },
    [setPage, pageCount],
  );

  const handlePrevPage = React.useCallback(() => setPage(page <= 1 ? 1 : page - 1), [
    setPage,
    page,
  ]);

  const handleNextPage = React.useCallback(() => (page >= pageCount ? '' : setPage(page + 1)), [
    setPage,
    page,
    totalCount,
  ]);

  const activePrev = React.useMemo(() => page > 1, [page]);
  const activeNext = React.useMemo(() => page < pageCount, [page, totalCount]);

  return (
    <div className={classNames('pagination', className)}>
      <div className="pagination_limit">
        <span className="pagination_limit-span">Выводить на странице</span>
        <input
          type="text"
          className="pagination_limit-input"
          value={limit}
          onChange={handleLimitInputChange}
        />
      </div>
      <div className="pagination__buttons">
        <Button
          type={BUTTON_TYPE.ICON}
          text="Назад"
          onClick={handlePrevPage}
          className="pagination"
          icon
          iconType={ICON_TYPE.ARROW_PREV}
          disabled={!activePrev}
        />
        <input
          type="text"
          className="pagination_limit-input"
          value={page}
          onChange={handlePageInputChange}
          onFocus={e => {
            e.preventDefault();
            e.target.select();
          }}
        />
        <Button
          type={BUTTON_TYPE.ICON}
          text="Вперед"
          onClick={handleNextPage}
          className="pagination"
          icon
          reverse
          iconType={ICON_TYPE.ARROW_NEXT}
          disabled={!activeNext}
        />
      </div>
    </div>
  );
};

export default Pagination;
