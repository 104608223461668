import * as React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import { INewNotification } from 'sb_manufacturing_front_api';
import Popup from '~/components/popup/Popup';
import { selectNotifications } from '~/redux/selectors/notificationSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import { FORMAT_MOMENT } from '~/helpers/constants';
import getTextOrToday from '~/helpers/getTextOrToday';

const Notification = ({ date, message, is_read, title }: INewNotification) => {
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  const timeStr = React.useMemo(
    () => moment.utc(date).utcOffset(timeZone).format(FORMAT_MOMENT.HMM),
    [date],
  );
  return (
    <div className={cx('notification__item', { active: !Number(is_read) })}>
      <p className="notification__time">
        {timeStr}
        <span>{title ? title : ''}</span>
      </p>
      <p className="notification__text">{message}</p>
    </div>
  );
};

const NotificationsPopup = () => {
  const notifications = useSelector(selectNotifications);
  const timeZone = useSelector(selectCompanyOptions).time_zone;
  let lastDate = '';

  return (
    <Popup aside>
      <AsidePopupHeader>
        <h2 className="aside-popup__title">Уведомления</h2>
      </AsidePopupHeader>
      <AsidePopupBody>
        <div className="notification">
          {notifications &&
            notifications.map(notification => {
              const currentDate = moment.utc(notification.date).local().format(FORMAT_MOMENT.DMMMM);
              const renderDate = currentDate !== lastDate;

              lastDate = currentDate;

              return (
                <>
                  {renderDate && (
                    <h3 className="notification__title">
                      {getTextOrToday(notification.date, currentDate, timeZone)}
                    </h3>
                  )}
                  <Notification key={`notification${notification.id}`} {...notification} />
                </>
              );
            })}
          {!notifications.length && <p className="notification__none">Уведомлений нет</p>}
        </div>
      </AsidePopupBody>
    </Popup>
  );
};

export default NotificationsPopup;
