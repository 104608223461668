import * as React from 'react';
import { useSelector } from 'react-redux';
import { INewUser } from 'sb_manufacturing_front_api';
import { selectUsers } from '~/redux/selectors/usersSelectors';
import { ReactComponent as DeleteSvg } from '~/assets/delete-circle.svg';

interface IAddedUsers {
  users: number[];
  onRemove: (id: number) => void;
  onClickAdd: (value: boolean) => void;
  readonly?: boolean;
}

interface IUserEntity {
  user: INewUser;
  onRemove: (id: number) => void;
  readonly?: boolean;
}

const UserEntity = ({ user, onRemove, readonly }: IUserEntity) => {
  const ref = React.useRef<HTMLDivElement>();

  const initials = React.useMemo(
    () => `${user.lastname.charAt(0).toUpperCase()}${user.firstname.charAt(0).toUpperCase()}`,
    [user.lastname, user.firstname],
  );
  const handleRemove = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onRemove(user.id);
    },
    [user.id, onRemove],
  );

  return (
    <>
      <div ref={ref as React.RefObject<HTMLDivElement>} className="user-avatar user-avatar_popup">
        {initials}
        {!readonly && <DeleteSvg className="user-avatar__remove" onClick={handleRemove} />}
      </div>
    </>
  );
};

const AddedUsers = ({ users, onRemove, onClickAdd, readonly }: IAddedUsers) => {
  const allUsers = useSelector(selectUsers);
  const usersValues = React.useMemo(() => allUsers.filter(user => users.includes(user.id)), [
    allUsers,
    users,
  ]);

  return (
    <>
      {!readonly && (
        <div className="task-carousel__plus-circle" onClick={() => onClickAdd(true)}>
          +
        </div>
      )}
      {usersValues.map(u => (
        <UserEntity key={u.id} user={u} onRemove={onRemove} readonly={readonly} />
      ))}
    </>
  );
};

export default AddedUsers;
