export const urlsMap = {
  index: '/',
  login: '/login',
  tasks: '/tasks',
  schedules: '/schedules',
  workers: '/workers',
  statistics: '/statistics',
  reports: '/reports',
  map: '/map',
  docs: 'https://wfmdoc.smena.app/',
  email: 'mailto:support@smena.app',
};
