import React from 'react';
import { ArrowTop } from '~/assets/arrow-top';
import { ArrowDown } from '~/assets/arrow-down';

interface ITasksNoTimeFilter {
  setFilter: (value: [string, boolean]) => void;
  filter: [string, boolean];
}

export const TasksNoTimeFilter: React.FC<ITasksNoTimeFilter> = ({ setFilter, filter }) => {
  const handelFilter = (name: string, active: boolean) => {
    setFilter([name, active]);
  };

  return (
    <div className="tasks-no-time-filter">
      <div className="tasks-no-time-filter__title">
        <div
          className={`tasks-no-time-filter__wrapper ${filter[0] === 'name' ? filter[1] : ''}`}
          onClick={() => handelFilter('name', !filter[1])}
        >
          <span>Задача</span>
          <span className="sort-arrows">
            <ArrowTop />
            <ArrowDown />
          </span>
        </div>
      </div>
      <div className="tasks-no-time-filter__period">
        <div
          className={`tasks-no-time-filter__wrapper ${filter[0] === 'date' ? filter[1] : ''}`}
          onClick={() => handelFilter('date', !filter[1])}
        >
          <span>Период</span>
          <span className="sort-arrows">
            <ArrowTop />
            <ArrowDown />
          </span>
        </div>
      </div>
      <div className="tasks-no-time-filter__performer">
        <div className="tasks-no-time-filter__wrapper">
          <span>Исполнители</span>
        </div>
      </div>
    </div>
  );
};
