import * as React from 'react';
import classNames from 'classnames';

interface ISVGIcon {
  color?: string;
}

export const ArrowTop = ({ color }: ISVGIcon) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('stroke-current', color)}
    >
      <path d="M8.53553 4.96449L5 1.42896L1.46447 4.96449" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
