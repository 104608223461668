import * as React from 'react';
import classNames from 'classnames';
import { INewUser } from 'sb_manufacturing_front_api';
import { Header } from '~/containers/workers/list-table/header';
import { Row } from '~/containers/workers/list-table/row';

interface IUsersTable {
  className?: string;
  users: INewUser[];
}

export const UsersTable = ({ users, className }: IUsersTable) => {
  return (
    <div className={classNames('list-table', className)}>
      <Header />
      {users.length < 1 && <div className="no-results">Ничего не найдено</div>}
      {users.map(user => (
        <Row key={user.id} {...user} />
      ))}
    </div>
  );
};
