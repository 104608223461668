import * as React from 'react';

interface IAsidePopupBody {
  children: React.ReactChild | React.ReactChild[];
}

const AsidePopupBody: React.FC<IAsidePopupBody> = ({ children }) => {
  return <div className="aside-popup__body">{children}</div>;
};

export default AsidePopupBody;
