import * as React from 'react';
import { selectCurrentUserId, selectUserRole } from '~/redux/selectors/authSelectors';
import { ReactComponent as ArrowDownSvg } from '~/assets/arrow-down.svg';
import moment from 'moment';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { useAppSelector } from '~/hooks/redux';
import { TaskInfo } from './task-carousel/task-info';
import { selectCurrentTask } from '~/redux/selectors/stateSelectors';
import { TaskStatus } from './task-status';
import { ITaskTrack } from 'sb_manufacturing_front_api';
import { getLocalTime, getLocalTimeFormat } from '~/helpers/convertToUnix';
import { FORMAT_MOMENT, TYPE_ROLE } from '~/helpers/constants';
import { setCurrentTask } from '~/redux/modules/stateModule';
import { useDispatch, useSelector } from 'react-redux';

export const UserMenuTask = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  const userId = useAppSelector(selectCurrentUserId);
  const currentTask = useAppSelector(selectCurrentTask);
  const companyOptions = useAppSelector(selectCompanyOptions);
  const [openTask, setOpenTask] = React.useState(false);
  const [timeStart, setTimeStart] = React.useState<string | undefined>('');
  const [timeEnd, setTimeEnd] = React.useState<string | undefined>('');
  const tracks: ITaskTrack[] | undefined = currentTask?.tracks;

  React.useEffect(() => {
    if (userId) {
      if (tracks) {
        setTimeStart(
          getLocalTimeFormat(
            tracks.slice(-1)[0]?.start_time ?? currentTask?.begin_time,
            companyOptions.time_zone,
            FORMAT_MOMENT.HHMM,
          ),
        );
        setTimeEnd(
          getLocalTimeFormat(
            tracks.slice(-1)[0]?.end_time ?? currentTask?.end_time,
            companyOptions.time_zone,
            FORMAT_MOMENT.HHMM,
          ),
        );
      } else {
        setTimeStart(
          getLocalTimeFormat(currentTask?.begin_time, companyOptions.time_zone, FORMAT_MOMENT.HHMM),
        );
        setTimeEnd(
          getLocalTimeFormat(currentTask?.end_time, companyOptions.time_zone, FORMAT_MOMENT.HHMM),
        );
      }
    }
  }, [userId, companyOptions, JSON.stringify(currentTask)]);

  const openPopupTask = () => {
    if (currentTask) {
      setOpenTask(!openTask);
    }
  };

  const popupTaskClose = () => {
    setOpenTask(!openTask);
  };

  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(true);
  let timerId: any;

  React.useEffect(() => {
    setCountDown(
      moment(getLocalTime(currentTask?.end_time || '', companyOptions.time_zone)).unix() -
        moment().unix(),
    );

    timerId = setInterval(() => {
      setCountDown(countDown => countDown - 60);
    }, 60000);

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && userRole === TYPE_ROLE.SUPERVISOR) {
      console.log('!!!');
      dispatch(setCurrentTask());
      setRunTimer(true);
    }
  }, [countDown]);

  return (
    <div className="user-menu__item user-menu__item--task">
      <button
        className={`user-menu__task ${
          tracks && tracks[tracks.length - 1]?.start_time && !tracks[tracks.length - 1]?.end_time
            ? 'user-menu__task--active'
            : tracks && tracks[tracks.length - 1]?.start_time && tracks[tracks.length - 1]?.end_time
            ? 'user-menu__task--active-end'
            : ''
        }`}
        onClick={openPopupTask}
      >
        {currentTask ? (
          <>
            <div className={`user-menu__task-status`}>
              <TaskStatus status={currentTask.status} />
            </div>
            <div className="user-menu__block-date-time">
              <p className="user-menu__time">
                Задача
                <p
                  className={
                    tracks && tracks[tracks.length - 1]?.start_time
                      ? 'user-menu__time-start user-menu__time-start_active'
                      : 'user-menu__time-start'
                  }
                >
                  {timeStart}
                </p>
                <p
                  className={
                    tracks && tracks[tracks.length - 1]?.start_time
                      ? 'user-menu__time-end user-menu__time-end_active'
                      : 'user-menu__time-end'
                  }
                >
                  {' '}
                  – {timeEnd}
                </p>
              </p>
              <span className="user-menu__date">{currentTask.title}</span>
            </div>
          </>
        ) : (
          <div className="user-menu__block-date-time">
            <p className="user-menu__time">У вас нет задач</p>
          </div>
        )}
        <ArrowDownSvg />
      </button>
      <TaskInfo
        task={currentTask}
        read_only={currentTask?.read_only}
        id={currentTask?.id ?? 0}
        user_id={userId}
        onClose={popupTaskClose}
        status={currentTask?.status ?? 0}
        title={currentTask?.title ?? 'Название задачи'}
        type_id={currentTask?.type_id}
        geo_type={currentTask?.geo_type}
        geo={currentTask?.geo}
        start={currentTask?.begin_time ?? moment().format(FORMAT_MOMENT.YYYYMMDD_HHMMSS)}
        end={currentTask?.end_time ?? moment().format(FORMAT_MOMENT.YYYYMMDD_HHMMSS)}
        show={openTask}
        priority={currentTask?.priority ?? 0}
        is_comment={currentTask?.is_comment}
        need_path_photo_report={currentTask?.need_path_photo_report}
        description={currentTask?.description ?? ''}
        need_photo_report={currentTask?.need_photo_report ?? 0}
        need_department_test={currentTask?.need_department_test}
        need_supervisor_test={currentTask?.need_supervisor_test}
        unfixed={currentTask?.unfixed}
      />
    </div>
  );
};
