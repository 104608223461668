import * as React from 'react';
import Popup from '~/components/popup/Popup';
import AsidePopupBody from '~/components/popup/AsidePopupBody';
import { Entry } from '~/containers/tasks/popups/task-popup/history';
import { INewTaskLog } from 'sb_manufacturing_front_api';
import AsidePopupHeader from '~/components/popup/AsidePopupHeader';
import { closePopup } from '~/redux/modules/popupModule';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { useDispatch, useSelector } from 'react-redux';
import { FORMAT_MOMENT, BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

interface IViewTaskPopup {
  taskLogs: INewTaskLog[];
  inside: boolean;
  task: any;
}

const HistoryTaskPopup = ({ taskLogs, inside, task }: IViewTaskPopup) => {
  const dispatch = useDispatch();
  const companyOptions = useSelector(selectCompanyOptions);
  let dates = '';
  const handleClickBack = () => {
    dispatch(closePopup());
    setTimeout(() => {
      dispatch(openPopup({ name: POPUPS_NAME.VIEW_TASK_POPUP, data: { task: task } }));
    }, 10);
  };
  const setDateState = (date: string) => {
    dates = getLocalTimeFormat(date, companyOptions.time_zone, FORMAT_MOMENT.DDMMMM);
  };

  return (
    <Popup aside>
      <>
        {inside ? (
          <AsidePopupHeader>
            <Button
              type={BUTTON_TYPE.ICON}
              text="Назад"
              onClick={handleClickBack}
              className="back"
              icon
              iconType={ICON_TYPE.ARROW_PREV}
            />
          </AsidePopupHeader>
        ) : null}
        <AsidePopupBody>
          <div className="history-page">
            <h3 className="history-page__title">История задачи</h3>
            {taskLogs &&
              taskLogs?.map((entry, index) => {
                if (
                  dates !==
                  getLocalTimeFormat(entry.date, companyOptions.time_zone, FORMAT_MOMENT.DDMMMM)
                ) {
                  setDateState(entry.date);
                  return (
                    <>
                      <span className="history-page__date">{dates}</span>
                      <Entry key={index} {...entry} />
                    </>
                  );
                } else {
                  return <Entry key={index} {...entry} />;
                }
              })}
          </div>
        </AsidePopupBody>
      </>
    </Popup>
  );
};

export default HistoryTaskPopup;
