import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UsersFiltersState {
  usersFilters: IColumnFilter[];
}

export interface IResetFilter {
  name: string;
}

export interface IToggleFilter {
  name: string;
  id: number;
}

const initialState: UsersFiltersState = {
  usersFilters: [],
};

export const usersFiltersSlice = createSlice({
  name: 'usersFilters',
  initialState,
  reducers: {
    setUsersFilters(state, action: PayloadAction<IColumnFilter[]>) {
      state.usersFilters = action.payload;
    },
    resetFilter(state, action: PayloadAction<IResetFilter>) {
      const { name } = action.payload;
      const filter = state.usersFilters.find(filter => filter.name === name);
      const resetChecks = filter?.data.map(el => ({ ...el, check: false }));
      if (!resetChecks || !filter) return;
      filter.data = resetChecks;
    },
    allFilter(state, action: PayloadAction<IResetFilter>) {
      const { name } = action.payload;
      const filter = state.usersFilters.find(filter => filter.name === name);
      const allChecks = filter?.data.map(el => ({ ...el, check: true }));
      if (!allChecks || !filter) return;
      filter.data = allChecks;
    },
    toggleFilter(state, action: PayloadAction<IToggleFilter>) {
      const { id, name } = action.payload;
      const filter = state.usersFilters.find(filter => filter.name === name);
      const currentCheck = filter?.data.find(el => el.id === id);
      if (!currentCheck) return;
      currentCheck.check = !currentCheck.check;
    },
    switchOffFilter(state, action: PayloadAction<IToggleFilter>) {
      const { id, name } = action.payload;
      const filter = state.usersFilters.find(filter => filter.name === name);
      const currentCheck = filter?.data.find(el => el.id === id);
      if (!currentCheck) return;
      currentCheck.check = false;
    },
  },
});

export default usersFiltersSlice.reducer;
export const {
  setUsersFilters,
  toggleFilter,
  switchOffFilter,
  resetFilter,
  allFilter,
} = usersFiltersSlice.actions;
