import { INewRole } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const rolesAdapter = createEntityAdapter<INewRole>({
  selectId: role => role.id,
});

export interface IRolesState {
  ids: EntityId[];
  entities: Dictionary<INewRole>;
}

const rolesSlice = createSlice({
  name: 'roles',
  initialState: rolesAdapter.getInitialState(),
  reducers: {
    create: rolesAdapter.setMany,
    update: rolesAdapter.upsertMany,
    remove: rolesAdapter.removeMany,
    clear: rolesAdapter.removeAll,
  },
});

export default rolesSlice.reducer;

export const { create, update, remove, clear } = rolesSlice.actions;
