import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { tasksStatusesAdapter } from '~/redux/modules/tasksStatusesModule';

export const tasksStatusesSelectors = tasksStatusesAdapter.getSelectors<IStore>(
  state => state.tasksStatuses,
);

export const selectDataTaskStatuses = createSelector(
  (state: IStore) => tasksStatusesSelectors.selectAll(state),
  statuses =>
    statuses.map(status => ({
      id: status.id,
      name: status.name,
      deleted: false,
      check: false,
    })),
);
