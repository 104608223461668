import * as React from 'react';
import { useAppDispatch, useAppSelector } from '~/hooks/redux';
import { EOrderDirection } from 'sb_manufacturing_front_api';
import { setUsersFiltersSort } from '~/redux/reducers/UsersFiltersSortSlice';
import { ReactComponent as ArrowTopSvg } from '~/assets/arrow-top.svg';
import { ReactComponent as ArrowBottomSvg } from '~/assets/arrow-bottom.svg';

export const Header = () => {
  const { field, direction } = useAppSelector(state => state.usersFiltersSort);

  const dispatch = useAppDispatch();

  const handlerSortField = React.useCallback(
    (fieldName: string) => {
      return function () {
        if (field !== fieldName) {
          return dispatch(
            setUsersFiltersSort({
              field: fieldName,
              direction: EOrderDirection.ASC,
            }),
          );
        }
        if (direction === EOrderDirection.DESC) {
          return dispatch(
            setUsersFiltersSort({
              field: fieldName,
              direction: EOrderDirection.ASC,
            }),
          );
        }
        dispatch(
          setUsersFiltersSort({
            field: fieldName,
            direction: EOrderDirection.DESC,
          }),
        );
      };
    },
    [direction, field],
  );

  const active = React.useCallback(
    (fieldName: string) => {
      if (fieldName === field) {
        if (direction === EOrderDirection.DESC) {
          return {
            top: false,
            bottom: true,
          };
        }
        return {
          top: true,
          bottom: false,
        };
      }
      return {
        top: false,
        bottom: false,
      };
    },
    [direction, field],
  );

  return (
    <header className="list-table__row list-table__row_header">
      <div className="list-table__col list-table__col_avatar" />
      <div
        className="list-table__col list-table__col_name"
        onClick={() => {
          handlerSortField('fio')();
        }}
      >
        <span>ФИО</span>
        <span className="sort-arrows">
          <ArrowTopSvg className={active('fio').top ? 'active-sort' : ''} />
          <ArrowBottomSvg className={active('fio').bottom ? 'active-sort' : ''} />
        </span>
      </div>
      <div
        className="list-table__col list-table__col_role"
        onClick={() => {
          handlerSortField('role')();
        }}
      >
        <span>Роль</span>
        <span className="sort-arrows">
          <ArrowTopSvg className={active('role').top ? 'active-sort' : ''} />
          <ArrowBottomSvg className={active('role').bottom ? 'active-sort' : ''} />
        </span>
      </div>
      <div
        className="list-table__col list-table__col_specialisation"
        onClick={() => {
          handlerSortField('profession')();
        }}
      >
        <span>Профессия</span>
        <span className="sort-arrows">
          <ArrowTopSvg className={active('profession').top ? 'active-sort' : ''} />
          <ArrowBottomSvg className={active('profession').bottom ? 'active-sort' : ''} />
        </span>
      </div>
      <div className="list-table__col list-table__col_place">
        <span>Цех</span>
      </div>
      <div className="list-table__col list-table__col_number">
        <span>Табельный номер</span>
      </div>
    </header>
  );
};
