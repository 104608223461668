export const convertToLocal = (time?: string, hourOnly?: boolean, options?: number) => {
  if (!time) return;
  if (!options) return;

  const [hours, minutes] = time.split(':');
  let hour = `${(parseInt(hours) + options) % 24}`;

  hour = hour.padStart(2, '0');

  if (hourOnly) {
    return hour;
  }
  return hour + ':' + minutes;
};

export default convertToLocal;
