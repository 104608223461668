import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectWorkingShift } from '~/redux/selectors/workingShiftsSelectors';
import moment from 'moment';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { FORMAT_MOMENT, workingShiftInfo } from '~/helpers/constants';

interface IFC {
  workShiftId?: number;
}

const WorkShiftDinner = ({ workShiftId }: IFC) => {
  const workShift = useSelector(selectWorkingShift(workShiftId));
  const timeZone = useSelector(selectCompanyOptions).time_zone;

  if (!workShift?.has_dinner) {
    return null;
  }

  const startDinner = moment(workShift?.dinner_begin_time, FORMAT_MOMENT.HHMMSS)
    .add(timeZone, 'hours')
    .format(FORMAT_MOMENT.HHMM);
  const endDinner = moment(workShift?.dinner_end_time, FORMAT_MOMENT.HHMMSS)
    .add(timeZone, 'hours')
    .format(FORMAT_MOMENT.HHMM);

  return (
    <div className={`${workingShiftInfo}__dinner`}>
      <div className={`${workingShiftInfo}__dinnerTitle`}>Обед</div>
      <div className={`${workingShiftInfo}__dinnerTime`}>
        {startDinner} - {endDinner}
      </div>
    </div>
  );
};

export default WorkShiftDinner;
