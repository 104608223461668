import * as React from 'react';
import { YMaps, Map } from '@pbe/react-yandex-maps';
import ymaps, { IMapState } from 'yandex-maps';
import MapAside from '~/components/map/MapAside';
import { titlesMap } from '~/utils/titles';
import { ReactComponent as PlusSvg } from '~/assets/mapPlus.svg';
import { ReactComponent as MinusSvg } from '~/assets/mapMinus.svg';
import { ReactComponent as LocationSvg } from '~/assets/location.svg';
import moment from 'moment';
import { FORMAT_MOMENT, ICON_TYPE, PLACEHOLDER, TYPE_ROLE } from '~/helpers/constants';
import getTextOrToday from '~/helpers/getTextOrToday';
import Calendar from '~/containers/tasks/calendar';
import SelectsField from '~/components/form/select/Select';
import { nextDay, prevDay, setDepartmentId, setWorkShiftId } from '~/redux/modules/stateModule';
import { useAppSelector } from '~/hooks/redux';
import { selectDay, selectDepartmentId, selectWorkShiftId } from '~/redux/selectors/stateSelectors';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { useDispatch } from 'react-redux';
import { selectUser, selectUsers } from '~/redux/selectors/usersSelectors';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import { selectWorkingShifts } from '~/redux/selectors/workingShiftsSelectors';
import { selectShifts } from '~/redux/selectors/shiftsSelectors';
import { selectDepartments } from '~/redux/selectors/departmentsSelectors';
import MapControls from '~/containers/map/Controls/Controls';
import classNames from 'classnames';
import MapZones from '~/components/map/MapZones';
import MapCoords from '~/components/map/MapCoords';
import { getCommonMapData } from '~/redux/modules/mapModule';
import { selectMapList, selectMapLoading } from '~/redux/selectors/mapSelectors';
import MapPlacemark from '~/components/map/MapPlacemark';
import { prepareDateServerTime } from '~/helpers/convertToUnix';

const state: IMapState = {
  center: [63.87380822280273, 122.71362327944605],
  type: 'yandex#hybrid',
  controls: undefined,
};

const MapScreen = () => {
  const dispatch = useDispatch();
  const [zoom, setZoom] = React.useState<number>(14);
  const [center, setCenter] = React.useState<boolean>(false);
  const [loader, setLoader] = React.useState<boolean>(true);
  const [infoOpen, setInfoOpen] = React.useState<number | null>(null);
  const [zoneName, setZoneName] = React.useState<string | null>(null);
  const [userName, setUserName] = React.useState<string | null>(null);
  const [currentTime, setCurrentTime] = React.useState<string | null>(null);

  const currentUserId = useAppSelector(selectCurrentUserId);
  const users = useAppSelector(selectUsers);
  const user = useAppSelector(selectUser(currentUserId));
  const selectedDay = useAppSelector(selectDay);
  const companyOptions = useAppSelector(selectCompanyOptions);
  const workShiftId = useAppSelector(selectWorkShiftId);
  const workingShiftsTypes = useAppSelector(selectWorkingShifts);
  const departmentId = useAppSelector(selectDepartmentId);
  const departments = useAppSelector(selectDepartments);
  const shifts = useAppSelector(selectShifts);
  const asideList = useAppSelector(selectMapList);
  const mapLoading = useAppSelector(selectMapLoading);

  const preparedWorkingShifts: ISelectOption = {};
  const preparedDepartments: ISelectOption = {};

  const userWorkingShifts = React.useMemo(
    () => shifts.filter(shift => shift.user_id === currentUserId),
    [shifts, currentUserId],
  );

  React.useEffect(() => {
    if (infoOpen) {
      const userId = asideList?.find(i => i.shift_id === infoOpen)?.user_id || -1;
      if (userId) {
        const user = users.find(innerUser => innerUser.id === userId);
        if (user) {
          setUserName(`${user.lastname} ${user.firstname?.charAt(0)?.toUpperCase()}.
            ${user.middlename?.charAt(0)?.toUpperCase()}.`);
        }
      }
    }
  }, [infoOpen]);

  if (user?.role_id === TYPE_ROLE.SUPERVISOR) {
    workingShiftsTypes
      .filter(item => userWorkingShifts[0]?.working_shift_id === item.id)
      .forEach(item => (preparedWorkingShifts[item.id] = item.name));
  } else {
    workingShiftsTypes.forEach(item => (preparedWorkingShifts[item.id] = item.name));
  }

  departments
    .filter(department => user?.departments_ids?.includes(department.id))
    .forEach(department => (preparedDepartments[department.id] = department.name));

  React.useEffect(() => {
    if (!workingShiftsTypes.map(shift => shift.id).includes(workShiftId)) {
      if (user?.role_id === TYPE_ROLE.SUPERVISOR) {
        dispatch(setWorkShiftId(userWorkingShifts[0]?.working_shift_id));
      } else {
        dispatch(
          setWorkShiftId(userWorkingShifts[0]?.working_shift_id ?? workingShiftsTypes[0]?.id),
        );
      }
    }
  }, [workingShiftsTypes, userWorkingShifts, user]);

  React.useEffect(() => {
    if (user?.departments_ids && (!departmentId || !user.departments_ids.includes(departmentId))) {
      dispatch(setDepartmentId(user?.departments_ids[0]));
    }
  }, [user]);

  React.useEffect(() => {
    if (selectedDay && departmentId && workShiftId) {
      setCenter(true);
      dispatch(
        getCommonMapData({
          date: prepareDateServerTime(selectedDay, companyOptions.time_zone),
          departmentsIds: [departmentId],
          workingShiftsIds: [workShiftId],
        }),
      );
    }

    if (selectedDay) {
      setInfoOpen(null);
    }
  }, [selectedDay, departmentId, workShiftId]);

  React.useEffect(() => {
    if (!mapLoading) {
      setCenter(false);
      setLoader(false);
    }
  }, [mapLoading]);

  const [ymaps, setYmaps] = React.useState<any>(null);

  const centeredMap = (map: ymaps.Map) => {
    const bounds = map?.geoObjects?.getBounds();

    if (bounds && !mapLoading && center) {
      map?.setBounds(bounds);
    }
  };

  return (
    <div className="layout-screen">
      <div className="layout-screen__contentMap">
        <div className="layout-screen__asideWrapper">
          <h1 className="layout-screen__title">
            {`${titlesMap.map}, `}
            {getTextOrToday(
              selectedDay,
              moment(selectedDay).format(FORMAT_MOMENT.DDMMM),
              companyOptions.time_zone,
            )}
            <Calendar />
          </h1>
          <div className="layout-screen__content" style={{ paddingBottom: 0 }}>
            <div className="layout-screen__selects">
              <SelectsField
                classNameWrapper="layout-screen__select"
                options={preparedWorkingShifts}
                onChange={(value: ISelectValue) => {
                  setInfoOpen(null);
                  dispatch(setWorkShiftId(value!.value));
                }}
                defaultValue={workShiftId}
                iconName={ICON_TYPE.WORKING_SHIFTS}
                placeholder={PLACEHOLDER.NONE}
                disabled={Object.values(preparedWorkingShifts).length <= 1}
              />
              <SelectsField
                classNameWrapper="layout-screen__select"
                options={preparedDepartments}
                onChange={(value: ISelectValue) => {
                  setInfoOpen(null);
                  dispatch(setDepartmentId(value!.value));
                }}
                defaultValue={departmentId}
                placeholder={PLACEHOLDER.NONE}
                disabled={Object.values(preparedDepartments).length <= 1}
              />
            </div>
          </div>
          <MapAside
            infoOpen={infoOpen}
            setInfoOpen={setInfoOpen}
            asideList={asideList}
            setCurrentTime={setCurrentTime}
          />
        </div>
        <div className="mapContainer">
          {loader && <div className="mapContainer__loader"></div>}
          <YMaps>
            <Map
              onLoad={ymaps => setYmaps(ymaps)}
              modules={['templateLayoutFactory']}
              state={{
                ...state,
                zoom: zoom,
              }}
              width="100%"
              height="100%"
              instanceRef={ref => {
                centeredMap(ref);
              }}
            >
              <MapZones setZoneName={setZoneName} />
              {infoOpen ? (
                <MapCoords shiftId={infoOpen} ymaps={ymaps} currentTime={currentTime} />
              ) : (
                asideList.map((item, key) => (
                  <MapPlacemark
                    key={key}
                    withUser={true}
                    ymaps={ymaps}
                    placeMark={item}
                    setInfoOpen={setInfoOpen}
                  />
                ))
              )}
            </Map>
          </YMaps>
          <div
            className={classNames('mapContainer__zoom', {
              ['mapContainer__zoom_infoOpen']: infoOpen,
            })}
          >
            <div onClick={() => setZoom(prev => prev + 1)}>
              <PlusSvg />
            </div>
            <div onClick={() => setZoom(prev => prev - 1)}>
              <MinusSvg />
            </div>
          </div>
          {!!zoneName && (
            <div className="mapContainer__department">
              <LocationSvg />
              {zoneName}
            </div>
          )}
          {infoOpen && (
            <MapControls
              userName={userName || ''}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              shiftId={infoOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MapScreen;
