import * as React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useOnClickOutside } from '~/hooks';
import { CalendarHeader } from './header';
import { CalendarTable } from './table';
import { Popover } from '~/components/popover';
import { setDate } from '~/redux/modules/stateModule';
import { FORMAT_MOMENT, BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import Button from '~/components/form/buttons/Button';

interface ICalendarProps {
  current?: string;
}

export const Calendar = ({
  current = moment().format(FORMAT_MOMENT.DASH_YYYYMMDD),
}: ICalendarProps) => {
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClose = React.useCallback(() => setOpen(false), []);
  const toggleOpen = React.useCallback(() => setOpen(v => !v), []);
  useOnClickOutside(ref, handleClose);

  const [selectedMonth, setSelectedMonth] = React.useState<number>(
    moment(current).startOf('month').unix(),
  );
  const [selectedYear, setSelectedYear] = React.useState<number>(
    moment(current).startOf('year').unix(),
  );
  const month = moment.unix(selectedMonth).format(FORMAT_MOMENT.MONTH_MM);
  const handlePrev = React.useCallback(() => {
    setSelectedMonth(v => moment.unix(v).subtract(1, 'month').startOf('month').unix());

    if (month === '01') {
      setSelectedYear(v => moment.unix(v).subtract(1, 'year').startOf('year').unix());
    }
  }, [selectedMonth]);
  const handleNext = React.useCallback(() => {
    setSelectedMonth(v => moment.unix(v).add(1, 'month').startOf('month').unix());

    if (month === '12') {
      setSelectedYear(v => moment.unix(v).add(1, 'year').startOf('year').unix());
    }
  }, [selectedMonth]);

  const handleClickDay = React.useCallback((value: number) => {
    dispatch(setDate(moment.unix(value).format(FORMAT_MOMENT.DASH_YYYYMMDD)));
    handleClose();
  }, []);

  React.useEffect(() => {
    if (!open) {
      setSelectedMonth(moment(current).unix());
    }
  }, [open, current]);

  return (
    <div ref={ref} className="calendar__content">
      <Button
        type={BUTTON_TYPE.ICON}
        onClick={toggleOpen}
        className="calendar"
        icon
        iconType={ICON_TYPE.CALENDAR}
      />
      <Popover show={open} anchorEl={ref.current} anchorPosition="right">
        <div style={{ width: 300, padding: '30px 31px 37px' }}>
          <CalendarHeader
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            onPressNext={handleNext}
            onPressPrev={handlePrev}
          />
          <CalendarTable
            month={selectedMonth}
            onClickDay={handleClickDay}
            selected={moment(current).unix()}
          />
        </div>
      </Popover>
    </div>
  );
};
