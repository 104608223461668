import declOfNum from '~/helpers/declOfNum';
import { DECLINATION_TYPE } from '~/helpers/constants';

const textDeclension = (item: number, type: DECLINATION_TYPE) => {
  switch (type) {
    case DECLINATION_TYPE.DAYS:
      return item + declOfNum(item, [' день', ' дня', ' дней']);
    case DECLINATION_TYPE.HOURS:
      return item + declOfNum(item, [' час', ' часа', ' часов']);
    case DECLINATION_TYPE.MINUTES:
      return item + declOfNum(item, [' минута', ' минуты', ' минут']);
    case DECLINATION_TYPE.PERFORMERS:
      return item + declOfNum(item, [' Исполнитель', ' Исполнителя', ' Исполнителей']);
    case DECLINATION_TYPE.TASKS:
      return item + declOfNum(item, [' Задача', ' Задачи', ' Задач']);
    default:
      return '';
  }
};

export default textDeclension;
