import { INewDepartment } from 'sb_manufacturing_front_api';
import { createEntityAdapter, createSlice, Dictionary, EntityId } from '@reduxjs/toolkit';

export const departmentsAdapter = createEntityAdapter<INewDepartment>({
  selectId: zone => zone.id,
});

export interface IDepartmentsState {
  ids: EntityId[];
  entities: Dictionary<INewDepartment>;
}

const departmentsSlice = createSlice({
  name: 'departments',
  initialState: departmentsAdapter.getInitialState(),
  reducers: {
    create: departmentsAdapter.setMany,
    update: departmentsAdapter.upsertMany,
    remove: departmentsAdapter.removeMany,
    clear: departmentsAdapter.removeAll,
  },
});

export default departmentsSlice.reducer;

export const { create, update, remove, clear } = departmentsSlice.actions;
