import React from 'react';
import { Performers } from '~/containers/tasks/task/Performers';
import { ReactComponent as BasketSVG } from '~/assets/tasks-no-time/basket.svg';
import { ReactComponent as EditSVG } from '~/assets/tasks-no-time/edit.svg';
import { ReactComponent as ViewSVG } from '~/assets/tasks-no-time/view.svg';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserRole } from '~/redux/selectors/authSelectors';
import { ITaskTimeless } from 'sb_manufacturing_front_api';
import { TYPE_ROLE, DECLINATION_TYPE, FORMAT_MOMENT, ALERT, ALERT_TYPE } from '~/helpers/constants';
import { selectUsers } from '~/redux/selectors/usersSelectors';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { getLocalTimeFormat } from '~/helpers/convertToUnix';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import moment from 'moment';
import textDeclension from '~/helpers/textDeclension';
import { showAlert } from '~/redux/modules/stateModule';
import Priority from '~/components/priority/Priority';

interface ITaskNoTime {
  task: ITaskTimeless;
}

export const TaskNoTime: React.FC<ITaskNoTime> = ({ task }) => {
  const dispatch = useDispatch();

  const companyOptions = useSelector(selectCompanyOptions);
  const userRole = useSelector(selectUserRole);
  const usersAll = useSelector(selectUsers);

  const startDate = getLocalTimeFormat(
    task?.start_date,
    companyOptions?.time_zone,
    FORMAT_MOMENT.DDMMYYYY,
  );
  const endDate = getLocalTimeFormat(
    task?.end_date,
    companyOptions?.time_zone,
    FORMAT_MOMENT.DDMMYYYY,
  );
  const isSame = moment(task?.start_date).isSame(task?.end_date);

  const hours: number = task?.duration ? Math.floor(task?.duration / 60 / 60) : 0;
  const minutes: number = task?.duration ? Math.floor(task?.duration / 60) - hours * 60 : 0;
  const usersTask: number[] = task.tasks.map(item => item.user_id);
  const performers = usersAll.filter(user => usersTask.includes(user.id));

  const handelRemove = (id: number, title: string) => {
    const data = {
      title: 'Удалить задачу',
      description: `Вы действительно хотите удалить задачу “${title}” (свободная задача)?`,
      name: ALERT.REMOVE_TASK_TIMELESS,
      type: ALERT_TYPE.REMOVE,
      data: {
        taskId: id,
      },
    };
    dispatch(showAlert(data));
  };

  const handelView = () => {
    dispatch(openPopup({ name: POPUPS_NAME.VIEW_TASK_POPUP, data: { task: task } }));
  };

  const handelEdite = () => {
    dispatch(
      openPopup({
        name: POPUPS_NAME.EDITE_TASK_POPUP,
        data: {
          task: {
            ...task,
            geo: task?.geo ? task.geo.map(geo => (geo?.id ? geo.id : geo)) : [],
          },
          type: 1,
        },
      }),
    );
  };
  return (
    <>
      <div className="task-no-time">
        {[TYPE_ROLE.SUPERVISOR, TYPE_ROLE.DEPARTMENT].includes(userRole) ? (
          <div className="task-no-time__controls">
            <div className="task-no-time__control" onClick={handelEdite}>
              <EditSVG />
            </div>
            <div
              className="task-no-time__control"
              onClick={() => handelRemove(task.id, task.title)}
            >
              <BasketSVG />
            </div>
            <div className="task-no-time__control" onClick={handelView}>
              <ViewSVG />
            </div>
          </div>
        ) : null}
        <div className="task-no-time__header">
          <Priority id={task?.priority} />
          <div className="task-no-time__time">
            {hours ? `${textDeclension(hours, DECLINATION_TYPE.HOURS)}` : null}
            {minutes ? ` ${textDeclension(minutes, DECLINATION_TYPE.MINUTES)}` : null}
          </div>
        </div>
        <div className="task-no-time__title">{task?.title}</div>
        <div className="task-no-time__block">
          <div className="task-no-time__block-item">
            <Performers users={performers} titleShow={true} titleName="Исполнители" />
          </div>
          <div className="task-no-time__block-item">
            <div className="task-no-time__block-title">Период</div>
            <div className="task-no-time__period">
              {isSame ? startDate : `${startDate} – ${endDate}`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
