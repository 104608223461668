import classNames from 'classnames';

interface ILegends {
  className?: string;
  items: {
    title: string;
    titleColor: string;
    circleColor: string;
    total?: string;
    percent?: string;
  }[];
}

const Legends = ({ items, className }: ILegends) => {
  return (
    <div className={classNames('legends', className)}>
      {items.map(item => (
        <div className="legends__item">
          <span className="legends__itemCircle" style={{ backgroundColor: item.circleColor }} />
          <div className="legends__itemContent">
            <p className="legends__itemTitle" style={{ color: item.titleColor }}>
              {item.title}
            </p>
            {!!item.total && !!item.percent && (
              <div className="legends__itemInfo">
                <p>{item.total}</p>
                <span>{item.percent}</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Legends;
