import * as React from 'react';
import { ReactComponent as CheckboxSvg } from '~/assets/checkbox.svg';
import classNames from 'classnames';

interface ICheckbox {
  name?: string;
  defaultValue?: boolean;
  onChange?: (value: boolean, name: string) => void;
  label: string;
  disabled?: boolean;
  className?: string;
}

const CheckboxesField = ({
  defaultValue,
  onChange,
  label,
  disabled,
  name,
  className,
}: ICheckbox) => {
  const id = React.useRef(Math.random().toString(36).substring(2)).current;

  return (
    <label
      className={classNames('new-checkbox', className, {
        ['disabled']: disabled,
      })}
      htmlFor={id}
    >
      <input
        id={id}
        name={name}
        type="checkbox"
        className="checkbox__input"
        checked={defaultValue}
        disabled={disabled}
        onChange={event =>
          onChange && onChange(event.currentTarget.checked, event.currentTarget.name)
        }
      />
      <span className="checkbox__check">
        <CheckboxSvg />
      </span>
      <span className="checkbox__text">{label}</span>
    </label>
  );
};

export default CheckboxesField;
