import * as React from 'react';
import cx from 'classnames';

import { ReactComponent as ArrowDownSvg } from '~/assets/arrow-down.svg';

interface ISpoiler {
  title: string;
  open?: boolean;
}

export const Spoiler: React.FC<ISpoiler> = ({ title, open, children }) => {
  const [_open, setOpen] = React.useState(open);
  const handleToggle = React.useCallback(() => {
    setOpen(v => !v);
  }, []);

  React.useEffect(() => {
    if (open === true) {
      setOpen(open);
    }
  }, [open]);

  return (
    <div className={cx('spoiler', { spoiler_active: _open })}>
      <button className="spoiler__header" onClick={handleToggle} type="button">
        {title}
        <ArrowDownSvg className="spoiler__arrow" />
      </button>
      <div className="spoiler__body">{children}</div>
    </div>
  );
};
