import moment from 'moment';
import { EShiftType } from 'sb_manufacturing_front_api';
import { FORMAT_MOMENT } from '~/helpers/constants';

export const noop = () => {};

export const HOST = process.env.REACT_APP_API_IMAGE_URL!;

export const diffDateDaysNow = (dateStart: moment.Moment | string) => {
  return moment().diff(dateStart, 'days');
};

export const checkPasswordRegExp = (password: string) => {
  return !!password.match(
    `^(?=.{8,})(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!#$%*+& -./:;<=>?@^_{|}])`,
  );
};

export const generatePassword = (secure: boolean) => {
  const charsArray = ['abcdefghijklmnopqrstuvwxyz', '0123456789', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

  if (secure) {
    charsArray.push('!#$%*+-./:;<=>?@^_{|}');
  }
  let password = '';

  charsArray.forEach(chars => {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  });

  for (let i = 0; i < 4; i++) {
    const randomNumber = Math.floor(Math.random() * charsArray.join('').length);
    password += charsArray.join('').substring(randomNumber, randomNumber + 1);
  }

  return password;
};

export const getWorkShiftTextStatus = (date: string, type?: number) => {
  if (type === undefined) return `Выходной, ${moment(date).format(FORMAT_MOMENT.DMMMM)}`;

  const shiftDate = moment(date).format(FORMAT_MOMENT.DMMMM);

  return {
    [EShiftType.DEFAULT]: `Смена, ${shiftDate}`,
    [EShiftType.ADDITIONAL]: `Доп.смена, ${shiftDate}`,
    [EShiftType.ACCEPTED]: `Смена, ${shiftDate}`,
    [EShiftType.CANCELED]: `Отмена смены, ${shiftDate}`,
  }[type];
};
