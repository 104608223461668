import { CSSObjectWithLabel, GroupBase, OptionProps } from 'react-select';
import { ICON_TYPE, COLORS } from '~/helpers/constants';

type IStyle = CSSObjectWithLabel;
type IOptionState = OptionProps<
  {
    value: string;
    label: string;
    disabled?: boolean;
  },
  false,
  GroupBase<{ value: string; label: string; disabled?: boolean }>
>;

const getColor = (state: IOptionState, typeColor?: string) => {
  switch (typeColor) {
    case ICON_TYPE.PRIORITY:
      if (state.data.value === '0') {
        return COLORS.BLUE;
      }

      if (state.data.value === '1') {
        return COLORS.ORANGE;
      }

      if (state.data.value === '2') {
        return COLORS.RED;
      }

      return COLORS.BLUE;
    default:
      return COLORS.DARK;
  }
};

export const customStyles = (disabled?: boolean, error?: boolean, typeColor?: string) => {
  return {
    control: (state: IOptionState) => ({
      height: '100%',
      display: 'flex',
      backgroundColor: state.isFocused ? COLORS.GREY_LIGHT100 : COLORS.GREY_LIGHT300,
      borderRadius: '4px',
      outline: error ? `1px solid ${COLORS.PINK}` : '',
    }),
    valueContainer: (baseStyles: IStyle) => ({
      ...baseStyles,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400',
      padding: '12px 16px 12px 16px',
      justifyContent: 'flex-start',
      display: 'flex',
      flexWrap: 'none',
      columnGap: '6px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    singleValue: (baseStyles: IStyle, state: IOptionState) => ({
      ...baseStyles,
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      color: getColor(state, typeColor),
      margin: '0',
    }),
    input: (baseStyles: IStyle) => ({
      ...baseStyles,
      margin: '0',
      padding: '0',
      cursor: 'text',
    }),
    indicatorsContainer: (baseStyles: IStyle) => ({
      ...baseStyles,
      padding: '12px 12px 12px 0',
      display: disabled ? 'none;' : 'block;',
    }),
    clearIndicator: (baseStyles: IStyle) => ({
      ...baseStyles,
      position: 'absolute',
      right: '9px',
      top: '50%',
      transform: 'translateY(-50%)',
    }),
    indicatorSeparator: () => ({
      padding: 0,
    }),
    menu: (baseStyles: IStyle) => ({
      ...baseStyles,
      boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.20);',
      border: 'none',
      borderRadius: '4px',
      borderTop: 'none',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400',
      top: '48px',
      right: '0',
      width: 'max-content',
      minWidth: '100%',
      maxWidth: '280px',
      backgroundColor: COLORS.WHITE,
      zIndex: '99',
    }),
    option: (baseStyles: IStyle, state: IOptionState) => ({
      ...baseStyles,
      display: 'flex',
      alignItems: 'center',
      color: getColor(state, typeColor),
      padding: '8px 16px',
      width: '100%',
      backgroundColor: state.isSelected
        ? COLORS.GREY_LIGHT200
        : state.isFocused
        ? COLORS.GREY_LIGHT300
        : 'inherit',
      ':active': {
        backgroundColor: COLORS.GREY_LIGHT200,
      },
    }),
    placeholder: (baseStyles: IStyle) => ({
      ...baseStyles,
      color: COLORS.GREY,
      margin: 0,
    }),
    dropdownIndicator: (baseStyles: IStyle) => ({
      ...baseStyles,
      padding: 0,
    }),
    multiValue: () => ({
      display: 'none',
    }),
  };
};
