import moment, { Moment } from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';

const getTextOrToday = (date: string, text: string, timeZone: number, isOnlyText?: boolean) => {
  const tomorrowDate: Moment = moment().add(1, 'day').utc().utcOffset(timeZone);
  const yesterdayDate: Moment = moment().subtract(1, 'day').utc().utcOffset(timeZone);
  const isTomorrow =
    moment(date).format(FORMAT_MOMENT.DASH_YYYYMMDD) ===
    tomorrowDate.format(FORMAT_MOMENT.DASH_YYYYMMDD);
  const isToday =
    moment(date).format(FORMAT_MOMENT.DASH_YYYYMMDD) ===
    moment().utc().utcOffset(timeZone).format(FORMAT_MOMENT.DASH_YYYYMMDD);
  const isYesterday =
    yesterdayDate.format(FORMAT_MOMENT.DASH_YYYYMMDD) ===
    moment(date).format(FORMAT_MOMENT.DASH_YYYYMMDD);

  if (isYesterday) {
    return isOnlyText ? `Вчера` : `Вчера, ${text}`;
  } else if (isToday) {
    return isOnlyText ? `Сегодня` : `Сегодня, ${text}`;
  } else if (isTomorrow) {
    return isOnlyText ? `Завтра` : `Завтра, ${text}`;
  } else {
    return text;
  }
};

export default getTextOrToday;
