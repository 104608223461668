import * as React from 'react';
import moment from 'moment';
import Button from '~/components/form/buttons/Button';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';

interface ICalendarHeader {
  selectedMonth: number;
  selectedYear: number;
  onPressNext: () => void;
  onPressPrev: () => void;
}

export const CalendarHeader = ({
  selectedMonth,
  selectedYear,
  onPressNext,
  onPressPrev,
}: ICalendarHeader) => {
  const monthStr = React.useMemo(() => moment.unix(selectedMonth).format('MMMM').firstToUpper(), [
    selectedMonth,
  ]);
  const yearStr = React.useMemo(() => moment.unix(selectedYear).format('YYYY').firstToUpper(), [
    selectedYear,
  ]);

  return (
    <header className="calendar__header">
      <h3 className="calendar__month">{monthStr}</h3>
      <h3>{yearStr}</h3>
      <nav className="calendar__nav">
        <Button
          type={BUTTON_TYPE.ICON}
          onClick={onPressPrev}
          className="calendar-prev-small"
          icon
          iconType={ICON_TYPE.ARROW_PREV}
        />
        <Button
          type={BUTTON_TYPE.ICON}
          onClick={onPressNext}
          className="calendar-next-small"
          icon
          iconType={ICON_TYPE.ARROW_NEXT}
        />
      </nav>
    </header>
  );
};
