import * as React from 'react';
import LockImage from '~/assets/lock.svg';
import AddedUsers from '~/components/form/users/added-users';

interface IUsersField {
  value?: number[];
  onChange?: (id: number) => void;
  onGoToSearch: (value?: boolean) => void;
  readonly?: boolean;
  isTemplate?: boolean;
}

const UsersField = ({ value, onGoToSearch, onChange, readonly, isTemplate }: IUsersField) => {
  const handleUserRemove = React.useCallback(
    (id: number) => {
      onChange &&
        // @ts-ignore
        onChange(id);
    },
    [value, onChange],
  );

  return (
    <div className={`add-task__users ${isTemplate ? 'add-task__users--disable' : ''}`}>
      <h3 className="time-inputs__title">Исполнители</h3>
      <div className="add-task__users-block">
        <AddedUsers
          users={value || []}
          onRemove={handleUserRemove}
          onClickAdd={value => onGoToSearch(value)}
          readonly={readonly}
        />
      </div>
      <div className="add-task__users-lock">
        <div className="add-task__users-lock-icon">
          <img className="add-task__users-lock-image" src={LockImage} alt="" />
        </div>
        <span className="add-task__users-lock-text">
          Добавление исполнителей недоступно для шаблона
        </span>
      </div>
    </div>
  );
};

export default UsersField;
