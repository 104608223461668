import React from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import Routing from '~/Routing';

const App = () => {
  const location = useLocation();

  return (
    <div className={cx('layout', { no_padding: location.pathname === urlsMap.login })}>
      <Routing />
    </div>
  );
};

export default App;
