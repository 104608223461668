import * as React from 'react';
import styles from './Controls.module.scss';
import moment from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';
import { Slider } from 'antd';
import { useAppSelector } from '~/hooks/redux';
import { selectMapShift } from '~/redux/selectors/mapSelectors';

interface IFC {
  userName: string;
  currentTime: string | null;
  setCurrentTime: (value: string | null) => void;
  shiftId: number;
}

const MapControls = ({ userName, currentTime, setCurrentTime, shiftId }: IFC) => {
  const mapShift = useAppSelector(selectMapShift(shiftId || -1));
  const startTime =
    moment(mapShift?.shift?.start_time)
      .subtract(1, 'hours')
      .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS) || '';
  const endTime =
    moment(mapShift?.shift?.end_time).add(1, 'hours').format(FORMAT_MOMENT.YYYYMMDD_HHMMSS) ||
    moment().add(1, 'hours').format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
  const hours = Math.abs(moment(startTime).diff(moment(endTime), 'hours'));
  const otrezki: string[] = [];

  for (let i = 0; i < hours + 1; i++) {
    otrezki.push(
      moment(startTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS)
        .add(i, 'hours')
        .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    );
  }

  const [time, setTime] = React.useState(otrezki[1]);
  const [value, setValue] = React.useState(1);

  const changeHandler = (value: number) => {
    const split = String(value).split('.');
    const t = moment(
      otrezki[Number(split[0])].split(':')[0] + ':' + (Number(split[1]) * 0.6).toFixed(),
      FORMAT_MOMENT.YYYYMMDD_HHMMSS,
    ).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS);
    setTime(t);
    setCurrentTime(t);
  };

  React.useEffect(() => {
    if (currentTime) {
      setTime(currentTime);

      const index = otrezki
        .map(time => moment(time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).format('H'))
        .findIndex(num => num === moment(currentTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS).format('H'));

      setValue(
        Number(
          index +
            '.' +
            (
              Number(moment(currentTime, FORMAT_MOMENT.YYYYMMDD_HHMMSS).format('mm')) * 1.6
            ).toFixed(),
        ),
      );
    }
  }, [currentTime]);

  React.useEffect(() => {
    setCurrentTime(moment(mapShift?.shift?.start_time).format(FORMAT_MOMENT.YYYYMMDD_HHMMSS));
  }, [mapShift?.shift?.start_time]);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        {moment(time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).format(FORMAT_MOMENT.HHMM)} · {userName}
      </div>
      <div className={styles.rangeWrapper}>
        <div className={styles.rangeContainer}>
          <div className={styles.containerLeft} style={{ width: 100 / otrezki.length + '%' }}></div>
          <div className={styles.range}>
            <Slider
              min={0}
              defaultValue={1}
              max={otrezki.length - 1}
              step={0.01}
              value={value}
              onChange={changeHandler}
              tooltipVisible={false}
              trackStyle={{ height: '8px', backgroundColor: '#2F80ED' }}
              handleStyle={{
                width: '16px',
                height: '16px',
                backgroundColor: '#2F80ED',
                border: 'none',
                zIndex: 3,
              }}
            />
          </div>
          <div
            className={styles.containerRight}
            style={{ width: 100 / otrezki.length + '%' }}
          ></div>
        </div>
        <div className={styles.time}>
          {otrezki.map((time, key) => (
            <span key={key} style={{ width: 100 / otrezki.length + '%' }}>
              {moment(time, FORMAT_MOMENT.YYYYMMDD_HHMMSS).format(FORMAT_MOMENT.HHMM)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MapControls;
