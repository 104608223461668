import * as React from 'react';
import ChartLine from '~/components/statistic/ChartLine';
import ChartDoughnut from '~/components/statistic/ChartDoughnut';
import { ReactComponent as CaretSvg } from '~/assets/caret.svg';
import ChartTasks from '~/components/statistic/ChartTasks';
import { ETaskGeoStatus, ETaskStatus, INewTask, ITaskTrack } from 'sb_manufacturing_front_api';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from '~/redux/selectors/usersSelectors';
import moment from 'moment';
import { selectTasksShift } from '~/redux/selectors/tasksSelectors';
import { useOnClickOutside } from '~/hooks';
import textDeclension from '~/helpers/textDeclension';
import { openPopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';
import { COLORS, DECLINATION_TYPE, FORMAT_MOMENT, PLACEHOLDER } from '~/helpers/constants';
import convertTimeHM from '~/helpers/convertTimeHM';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';
import { titlesMap } from '~/utils/titles';
import SelectsField from '~/components/form/select/Select';
import { selectCurrentShifts } from '~/redux/selectors/shiftsSelectors';
import { selectDepartmentIds } from '~/redux/selectors/stateSelectors';
import containsArray from '~/helpers/containsArray';

const calculateTimePlaned = (usersTasks: INewTask[]) => {
  return usersTasks.reduce((acc, item: INewTask) => {
    if (item.end_time && item.begin_time) {
      const diff = moment(item.end_time).diff(item.begin_time);
      acc += diff;
    }
    return acc;
  }, 0);
};

const calculateTimeTracks = (tracks: ITaskTrack[]) => {
  return tracks.reduce((acc, item) => {
    const diff = item.end_time
      ? moment(item.end_time).diff(item.start_time)
      : moment.utc().diff(moment.utc(item.start_time));
    acc += diff;
    return acc;
  }, 0);
};

const timeCalculate = (tasks: INewTask[], planed: boolean) => {
  let time = 0;

  if (planed) {
    time += calculateTimePlaned(tasks);
  } else {
    tasks.forEach(item => {
      if (item.tracks?.length) {
        time += calculateTimeTracks(item.tracks);
      }
    });
  }

  return convertTimeHM(time, true);
};

const TasksStatistic = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const usersList = useSelector(selectUsers);
  const departmentIds = useSelector(selectDepartmentIds);
  const shifts = useSelector(selectCurrentShifts);
  const tasks = useSelector(selectTasksShift);
  const useGeo = useSelector(selectCompanyOptions).use_geo;

  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number>(1);

  useOnClickOutside(ref, () => setOpen(false));

  const usersShifts = shifts.filter(shift => {
    const shiftUser = usersList.find(user => user.id === shift.user_id);

    return shiftUser && containsArray(departmentIds, shiftUser?.departments_ids || []);
  });

  const usersTasks = React.useMemo(() => {
    return tasks
      .map(task => {
        return {
          order: usersShifts.findIndex(
            shift => shift?.id === task?.shift_id && shift?.user_id === task?.user_id,
          ),
          ...task,
        };
      })
      .filter(v => v.order !== -1);
  }, [tasks, usersShifts]);

  const hoursShift = moment(usersShifts[0]?.end_time).diff(
    moment(usersShifts[0]?.start_time),
    'hours',
  );

  const filteredShiftsDurations: number[] = usersShifts.map(shift => {
    return moment(shift?.end_time).diff(moment(shift?.start_time), 'hours');
  });

  const hoursAllShift = filteredShiftsDurations.length
    ? filteredShiftsDurations.reduce((sum: number, shiftDuration: number): number => {
        return sum + shiftDuration;
      })
    : 0;

  const textPerformers = textDeclension(usersShifts.length, DECLINATION_TYPE.PERFORMERS);

  const timeAllPlaned = calculateTimePlaned(usersTasks);

  let timeAllTracks = 0;
  usersTasks.forEach(item => {
    if (item.tracks?.length) {
      timeAllTracks += calculateTimeTracks(item.tracks);
    }
  });

  const timePlaned = convertTimeHM(timeAllPlaned, true);
  const timeTracks = convertTimeHM(timeAllTracks, true);

  const timeFact = (timeTracks.number / timePlaned.number) * 100 || 0.01;
  const timeLeft = convertTimeHM(hoursAllShift * 1000 * 60 * 60 - timeAllPlaned, true);
  const timeUnused = convertTimeHM(timeAllPlaned - timeAllTracks, true);

  const timeUnusedPercent = (timeUnused.number / hoursAllShift) * 100 || 0.01;
  const timeLeftPercent = (timeLeft.number / hoursAllShift) * 100 || 0.01;
  const timeTracksPercent = (timeTracks.number / hoursAllShift) * 100 || 0.01;

  const tasksPlaned = usersTasks.filter(
    item => item.status === ETaskStatus.E_DEFAULT && !item.tracks?.length,
  );

  const tasksWorked = usersTasks.filter(
    item => item.tracks?.length || item.status === ETaskStatus.E_COMPLETE,
  );
  const tasksGeo = usersTasks.filter(
    item => item.geo_status === ETaskGeoStatus.FAIL && item.tracks?.length,
  );
  const tasksExpired = usersTasks.filter(item => item.is_expired && item.tracks?.length);

  const tasksTimePlaned = timeCalculate(tasksPlaned, true);
  const tasksTimeWorked = timeCalculate(tasksWorked, false);
  const tasksTimeGeo = timeCalculate(tasksGeo, false);
  const tasksTimeExpired = timeCalculate(tasksExpired, false);

  const dataFirst = {
    datasets: [
      {
        data: [
          Number(timeTracksPercent.toFixed(0)),
          Number(timeUnusedPercent.toFixed(0)),
          Number(timeLeftPercent.toFixed(0)),
        ],
        backgroundColor: [COLORS.BLUE, COLORS.BLUE_LIGHT, COLORS.GREY_LIGHT],
        borderWidth: 0,
      },
    ],
  };

  const legendsFirst = [
    {
      title: 'Не запланировано',
      titleColor: COLORS.GREY,
      circleColor: COLORS.GREY_LIGHT,
      total: timeLeft.text,
      percent: `${timeLeftPercent.toFixed(0)}%`,
    },
    {
      title: 'Не отработано',
      titleColor: COLORS.GREY,
      circleColor: COLORS.BLUE_LIGHT,
      total: timeUnused.text,
      percent: `${timeUnusedPercent >= 0 ? timeUnusedPercent.toFixed(0) : 0}%`,
    },
    {
      title: 'Отработано',
      titleColor: COLORS.GREY,
      circleColor: COLORS.BLUE,
      total: timeTracks.text,
      percent: `${timeTracksPercent.toFixed(0)}%`,
    },
  ];

  const dataLast = {
    datasets: [
      {
        data: [Number(timeFact.toFixed(0)), 100 - Number(timeFact.toFixed(0))],
        backgroundColor: [COLORS.BLUE, COLORS.BLUE_LIGHT],
        borderWidth: 0,
      },
    ],
  };

  const legendsLast = [
    {
      title: 'По плану',
      titleColor: COLORS.GREY,
      circleColor: COLORS.BLUE_LIGHT,
      total: timePlaned.text,
      percent: '100%',
    },
    {
      title: 'Отработано',
      titleColor: COLORS.GREY,
      circleColor: COLORS.BLUE,
      total: timeTracks.text,
      percent: `${timeFact.toFixed(0)}%`,
    },
  ];

  const preparedOptions: ISelectOption = {
    1: 'Список «Назначено»',
    2: 'Список «Отработано»',
    3: 'Список «С откл. по времени»',
  };

  if (useGeo) {
    preparedOptions[4] = 'Список «С откл. по гео»';
  }

  const userTasks = () => {
    return usersShifts?.map(itemU => {
      const user = usersList.find(user => user.id === itemU.user_id);

      let userWorked = 0;
      usersTasks.forEach(item => {
        if (item.user_id === user?.id && item.tracks) {
          userWorked += calculateTimeTracks(item.tracks);
        }
      });

      const userName =
        user?.lastname +
        ' ' +
        user?.firstname.charAt(0).toUpperCase() +
        '. ' +
        (!!user?.middlename ? user?.middlename.charAt(0).toUpperCase() : '');

      const timeWorked = convertTimeHM(userWorked, true);

      const timeWorkedPercent = timeWorked.number / hoursShift || 0;

      return {
        userName,
        timeWorkedPercent,
        timeWorked,
      };
    });
  };

  const GenerateContent = () => {
    const Task = (task: INewTask) => {
      const user = usersList.find(user => user.id === task.user_id);
      const userName =
        user?.lastname +
        ' ' +
        user?.firstname.charAt(0).toUpperCase() +
        '. ' +
        (!!user?.middlename ? user?.middlename.charAt(0).toUpperCase() : '');

      const handleClick = () =>
        dispatch(openPopup({ name: POPUPS_NAME.VIEW_TASK_POPUP, data: { task: task } }));

      return (
        <div className="tasksStatistic__tasksListItem">
          <div className="tasksStatistic__tasksListName">{userName}</div>
          <div className="tasksStatistic__tasksListTask" onClick={handleClick}>
            {task.title}
          </div>
        </div>
      );
    };

    switch (value) {
      case 1:
        return {
          tasks: tasksPlaned.map(item => Task(item)),
          noTasks: tasksPlaned.length,
          text: `${tasksTimePlaned.text} (${textDeclension(
            tasksPlaned.length,
            DECLINATION_TYPE.TASKS,
          )})`,
        };
      case 2:
        return {
          tasks: tasksWorked.map(item => Task(item)),
          noTasks: tasksWorked.length,
          text: `${tasksTimeWorked.text} (${textDeclension(
            tasksWorked.length,
            DECLINATION_TYPE.TASKS,
          )})`,
        };
      case 3:
        return {
          tasks: tasksExpired.map(item => Task(item)),
          noTasks: tasksExpired.length,
          text: `${tasksTimeExpired.text} (${textDeclension(
            tasksExpired.length,
            DECLINATION_TYPE.TASKS,
          )})`,
        };
      case 4:
        return {
          tasks: tasksGeo.map(item => Task(item)),
          noTasks: tasksGeo.length,
          text: `${tasksTimeGeo.text} (${textDeclension(tasksGeo.length, DECLINATION_TYPE.TASKS)})`,
        };
    }
  };

  const renderContent = GenerateContent();

  return (
    <div className="tasksStatistic">
      <h2 className="tasksStatistic__title">{titlesMap.statistics}</h2>
      <div className="tasksStatistic__wrapper">
        <div className="tasksStatistic__inner">
          <div className="tasksStatistic__flex">
            <div className="tasksStatistic__subtitle">Загрузка смены</div>
            <div className="tasksStatistic__users" ref={ref}>
              <div className="tasksStatistic__btn" onClick={() => setOpen(!open)}>
                <p>{textPerformers}</p>
                <CaretSvg />
              </div>
              {open && (
                <div className="tasksStatistic__popup">
                  <div className="tasksStatistic__popupHeader">
                    <div className="tasksStatistic__popupTitle">{textPerformers}</div>
                    <div className="tasksStatistic__popupTotal">
                      {timePlaned.text} / {hoursAllShift} ч
                    </div>
                  </div>
                  <div className="tasksStatistic__popupWrapper">
                    <div className="tasksStatistic__popupInner">
                      {userTasks().map((item, key) => (
                        <div className="tasksStatistic__popupItem" key={key}>
                          <div className="tasksStatistic__popupItemName">{item.userName}</div>
                          <div className="tasksStatistic__popupItemInfo">
                            <div className="tasksStatistic__popupItemTotal">
                              {item.timeWorked.text} / {hoursShift} ч
                            </div>
                            <div
                              className="tasksStatistic__popupItemCircle after"
                              // @ts-ignore
                              style={{ '--fillPercent': item.timeWorkedPercent }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ChartLine
            hoursMax={hoursAllShift}
            hoursPlan={timePlaned.number}
            textPlan={timePlaned.text}
          />
          <div className="tasksStatistic__doughnut">
            <div className="tasksStatistic__doughnutItem">
              <ChartDoughnut
                title="Соотношение часов в смене"
                data={dataFirst}
                legends={legendsFirst}
              />
            </div>
            <div className="tasksStatistic__doughnutItem">
              <ChartDoughnut
                title="Выполнение плана"
                data={dataLast}
                legends={legendsLast}
                total={`${timeFact.toFixed(0)}%`}
              />
            </div>
          </div>
        </div>
        <div className="tasksStatistic__inner">
          <div className="tasksStatistic__wrapper">
            <div className="tasksStatistic__tasks">
              <div className="tasksStatistic__subtitle">Задачи</div>
              <ChartTasks
                tasksPlaned={tasksPlaned}
                tasksWorked={tasksWorked}
                tasksExpired={tasksExpired}
                tasksGeo={tasksGeo}
                time={timePlaned.number}
                timePlaned={tasksTimePlaned}
                timeWorked={tasksTimeWorked}
                timeGeo={tasksTimeGeo}
                timeExpired={tasksTimeExpired}
              />
            </div>
            <div className="tasksStatistic__tasksList">
              <div className="tasksStatistic__flex">
                <SelectsField
                  options={preparedOptions}
                  onChange={(value: ISelectValue) => setValue(Number(value!.value))}
                  defaultValue={value}
                  placeholder={PLACEHOLDER.NONE}
                />
                <div className="tasksStatistic__tasksListTotal">{renderContent?.text}</div>
              </div>
              <div className="tasksStatistic__tasksListContent">
                <div className="tasksStatistic__tasksListHeader">
                  <div className="tasksStatistic__tasksListName">Исполнитель</div>
                  <div className="tasksStatistic__tasksListTask">Задача</div>
                </div>
                <div className="tasksStatistic__tasksListWrapper">
                  <div className="tasksStatistic__tasksListInner">
                    {renderContent?.noTasks ? (
                      renderContent?.tasks
                    ) : (
                      <div className="tasksStatistic__noTasks">Задач с таким статусом нет</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasksStatistic;
