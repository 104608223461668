import * as React from 'react';
import { useAppSelector } from './redux';
import { TYPE_ROLE } from '~/helpers/constants';

export const useUserFilter = () => {
  const { usersFilters } = useAppSelector(state => state.usersFilters);

  const professionFilters = React.useMemo(
    () => usersFilters.find(filter => filter.name === 'profession'),
    [usersFilters],
  );

  const departmentsFilters = React.useMemo(
    () => usersFilters.find(filter => filter.name === TYPE_ROLE.DEPARTMENT),
    [usersFilters],
  );

  const categoryFilters = React.useMemo(
    () => usersFilters.find(filter => filter.name === 'category'),
    [usersFilters],
  );

  const departments_ids = React.useMemo(
    () => departmentsFilters?.data.filter(check => check.check).map(data => data.id),
    [departmentsFilters],
  );

  const categories_ids = React.useMemo(
    () => categoryFilters?.data.filter(check => check.check).map(data => data.id),
    [categoryFilters],
  );

  const professions_ids = React.useMemo(
    () => professionFilters?.data.filter(check => check.check).map(data => data.id),
    [professionFilters],
  );

  return {
    departments_ids,
    categories_ids,
    professions_ids,
  };
};
