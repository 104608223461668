import * as React from 'react';
import classNames from 'classnames';
import { ETaskPriority } from 'sb_manufacturing_front_api';

interface IPriorityIcon {
  id: ETaskPriority;
}

const PriorityIcon = ({ id }: IPriorityIcon) => {
  if (id === null || id === undefined) return null;

  return <div className={classNames('priorityIcon', `priorityIcon--${id}`)} />;
};

export default PriorityIcon;
