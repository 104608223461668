import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { zonesAdapter } from '~/redux/modules/zonesModule';

export const zonesSelectors = zonesAdapter.getSelectors<IStore>(state => state.zones);

export const selectZones = createSelector(
  (state: IStore) => zonesSelectors.selectAll(state),
  zones => zones.filter(z => z.parent_id !== null),
);

export const selectZone = (zoneId: number) =>
  createSelector(
    (state: IStore) => zonesSelectors.selectAll(state),
    zones => zones.find(zone => zone.id === zoneId),
  );

export const selectDataZones = createSelector(
  (state: IStore) => zonesSelectors.selectAll(state),
  zones =>
    zones
      .filter(z => z.parent_id !== null)
      .map(zone => ({
        id: zone.id,
        name: zone.name,
        deleted: zone.deleted,
        check: false,
        parent_id: zone.parent_id,
        coords: zone.coords,
      })),
);
